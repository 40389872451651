import React from "react";
import "./MapHelp.css";
import { GPSCalibration } from "../../../assets/images";

const MapHelp = () => {
  return (
    <div className="col-md-12 height-custom pt-5 text-start pb-5">
      <div className="col-md-12  pt-4">
        <div className="row">
          <div className="col-md-12">
            <h4 className="heading-pages">
              Map<span className="span-with-green ps-1">Help</span>
            </h4>
          </div>
        </div>
        <div className="row justify-content-center pt-3">
          <div className="col-md-8 map-help-box">
            <div className="row pt-3">
              <div className="col-md-12 heading-pages">
                Calibrate your blue dot’s
                <br />
                compass at anytime
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-12 sub-text-pages">
                1.On your Android phone, open Google Maps app.
              </div>
              <div className="col-md-12 sub-text-pages">
                2.You should only have do this a few times.
              </div>
              <div className="col-md-12 sub-text-pages">
                3.The beam should become narrow and point in the right
                direction.
              </div>
            </div>
            <div className="row text-center">
              <div className="col-md-12">
                <img src={GPSCalibration} alt="Calibration GIF" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MapHelp;
