import React from "react";
import Carousel from "react-multi-carousel";
import { useQuery } from "react-query";

import { useSelector } from "react-redux";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";

function RecommendedGroceryProducts() {
  const merchantId = useSelector(
    (state: any) => state.merchant?.selectedgrocerymerchant
  );

  const responsive = {
    superLargeDesktop: {
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 3,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const {
    data: products,
    isLoading,
    isError,
  } = useQuery({
    queryKey: ["getProductWithOffers"],
    queryFn: async () => {
      try {
        const response = await axiosInstance.post(
          `${API_URL.RECOMMENTED_PRODUCTS}`,
          {}
        );

        return response.data;
      } catch (error) {
        console.error("Error fetching recommended products:", error);
        throw new Error("Error fetching recommended products");
      }
    },
  });

  return (
    <div className="col-md-12 pt-4">
      <div className="">
        <div className="sub-div-headings d-flex">
          <img src="" alt="" />
          &nbsp;
          <span>Offer products</span>&nbsp;
          <span className="line-span">
            <div className="line-div-max-length-ecommerce"></div>
          </span>
        </div>

        <div className="col-md-12 cart-container div-caroysel-relative pt-4">
          <div className="row">
            <Carousel responsive={responsive} swipeable={true} rewind={true}>
              {isLoading ? (
                <p>Loading...</p>
              ) : isError ? (
                <p>Error fetching data</p>
              ) : (
                products.map((product: any) => (
                  <div
                    className="p-1 mb-3 content-fishcard fish-card-div-height px-2"
                    key={product?.product_id}
                  >
                    <div className="card stock-card card-no-border card-hghlght">
                      <div className="card-body">
                        <img src="" alt={product?.product_name} />
                        <div className="inner-text-heading-four pt-2">
                          {product?.product_name}
                        </div>
                        <div className="inner-text-content-five">
                          <span>{product?.product_category_id}</span>
                          <span>&nbsp;|&nbsp;</span>
                          <span>{product?.business_area}</span>
                        </div>
                        {product?.available_stock === 0 && (
                          <div className="out-of-stock-text pt-4">
                            <div className="outofstock p-1">Out of Stock</div>
                          </div>
                        )}
                        <div className="inner-text-content-five pt-1 pb-1">
                          Price:{" "}
                          <span className="inner-text-heading-four">
                            ₹{product?.price}
                          </span>
                        </div>
                        <div className="mb-0">
                          <button className="add-to-cart-button">View</button>
                        </div>
                      </div>
                    </div>
                  </div>
                ))
              )}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}

export default RecommendedGroceryProducts;
