import React from "react";

const CookiePolicy = () => {
  return (
    <>
      <div className="col-12 d-flex justify-content-center text-start pb-4">
        <div className="row width-full-page pb-5">
          <div className="col-md-12 text-center">
            <h4 className="heading-pages pt-5">
              <span className="span-with-green">Cookie</span> Policy
            </h4>
          </div>
          <div className="col-12 d-flex justify-content-center text-start pb-4">
            <div className="row width-full-page pb-3">
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Introduction</div>
                <div className="inner-text-content-two pt-3">
                  This Cookie Policy explains how LiveLocal E-Comm Private
                  Limited ("LiveLocal," "we," "our," or "us") uses cookies and
                  similar tracking technologies on our website. By using our
                  website, you consent to the use of cookies as described in
                  this policy.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">What Are Cookies?</div>
                <div className="inner-text-content-two pt-3">
                  Cookies are small text files that are stored on your device
                  when you visit a website. They are commonly used to recognize
                  your device, remember your preferences, and improve your
                  online experience.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Types of Cookies We Use
                </div>
                <ul className="pt-3">
                  <li>
                    <span className="inner-text-heading-two">
                      Essential Cookies:
                    </span>
                    These cookies are necessary for the proper functioning of
                    our website. They enable you to navigate the website and use
                    its features.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Analytics Cookies:
                    </span>
                    These cookies help us analyze and improve the performance of
                    our website. We use tools like Google Analytics to
                    understand how visitors interact with our site.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Advertising Cookies:
                    </span>
                    We may use advertising cookies to display relevant ads and
                    promotional content based on your browsing habits and
                    interests.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  How to Manage Cookies
                </div>
                <div className="inner-text-content-two pt-3">
                  Most web browsers allow you to manage your cookie preferences.
                  You can typically:
                </div>
                <ul className="pt-3">
                  <li>
                    <span className="inner-text-heading-two">
                      Delete cookies:{" "}
                    </span>
                    You can delete cookies that are already stored on your
                    device.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Block cookies:
                    </span>{" "}
                    You can set your browser to block cookies from our website
                    or third-party websites.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">Opt-Out:</span>{" "}
                    Many third-party advertising networks offer opt-out options
                    for behavioral advertising. You can visit their websites to
                    opt out.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Your Consent</div>
                <div className="inner-text-content-two pt-3">
                  By using our website, you consent to the use of cookies as
                  described in this Cookie Policy. If you do not agree with the
                  use of cookies, you should adjust your browser settings or
                  refrain from using our website.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Changes to this Cookie Policy
                </div>
                <div className="inner-text-content-two pt-3">
                  We may update this Cookie Policy to reflect changes in our
                  practices. When we make significant changes, we will provide
                  notice on our website.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Contact Us</div>
                <div className="inner-text-content-two pt-3">
                  If you have any questions or concerns regarding this Cookie
                  Policy, please contact us at:&nbsp;
                  <a
                    href="mailto:support@lilo.co.in"
                    className="span-with-green-mail"
                  >
                    support@lilo.co.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default CookiePolicy;
