import { createSlice, PayloadAction } from "@reduxjs/toolkit";

interface ProductDataListState {
  productList: productData[];
}

interface productData {
  product_id: string;
  product_name: string;
  category_name: string;
  product_details?: {
    images?: string[];
  };
  variants?: {
    price?: number;
  }[];
}

export const productDataListSlice = createSlice({
  name: "productDataList",
  initialState: {
    productList: [],
  } as ProductDataListState,
  reducers: {
    setProductList: (state, action: PayloadAction<productData[]>) => {
      state.productList = action.payload;
    },
  },
});

export const { setProductList } = productDataListSlice.actions;

export const selectProductList = (state: {
  productDataList: ProductDataListState;
}) => state.productDataList.productList;

export default productDataListSlice.reducer;
