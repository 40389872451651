import React, { useState, useEffect } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import CustomButton from "../customButton/customButton";
import "./styles.css";
import MapComponent from "../location/location";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import {
  API_URL,
  baseUrl,
  guestId,
  mapurl,
  sessionId,
} from "../../services/apiConfig";
import { connect } from "react-redux";
import { RootState } from "../../redux/store";
import { Dispatch } from "redux";
import {
  setPhoneNumber,
  setName,
  setGuestPhoneNumber,
} from "../../redux/authSlice/authSlice";
import { log } from "console";
import {
  addAddress,
  addGuestAddress,
} from "../../redux/location/locationAction";
import { changeSelectedCurrentAddress } from "../../redux/location/locationSlice";
import { apiAddressFormat } from "../../utils/location.utils";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

interface AddressModalProps {
  isOpen: boolean;
  toggle: () => void;
}
interface formData {
  address1: string;
  landmark: string;
  mobileNumber: string;
  street: string;
  latitude: any;
  longitude: any;
  city: any;

  country: any;

  pincode: string;
  addressType: string;
  name: string;
}
const AddressModal: React.FC<AddressModalProps> = ({ isOpen, toggle }) => {
  const [address, setAddress] = useState("");
  const [landmark, setLandmark] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [street, setStreet] = useState("");
  const [city, setCity] = useState("");
  const [pincode, setPincode] = useState("");
  const { name, phoneNumber } = useSelector((state: RootState) => state.auth);

  const [userName, setUserName] = useState(name);
  const [localPhoneNumber, setLocalPhoneNumber] = useState(phoneNumber);
  const [addressType, setAddressType] = useState<string>("");
  const [addressObject, setAddressObject] = useState<any>({});
  const [addressother, setaddressother] = useState<string>("");
  const [latitude, setlatitude] = useState<number | null>(null);
  const [longitude, setlongitude] = useState<number | null>(null);
  const [loadingAddress, setLoadingAddress] = useState<boolean>(false);
  const dispatch = useDispatch();

  const [roadSuggestions, setRoadSuggestions] = useState([]);
  const [roadInputValue, setRoadInputValue] = useState("");

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axiosInstance.get(
          `${mapurl}reverse?format=json&latitude=${addressObject?.latitude}&longitude=${addressObject?.longitude}`
        );

        const display_name = response?.data?.display_name;
        setAddress(display_name);
        setStreet(response?.data?.address?.road);
        setLandmark(response?.data?.address?.leisure);
        setCity(response?.data?.address?.city);
        setPincode(response?.data?.address?.postcode);
        setlatitude(response?.data?.lat);
        setlongitude(response?.data?.lon);
        setLoadingAddress(false);
      } catch (error) {
        setLoadingAddress(false);
      }
    };

    if (addressObject?.latitude && addressObject?.longitude) {
      fetchData();
    }
  }, [addressObject]);

  const handleLocationSelect = (data: any) => {
    setAddress(data?.address?.amenity);
    setStreet(data?.address?.road);
    setLandmark(data?.address?.leisure);
    setCity(data?.address?.city);
    setPincode(data?.address?.postcode);
    setlatitude(data?.lat);
    setlongitude(data?.lon);
  };

  const handleAddressTypeSelect = (selectedAddressType: string) => {
    setAddressType(selectedAddressType);
  };
  useEffect(() => {
    setCity(addressObject?.address?.city || "");
    setPincode(addressObject?.address?.postcode || "");
  }, [addressObject]);
  // useEffect(() => {
  //   setUserName(name);
  //   setLocalPhoneNumber(phoneNumber);
  // }, [name, phoneNumber]);

  // useEffect(() => {
  //   if (localPhoneNumber !== " ") {
  //     dispatch(setGuestPhoneNumber(localPhoneNumber));
  //   }
  // }, [localPhoneNumber]);

  const north = 17.5;
  const south = 8;
  const east = 88;
  const west = 74;

  const fetchRoadSuggestions = async (value: any) => {
    try {
      const response = await axiosInstance.get(
        `${mapurl}search?q=${value}&limit=5&format=json&addressdetails=1&bounded=1&viewbox=${west},${south},${east},${north}`
      );
      const suggestions = response.data.map((item: any) => item.display_name);
      const selectedSuggestion = response.data[0];
      if (selectedSuggestion) {
        const { lat, lon } = selectedSuggestion;
        setlatitude(lat);
        setlongitude(lon);
      }
      const city = response.data[0]?.address?.county;
      const pincode = response.data[0]?.address?.postcode;
      setCity(city || "");
      setPincode(pincode || "");

      setRoadSuggestions(suggestions);
    } catch (error) {
      console.error("Error fetching road suggestions:", error);
    }
  };

  const handleRoadSuggestionClick = async (
    suggestion: React.SetStateAction<string>
  ) => {
    setRoadInputValue(suggestion);
    setRoadSuggestions([]);
  };

  const { data, refetch } = useQuery({
    queryFn: async () => {
      let response;
      if (guestId && sessionId) {
        response = await axiosInstance.get(API_URL.GUEST_USER_GET_ADDRESS);
      } else {
        response = await axiosInstance.get(API_URL.ADDRESS_LIST);
      }
      return response.data;
    },
  });

  const handleSaveAndProceed = async () => {
    if (guestId && sessionId) {
      const formData = {
        address1: address,
        landmark: landmark,
        mobileNumber: localPhoneNumber,
        street: street,
        latitude: latitude,
        longitude: longitude,
        city: addressObject?.address?.city,
        country: addressObject?.address?.country,
        pincode,
        addressType,
        name: userName,
        guestId: guestId,
      };

      try {
        const result = await dispatch(addGuestAddress(formData) as any);
        const res = result.payload;

        let finalAddressType = addressType;
        if (addressType === "Others") {
          finalAddressType = addressother;
          formData.addressType = finalAddressType;
        }
        if (res) {
          dispatch(
            changeSelectedCurrentAddress({
              coords: { latitude: res.latitude, longitude: res.longitude },
              formattedAddress: apiAddressFormat(res),
              addressId: res.id,
              addressType: res.addressType,
            })
          );
          setAddress(formData.address1);
          toggle();
          // window.location.reload();
          refetch();
        }
      } catch (error) {
        console.error(error);
      }
    } else {
      const formData = {
        address1: address,
        landmark: landmark,
        mobileNumber: localPhoneNumber,
        street: street,
        latitude: latitude,
        longitude: longitude,
        city: addressObject?.address?.city,
        country: addressObject?.address?.country,
        pincode,
        addressType,
        name: userName,
      };

      try {
        const result = await dispatch(addAddress(formData) as any);
        const res = result.payload;

        let finalAddressType = addressType;
        if (addressType === "Others") {
          finalAddressType = addressother;
          formData.addressType = finalAddressType;
        }

        if (res) {
          dispatch(
            changeSelectedCurrentAddress({
              coords: { latitude: res.latitude, longitude: res.longitude },
              formattedAddress: apiAddressFormat(res),
              addressId: res.id,
              addressType: res.addressType,
            })
          );
          setAddress(formData.address1);
          toggle();
          // window.location.reload();
          refetch();
        }
      } catch (error) {
        console.error(error);
      }
    }
  };

  const handlePhone = (e: any) => {
    setLocalPhoneNumber(e.target.value);
    if (localPhoneNumber !== "") {
      dispatch(setGuestPhoneNumber(localPhoneNumber));
    }
  };
  return (
    <Modal isOpen={isOpen} toggle={toggle}>
      <ModalBody>
        <div
          className="addressModalParent ps-2 pe-2 scroll-container"
          style={{ maxHeight: "600px", overflowY: "auto" }}
        >
          <div>
            <span className="addressModalheader sub-div-headings pt-3">
              Add delivery address
            </span>
          </div>
          <div className="mapBody  ">
            <MapComponent onSelect={handleLocationSelect} />
          </div>

          <div className="mt-3">
            <span>Address type</span>
          </div>
          <div className="addressTypeContainer border-0 border-bottom mb-3 pb-3">
            <CustomButton onAddressTypeSelect={handleAddressTypeSelect} />
            {addressType === "Others" && (
              <div className="mt-3">
                <span>Other Address Type</span>
                <input
                  type="text"
                  className="form-control mt-2"
                  value={addressother}
                  onChange={(e) => setaddressother(e.target.value)}
                />
              </div>
            )}
          </div>
          <div className="p-2">
            <form>
              <div className="form-group">
                <div className="mt-3">
                  <span>
                    Name <span className="text-danger">*</span>
                  </span>
                  <input
                    type="text"
                    className="form-control input-field mt-2 addressModalInputFields"
                    id="Name"
                    name="Name"
                    value={userName}
                    onChange={(e) => setUserName(e.target.value)}
                  />
                </div>
                <div className="mt-3">
                  <span>
                    Mobile Number<span className="text-danger">* </span>
                  </span>
                  <input
                    type="text"
                    className="form-control input-field mt-2 addressModalInputFields"
                    id="phoneNo"
                    name="phoneNo"
                    value={localPhoneNumber}
                    onChange={(e) => handlePhone(e)}
                  />
                </div>
                <div className="mt-3">
                  <span>
                    Road Name / Area<span className="text-danger">*</span>
                  </span>
                  <input
                    type="text"
                    className="form-control input-field mt-2 addressModalInputFields"
                    id="roadName"
                    name="roadName"
                    value={roadInputValue}
                    onChange={(e) => {
                      setRoadInputValue(e.target.value);
                      fetchRoadSuggestions(e.target.value);
                    }}
                  />
                  {roadSuggestions.length > 0 && (
                    <div className="suggestions">
                      {roadSuggestions.map((suggestion, index) => (
                        <div
                          key={index}
                          onClick={() => handleRoadSuggestionClick(suggestion)}
                          className="suggestion-item"
                        >
                          {suggestion}
                        </div>
                      ))}
                    </div>
                  )}
                </div>
                <div className="mt-3">
                  <span>
                    House No / Building Name
                    <span className="text-danger">*</span>
                  </span>
                  <input
                    type="text"
                    className="form-control input-field mt-2 addressModalInputFields"
                    id="buildingName"
                    name="buildingName"
                    value={address}
                    onChange={(e) => setAddress(e.target.value)}
                  />
                </div>

                <div className="mt-3">
                  <span>Landmark</span>
                  <input
                    type="text"
                    className="form-control input-field mt-2 addressModalInputFields"
                    id="landMark"
                    name="landMark"
                    value={landmark}
                    onChange={(e) => setLandmark(e.target.value)}
                  />
                </div>
                <div className="d-flex inputFlexContainer">
                  <div className="mt-3">
                    <span>City </span>
                    <input
                      type="text"
                      className="form-control input-field mt-2 addressModalInputFields"
                      id="city"
                      name="city"
                      value={city}
                      onChange={(e) => setCity(e.target.value)}
                    />
                  </div>
                  <div className="mt-3">
                    <span>Pincode </span>
                    <input
                      type="text"
                      className="form-control input-field mt-2 addressModalInputFields"
                      id="pincode"
                      name="pincode"
                      value={pincode}
                      onChange={(e) => setPincode(e.target.value)}
                    />
                  </div>
                </div>
              </div>
              <Button
                className="mt-3 saveAndProceedButton"
                onClick={handleSaveAndProceed}
                disabled={!userName || !localPhoneNumber}
              >
                Save and Proceed
              </Button>
            </form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default AddressModal;
