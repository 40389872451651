import { faStar } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, CardBody } from "reactstrap";
import { Pre_demo_img } from "../../../assets/images";
import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import FoodCart from "../FoodCart/FoodCart";
import { useLocation, useNavigate, useParams } from "react-router-dom";

const groupByShop = (products: any) => {
  return products.reduce((acc: any, product: any) => {
    const { shopName } = product._source;
    if (!acc[shopName]) {
      acc[shopName] = [];
    }
    acc[shopName].push(product._source);
    return acc;
  }, {});
};

const ProductSearchListing = () => {
  const navigate = useNavigate();
  const { "*": dishName } = useParams();
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const categoryInfo = useLocation();
  const { id, name } =
    (categoryInfo.state as { id: string; name: string }) || {};

  const { data: response, refetch } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        `${API_URL.FOOD_LISTING}`,
        {
          name: dishName,
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          planet_id: "foodPlanet",
        },
        {
          headers: { version: "v4" },
        }
      ),
    queryKey: ["", location?.latitude],
    enabled: !!location?.latitude,
  });

  const foodlisting = response?.data.hits.hits || [];

  const groupedProducts = groupByShop(foodlisting);

  const handleImageClick = (productId: string) => {
    navigate(`/FoodDetails?id=${productId}`, { state: { productId } });
  };

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page-product pb-5 relative-div-bg">
        <div className="inner-text-heading ">
          <span className="span-with-green">Restaurants & Dishes </span>
        </div>
        <div className="pt-3">
          {Object.keys(groupedProducts).map((shopName) => (
            <Card key={shopName} className="restaurant-category-div mb-4">
              <CardBody>
                <div className="bottom-border">
                  <div className="sub-div-headings">{shopName}</div>
                </div>
                <div className="d-flex width-main-div-product pt-3 pb-3">
                  {groupedProducts[shopName].map((product: any) => (
                    <div
                      key={product.productId}
                      className="p-2 width-in-pixel content-fishcard"
                    >
                      <Card className="card-hghlght card-no-border product-card-cat">
                        <CardBody>
                          <img
                            src={product?.productImages?.[0] || Pre_demo_img}
                            alt={product.name}
                            className="img-ratio-card"
                          />
                          <div className="pt-2">
                            <div className="inner-text-heading-two">
                              {product.name}
                            </div>
                            <div className="inner-text-content-three">
                              Price:{" "}
                              <span className="inner-text-heading-four">
                                {product.price} rs
                              </span>
                            </div>
                            <div className="inner-text-content-four">
                              <FontAwesomeIcon
                                icon={faStar}
                                className="yellow-star"
                              />
                              &nbsp; {product.rating} ({product.totalReviews}{" "}
                              reviews)
                            </div>
                            <div className="pt-2 custom-button-div">
                              <button
                                className="card-button text-white add-to-cart-button"
                                onClick={() =>
                                  handleImageClick(product.productId)
                                }
                              >
                                View
                              </button>
                            </div>
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ))}
                </div>
              </CardBody>
            </Card>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductSearchListing;
