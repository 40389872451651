import React from "react";

const PrivacyPolicy = () => {
  return (
    <>
      <div className="col-12 d-flex justify-content-center text-start pb-4">
        <div className="row width-full-page pb-3">
          <div className="col-md-12 text-center">
            <h4 className="heading-pages pt-5">
              {" "}
              <span className="span-with-green">Privacy</span> Policy
            </h4>
          </div>
          <div className="col-12 d-flex justify-content-center text-start pb-4">
            <div className="row width-full-page pb-3">
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Introduction</div>
                <div className="inner-text-content-two pt-3">
                  LiveLocal E-Comm Private Limited ("LiveLocal," "we," "our," or
                  "us") is committed to protecting the privacy and security of
                  your personal information. This Privacy Policy explains how we
                  collect, use, disclose, and safeguard your personal
                  information when you use our hyperlocal delivery services,
                  mobile applications, and website.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Information We Collect
                </div>
                <div className="inner-text-content-two pt-3">
                  We may collect various types of information, including but not
                  limited to:
                </div>
                <ul className="pt-3">
                  <li>
                    <span className="inner-text-heading-two">
                      Personal Information:
                    </span>{" "}
                    This may include your name, contact information, address,
                    and payment details.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Location Information:
                    </span>{" "}
                    We collect your device's location information when you use
                    our services to provide efficient hyperlocal delivery.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Transaction Details:
                    </span>{" "}
                    Information about your transactions, such as order history
                    and delivery status.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Usage Information:
                    </span>{" "}
                    Data about how you interact with our website and mobile
                    applications.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Device Information:
                    </span>{" "}
                    This may include collecting information about the devices
                    you use to access our Services,hardware details, OS Versions
                    and softwares.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  How We Use Your Information
                </div>
                <div className="inner-text-content-two pt-3">
                  We use your personal information for the following purposes:
                </div>
                <ul className="pt-3">
                  <li>
                    <span className="inner-text-heading-two">
                      To process and fulfill{" "}
                    </span>
                    your orders for hyperlocal delivery services.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      To communicate
                    </span>{" "}
                    with you about your orders and provide customer support.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      To improve and personalize{" "}
                    </span>
                    your experience with our services.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      To send you updates, promotions, and information about
                    </span>{" "}
                    our services.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Information Sharing</div>
                <div className="inner-text-content-two pt-3">
                  We may share your information with the following parties:
                </div>
                <ul className="pt-3">
                  <li>
                    <span className="inner-text-heading-two">
                      Delivery Partners:{" "}
                    </span>
                    We share your delivery details with our delivery partners to
                    fulfill your orders.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      Service Providers:
                    </span>{" "}
                    We engage third-party service providers for various
                    purposes, such as payment processing and analytics.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      {" "}
                      Legal Requirements:
                    </span>{" "}
                    We may disclose your information to comply with legal
                    obligations or respond to law enforcement requests.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Data Security</div>
                <div className="inner-text-content-two pt-3">
                  We employ industry-standard security measures to protect your
                  personal information. However, no method of data transmission
                  over the internet or electronic storage is entirely secure.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Your Choices</div>
                <div className="inner-text-content-two pt-3">You can:</div>
                <ul className="pt-3">
                  <li>
                    <span className="inner-text-heading-two">
                      Review and update
                    </span>{" "}
                    your personal information through your account settings.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">Opt out</span> of
                    marketing communications at any time.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Cookies and Tracking Technologies
                </div>
                <div className="inner-text-content-two pt-3">
                  We use cookies and similar technologies to enhance your
                  experience on our website and mobile applications. You can
                  manage cookie preferences through your browser settings.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Children's Privacy</div>
                <div className="inner-text-content-two pt-3">
                  Our services are not intended for children under the age of
                  13. We do not knowingly collect information from children.
                </div>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Contact Us</div>
                <div className="inner-text-content-two pt-3">
                  If you have any questions or concerns regarding this Privacy
                  Policy or your personal information, please contact us
                  at:&nbsp;
                  <a
                    href="mailto:support@lilo.co.in"
                    className="span-with-green-mail"
                  >
                    support@lilo.co.in
                  </a>
                </div>
              </div>
              <div className="col-md-12 pb-4">
                <div className="sub-div-headings pt-4">Consent</div>
                <div className="inner-text-content-two pt-3">
                  By using our hyperlocal delivery services, you consent to the
                  terms outlined in this Privacy Policy.
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PrivacyPolicy;
