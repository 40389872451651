import React from "react";
import { QueryClient, QueryClientProvider } from 'react-query';
import "./App.css";
import RouterComponent from "./routes";

const App: React.FC = () => {
  const queryClient = new QueryClient();
  return (
    <QueryClientProvider client={queryClient}>
      <div className="App">
         
        <RouterComponent />
      </div>
    </QueryClientProvider>
  );
};

export default App;

