import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { v4 as uuidv4 } from "uuid";
import { liveLocal_Logo } from "../../assets/images";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import SignInModal from "../signinmodal/signInModal";
import { useMutation } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL, guestId } from "../../services/apiConfig";
import { setGuestUser } from "../../redux/authSlice/authSlice";
import Cookies from "js-cookie";

interface UserConfirmModalProps {
  isOpen: boolean;
  toggle: () => void;
}

interface guestUser {
  session_id: string;
  guest_id: string;
}

const UserConfirmModal: React.FC<UserConfirmModalProps> = ({
  isOpen,
  toggle,
}) => {
  const navigate = useNavigate();
  const [otpRefs] = useState<any>([
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ]);

  const dispatch = useDispatch();
  const [isSignInModalOpen, setIsSignInModalOpen] = useState(false);
  // const [guestTimeout, setGuestTimeout] = useState<number | undefined>(
  //   undefined
  // );

  const closeModal = () => {
    toggle();
  };

  const clearSessionStorage = () => {
    sessionStorage.clear();
    navigate("/");
    window.location.reload();
  };

  const toggleSignInModal = () => {
    setIsSignInModalOpen(!isSignInModalOpen);
  };

  const guestLogin = useMutation({
    mutationFn: async (payload: guestUser) => {
      const response = await axiosInstance.post(API_URL.GUEST_CREATE, payload);
      return response.data;
    },
    onSuccess: () => {
      closeModal();
      window.location.reload();
    },
  });

  const handleGuestUserClick = async () => {
    try {
      const response = await axiosInstance.get(
        "/customer-consumer/customer/service/guest/guest-id"
      );
      const createdAt = new Date().toISOString();
      const uuidend = response.data;
      const uuid = uuidv4();
      // sessionStorage.setItem("guest-id", uuidend);
      // sessionStorage.setItem("session-id", JSON.stringify({ uuid, createdAt }));
      Cookies.set("guest-id", uuidend);
      Cookies.set("session-id", JSON.stringify({ uuid, createdAt }));

      // window.location.reload();

      if (uuidend) {
        dispatch(setGuestUser(guestId ? guestId : ""));
      } else {
        console.error("UUID not found in response:");
      }

      const payload: guestUser = {
        session_id: uuid,
        guest_id: uuidend,
      };

      guestLogin.mutate(payload);
    } catch (error) {
      console.error("Error fetching guest ID:", error);
    }
  };

  // useEffect(() => {
  //   if (!isOpen) {
  //     if (guestTimeout) {
  //       clearTimeout(guestTimeout);
  //     }
  //   }
  //   return () => {
  //     if (guestTimeout) {
  //       clearTimeout(guestTimeout);
  //     }
  //   };
  // }, [isOpen, guestTimeout]);

  return (
    <>
      <Modal
        isOpen={isOpen}
        toggle={toggle}
        className="custom-modal "
        backdrop="static"
      >
        <ModalBody className="custom-modal-body">
          <div className="row pb-3">
            <div className="col text-start">
              <img
                src={liveLocal_Logo}
                alt="SignIn Img"
                className="img-fluid liveLocalLogo"
              />
            </div>
            <div className="col text-end">
              <button className="closebutton" onClick={closeModal}>
                X
              </button>
            </div>
          </div>
          <div>
            <button
              className="btn btn-success btn-lg mt-4"
              onClick={toggleSignInModal}
            >
              SIGN IN
            </button>
          </div>

          <div>
            <button
              className="btn btn-success btn-lg mt-4"
              onClick={handleGuestUserClick}
            >
              GUEST USER
            </button>
          </div>

          <div className="termstextcontainer mt-3">
            <p>
              By continuing, I accept the{" "}
              <a className="link">Terms of Service</a>
              <a className="link">
                <br />
                Privacy Policy
              </a>{" "}
              | <a className="link">Content Policy</a>
            </p>
          </div>
        </ModalBody>
      </Modal>

      {isSignInModalOpen && (
        <SignInModal isOpen={isSignInModalOpen} toggle={toggleSignInModal} />
      )}
    </>
  );
};

export default UserConfirmModal;
