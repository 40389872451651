import { PayloadAction, createSlice } from '@reduxjs/toolkit';

// Define the interface for the state
interface FlyingImageState {
  imgSrc: string | null;
  itemOffsets: {
    top: number;
    left: number;
  } | null;
}

// Initial state
const initialState: FlyingImageState = {
  imgSrc: null,
  itemOffsets: null,
};

// Create the slice
const flyingAnimation = createSlice({
  name: ' flyingAnimation',
  initialState,
  reducers: {
    setFlyingImg(state, action: PayloadAction<{ imgSrc: string|null; itemOffsets: { top: number; left: number }|null }>) {
      state.imgSrc = action.payload.imgSrc;
      state.itemOffsets = action.payload.itemOffsets;
    },
    // Other reducers can be added here
  }, 
});

export const { setFlyingImg } = flyingAnimation.actions;
export default flyingAnimation.reducer;
