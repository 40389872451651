import React, { useState } from "react";
import { Card, CardBody } from "reactstrap";
import "./OrderDetails.css";
import { companyicons, hotel_icon, tuna_fish } from "../../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";

const Orderdetails = () => {
  const navigate = useNavigate();

  interface Order {
    out_of_stock: boolean;
    orderId: string;
    product_name: string;
    merchantName: string;
    imageUrl: string;
    totalPrice: string;
    products?: [
      {
        productName: string;
        originalPrice: string;
        quantity: string;
        discountPrice: string;
        gst: string;
      }
    ];
    merchantDetails?: {
      shop_info?: {
        shop_address?: {
          address?: string;
          city?: string;
        };
      };
    };
    deliveryAddressInfo?: {
      customerAddress?: {
        addressType?: string;
        city?: string;
      };
    };
    productDetails?: [
      {
        quantity: string;
        productName: string;
        subtotal?: string;
      }
    ];
    delivery_boy_details?: {
      firstName: string;
    };
    orderDeliveryTime: string;
    delivery_charge?: number;
  }
  const location = useLocation();

  const orderId = location.state.orderId;

  const [order, setOrder] = useState<Order | null>(null);

  useQuery(["order", orderId], () => fetchOrderDetails(orderId!), {
    enabled: !!orderId,
  });

  const fetchOrderDetails = async (orderId: string) => {
    try {
      const response: any = await axiosInstance.get<Order>(
        `${API_URL.PASTORDERSDETAIL}/${orderId}/details`,
        {
          headers: {
            "device-id": "device",
            location: "1,2",
          },
        }
      );
      setOrder(response.data);
    } catch (error) {
      console.error("Error fetching product details:", error);
    }
  };
  const handleDownloadInvoice = async (orderId: string) => {
    try {
      const response = await axiosInstance.get(
        `${API_URL.DOWNLOAD_ORDER_INVOICE}/${orderId}`,
        {
          responseType: "blob",
        }
      );

      const url = window.URL.createObjectURL(new Blob([response.data]));
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", "invoice.pdf");
      document.body.appendChild(link);
      link.click();
    } catch (error) {
      console.error("Error downloading invoice:", error);
    }
  };
  const formattedOrderDeliveryTime = order
    ? new Date(order.orderDeliveryTime).toLocaleString("en-US", {
        hour: "numeric",
        minute: "numeric",
        hour12: true,
        day: "2-digit",
        month: "2-digit",
        year: "numeric",
      })
    : "";

  function calculateSubtotal(products: any[]) {
    if (!products) return 0;
    return products.reduce((total, product) => total + product.subtotal, 0);
  }
  // function calculateItemTotal(products: any[]) {
  //   const subtotal = calculateSubtotal(products);

  //   const deliveryFee = 0;
  //   const discount = order.products && order.products[0].discountPrice;
  //   const gst = order.products && order.products[0].gst;
  //   const total = subtotal - (parseFloat(discount) || 0) + (parseFloat(gst) || 0) + deliveryFee;
  //   return total;
  // }
  const handleBackClick = () => {
    navigate("/Profile");
  };
  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5">
        <div className="col-md-12 text-center pt-4">
          <h4 className="fish-category-text">
            Order &nbsp;
            <span className="span-with-green ">#{order?.orderId}</span>
          </h4>
        </div>
        <div className="col-12 pt-2 pb-5">
          <div className="row">
            <div className="col-md-6 pt-4 ">
              <Card className="order-details-box">
                <CardBody>
                  <div className="row">
                    <div className="col-6">
                      <div className="row  text-start">
                        <div>
                          <img
                            src={order?.imageUrl}
                            alt=""
                            className="img-fluid orderImage"
                          />
                        </div>
                        <div className="order-details-delivered-text pt-4">
                          Order Delivered By{" "}
                        </div>
                        <div className="inner-text-heading-four">
                          {order?.delivery_boy_details?.firstName}
                        </div>
                        <div className="order-details-delivered-text ">
                          Your Delivery Rating{" "}
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={faStar}
                            className="loved-it-review-star"
                          />
                          <span className="order-details-rating-text ">
                            5 | Loved It
                          </span>
                        </div>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="row  text-start">
                        <div className="col-12 order-details-border">
                          <div className="sub-div-headings">
                            {order?.merchantName}
                          </div>
                          <div className="order-details-shop-text">
                            {
                              order?.merchantDetails?.shop_info?.shop_address
                                ?.address
                            }
                            ,
                            {
                              order?.merchantDetails?.shop_info?.shop_address
                                ?.city
                            }
                          </div>
                        </div>

                        <div className="col-12 order-details-border">
                          <div className="order-details-shop-text">
                            Outlet:{" "}
                            {
                              order?.merchantDetails?.shop_info?.shop_address
                                ?.city
                            }
                          </div>
                          <div className="order-details-shop-text">
                            Home:{" "}
                            {
                              order?.deliveryAddressInfo?.customerAddress
                                ?.addressType
                            }
                            ,{order?.deliveryAddressInfo?.customerAddress?.city}
                            ,
                          </div>
                        </div>
                        <div className="order-details-delivered-text pt-md-4">
                          Delivered Date{" "}
                        </div>
                        <div className="inner-text-heading-four">
                          {formattedOrderDeliveryTime}
                        </div>
                        <div className="order-details-delivered-text ">
                          Your Product Rating{" "}
                        </div>
                        <div>
                          <FontAwesomeIcon
                            icon={faStar}
                            className="good-review-star"
                          />
                          <span className="order-details-rating-text ">
                            4.6 | Good
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>

            <div className="col-md-6 pt-4 ">
              <Card className="order-details-box">
                {order && (
                  <CardBody>
                    <div className="">
                      <table className="table-invoice">
                        <tbody>
                          <tr className="sub-div-headings">
                            <td colSpan={2}>Bill Details</td>
                          </tr>
                          {order.productDetails &&
                            order.productDetails.map((product, index) => (
                              <tr
                                key={index}
                                className="order-details-dotted-border"
                              >
                                <td className="order-details-rating-text">
                                  {product.productName} ({product.quantity})
                                </td>
                                <td className="order-details-price-headings">
                                  ₹{product.subtotal}
                                </td>
                              </tr>
                            ))}
                          <tr>
                            <td className="order-details-rating-text ">
                              Item total
                            </td>
                            <td className="order-details-shop-text pt-1">
                              ₹{calculateSubtotal(order.productDetails || [])}
                            </td>
                          </tr>
                          <tr>
                            <td className="order-details-rating-text ">
                              Delivery Fee
                            </td>
                            <td className="order-details-green-text pt-1">
                              {order && order.delivery_charge === 0
                                ? "Free"
                                : `₹${order?.delivery_charge}`}
                            </td>
                          </tr>
                          <td className="order-details-rating-text ">
                            Discount Applied
                            <span className="order-details-green-text">
                              (Lilo25)
                            </span>
                          </td>
                          <td className="order-details-shop-text pt-1">
                            - ₹{order?.products?.[0]?.discountPrice}
                          </td>
                          <tr>
                            <td className="order-details-rating-text ">
                              GST & Other Charges
                            </td>{" "}
                            <td className="order-details-shop-text pt-1">
                              - ₹{order?.products?.[0]?.gst}
                            </td>
                          </tr>
                          <div className="pt-3"></div>
                          <tr className="order-details-total ">
                            <td className="order-details-green-text pt-1">
                              Total (paid Via PHONEPE)
                            </td>{" "}
                            <td className="order-details-green-text pt-1">
                              {order
                                ? order.totalPrice +
                                  (order.delivery_charge !== 0
                                    ? order.delivery_charge
                                    : 0)
                                : "Loading..."}
                            </td>
                          </tr>{" "}
                        </tbody>
                      </table>
                    </div>
                  </CardBody>
                )}
              </Card>
            </div>
          </div>
          <div className="pt-3  justify-content-center text-center">
            <button
              className="btn text-white order-details-back-button px-2"
              onClick={handleBackClick}
            >
              Back
            </button>
            &nbsp;
            <button
              className="btn invoice-btn px-3"
              onClick={() => handleDownloadInvoice(orderId)}
            >
              Download Invoice
            </button>{" "}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orderdetails;
