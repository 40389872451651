import React, { useState } from "react";
import FishCard from "../../components/fishCard/FishCard";
import Filter from "../../components/filterComponent/filterComponent";
import Banner from "../../components/banner/Banner";
import Catogeryfilter from "../../components/categoryfilter/catogeryfilter";
import Category from "../Category/category";

interface Props {
  onTypeofCutChange: (selectedTypeofCut: string) => void;
  onQuantityChange: (selectedQuantity: string) => void;
}

const MainCategory: React.FC<Props> = ({ onTypeofCutChange }) => {
  const [selectedTypeofCut, setselectedTypeofCut] = useState("");
  const [selectedQuantity, setselectedQuantity] = useState("");

  const handleTypeofCutChange = (selectedTypeofCut: string) => {
    setselectedTypeofCut(selectedTypeofCut);
  };
  const handleQuantityChange = (selectedQuantity: string) => {
    setselectedQuantity(selectedQuantity);
  };
  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4">
      <div className="row width-full-page pb-5 relative-div-bg">
        <div className="row filterButtons text-md-start pt-4">
          <Catogeryfilter
            onTypeofCutChange={handleTypeofCutChange}
            onQuantityChange={handleQuantityChange}
          />
        </div>
        <Category
          selectedTypeofCut={selectedTypeofCut}
          selectedQuantity={selectedQuantity}
        />
      </div>
    </div>
  );
};

export default MainCategory;
