import { createAsyncThunk } from '@reduxjs/toolkit';


import { changeSelectedCurrentAddress, updateCurrentLocation, updateFetchingLocation } from './locationSlice';
import { apiAddressFormat } from '../../utils/location.utils';

import { roundToDecimalPlaces } from '../../utils/calculation';

import axiosInstance from '../../services/axios.instance';
import { getMerchantsNearby } from '../merchandSlice';
import { mapurl } from '../../services/apiConfig';
import { RootState } from '../store';
import { getDistanceValidation, incrementQuantity, } from '../productList/productListSlice';

interface Address {
  valid: boolean;
  pinCode: string;
  latitude: number;
  longitude: number;
  addressId: string;
  addressType: string;
  formattedAddress: string;
}

interface Location {
  address?: {
    postcode?: string;
  };
  lat?: number;
  lon?: number;
}
interface ConfigData {

  address1: string;
  landmark: string;
  mobileNumber: string;
  street: string;
  latitude: any;
  longitude: any;
  city: any;

  country: any;

  pincode: string;
  addressType: string;
  name: string;

  // Add more properties as needed
}
interface LocationData {
  latitude: number;
  longitude: number;
  address: string;
  // You can add other properties as needed
}

interface DeliveryData {
  customerId: string;
  merchant_longitude: number;
  merchant_latitude: number;
  customer_longitude: number;
  customer_latitude: number;
  finalTotalAmount: number;

  bill: any;
  planet_id: string;
  cart: cartItems
}

interface cartItems {
  product_id: string;
  delivery_boy_share: string;
  climate_margin: string;
  total_delivery_fee: string;
  platform_share: string;
  applied_discount_code: string
}
interface DeleteAddressResponse {
  id: string; // Assuming id is a string, adjust the type accordingly
  resp: any; // Adjust the type according to the response structure
  // You can add other properties as needed
}
interface GetCartAllDataPayload {
  merchantId: string;
}


export const getAddresses = createAsyncThunk<Address[], Location>(
  'consumer/getAddresses',
  async (currentLocation = {}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get<Address[]>('customer-consumer/customer/service/master/deliveryaddress');
      if (data?.length > 0) {
        for (const eachAddress of data) {
          if (
            eachAddress?.pinCode &&
            currentLocation?.address?.postcode &&
            String(eachAddress?.pinCode) === String(currentLocation?.address?.postcode)
          ) {
            dispatch(
              changeSelectedCurrentAddress({
                coords: { latitude: String(eachAddress.latitude), longitude: String(eachAddress.longitude) }, // Convert to string
                formattedAddress: apiAddressFormat(eachAddress),
                addressId: eachAddress.addressId,
                addressType: eachAddress.addressType,
              }),
            );
            dispatch(updateFetchingLocation(false));
            dispatch(
              getMerchantsNearby({
                latitude: eachAddress.latitude,
                longitude: eachAddress.longitude,
                res: 6,
                business_area: 'foodPlanet', //TODO: this needs to be dynamic
              }),
            );
            break;
          } else {
            dispatch(
              getMerchantsNearby({
                latitude: currentLocation?.lat ?? 0, // Provide a default value for latitude
                longitude: currentLocation?.lon ?? 0, // Provide a default value for longitude
                res: 6,
                business_area: 'foodPlanet', //TODO: this needs to be dynamic
              }),
            );
            dispatch(updateFetchingLocation(false));
            break;
          }
        }
      } else {
        dispatch(
          getMerchantsNearby({
            latitude: currentLocation?.lat ?? 0, // Provide a default value for latitude
            longitude: currentLocation?.lon ?? 0, // Provide a default value for longitude
            res: 6,
            business_area: 'foodPlanet', //TODO: this needs to be dynamic
          }),
        );
        dispatch(updateFetchingLocation(false));
      }
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getAddressesForCart = createAsyncThunk<Address[], Location>(
  'consumer/getAddressesForCart',
  async (currentLocation = {}, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get<Address[]>('customer-consumer/customer/service/master/deliveryaddress');
      if (data?.length > 0) {
        for (const eachAddress of data) {
          if (
            eachAddress?.pinCode &&
            currentLocation?.address?.postcode &&
            String(eachAddress?.pinCode) === String(currentLocation?.address?.postcode) &&
            !isNaN(eachAddress.latitude) &&
            !isNaN(eachAddress.latitude)
          ) {
            // dispatch(
            //   changeSelectedCurrentAddress({
            //     coords: { latitude: eachAddress.latitude, longitude: eachAddress.longitude },
            //     formattedAddress: apiAddressFormat(eachAddress),
            //     addressId: eachAddress.addressId,
            //     addressType: eachAddress.addressType,
            //   }),
            // );

            dispatch(
              changeSelectedCurrentAddress({
                coords: { latitude: String(eachAddress.latitude), longitude: String(eachAddress.longitude) }, // Convert to string
                formattedAddress: apiAddressFormat(eachAddress),
                addressId: eachAddress.addressId,
                addressType: eachAddress.addressType,
              }),
            );
          }
          const { latitude, longitude } = eachAddress;
          axiosInstance.defaults.headers['location'] = 'kerala';
          if (!isNaN(latitude) && !isNaN(longitude)) {
            try {
              const validLocations = await axiosInstance.post(
                `order-consumer/order/service/deliveryfee/validate-location`,
                { latitude, longitude },
              );
              eachAddress.valid = validLocations?.data?.is_valid_location;
            } catch (error) {
              eachAddress['valid'] = false;
            }
          }
        }
      }
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);


export const getReverseAddress = createAsyncThunk<any, { latitude: string; longitude: string }>(
  'consumer/getReverseAddresses',
  async (coords, { rejectWithValue, dispatch }) => {
    try {
      const { data } = await axiosInstance.get(`${mapurl}reverse?format=json&lat=${coords.latitude}&lon=${coords.longitude}`);
      dispatch(
        updateCurrentLocation({
          coords: { latitude: coords.latitude, longitude: coords.longitude }, // Removed optional chaining as coords.latitude and coords.longitude are already defined
          formattedAddress: data?.display_name,
        }),
      );
      dispatch(getAddresses(data));
      // dispatch(updateFetchingLocation(false));
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);


export const addAddress = createAsyncThunk<any, ConfigData>(
  'consumer/addAddress',
  async (configData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`customer-consumer/customer/service/master/deliveryaddress`, configData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const addGuestAddress = createAsyncThunk<any, ConfigData>(
  'consumer/addGuestAddress',
  async (configData, { rejectWithValue }) => {
    try {
      const { data } = await axiosInstance.post(`/customer-consumer/customer/service/master/delivery-address`, configData);
      return data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const checkDeliveryLocation = createAsyncThunk<boolean, LocationData>(
  'consumer/checkDeliveryLocation',
  async (locationData, { rejectWithValue }) => {
    try {
      axiosInstance.defaults.headers['location'] = 'kerala';
      const { data } = await axiosInstance.post(
        `order-consumer/order/service/deliveryfee/validate-location`,
        locationData,
      );
      return data?.is_valid_location;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);




export const calculateDeliveryRate = createAsyncThunk<any, DeliveryData>(
  'consumer/calculateDeliveryFee',
  async (deliveryData, { rejectWithValue, dispatch, getState }) => {
    console.log("delivery data1", deliveryData);
    try {
      // Assuming `selectedPlanet` should come from `deliveryData` instead of `getState()`
      const selectedPlanet = deliveryData.planet_id;

      const response = await axiosInstance.post(
        `order-consumer/order/service/deliveryfee/calculate-delivery-fee`,
        {
          merchant_longitude: deliveryData?.merchant_longitude,
          merchant_latitude: deliveryData?.merchant_latitude,
          customer_longitude: deliveryData?.customer_longitude,
          customer_latitude: deliveryData?.customer_latitude,
          amount: deliveryData?.finalTotalAmount,
        },
        { headers: { version: 'v4', planetId: deliveryData?.planet_id } },
      );
      const deliveryRates = response.data;
      let deliveryAmount = deliveryRates.delivery_fee;


      console.log("delivery fee api", response);
      if (response.status === 200) {
        if (deliveryRates?.is_deliveryfee_free && deliveryData.finalTotalAmount >= deliveryRates.min_amount) {
          deliveryAmount = Math.max(deliveryRates.delivery_fee - deliveryRates.deliveryfee_free_amount, 0);
        }

        const cartBackup = JSON.parse(JSON.stringify(deliveryData.cart));
        const [currentMerchantId] = Object.keys(deliveryData.cart);

        cartBackup[currentMerchantId].delivery_boy_share = deliveryRates.delivery_boy_share > 0 ? roundToDecimalPlaces(deliveryRates.delivery_boy_share) : 0;
        cartBackup[currentMerchantId].climate_margin = deliveryRates.climate_margin > 0 ? roundToDecimalPlaces(deliveryRates.climate_margin) : 0;
        cartBackup[currentMerchantId].total_delivery_fee = deliveryAmount > 0 ? roundToDecimalPlaces(deliveryAmount) : 0;
        cartBackup[currentMerchantId].platform_share = deliveryRates.platform_share > 0 ? roundToDecimalPlaces(deliveryRates.platform_share) : 0;
        cartBackup[currentMerchantId].applied_discount_code = null;

        if (deliveryData.bill !== null) {
          cartBackup[currentMerchantId].order_total_price = deliveryData.bill.order_total_price;
          cartBackup[currentMerchantId].packaging_charge_gst = deliveryData.bill.gstPackagingCharge;
          cartBackup[currentMerchantId].delivery_charge_gst = deliveryData.bill.gstDeliveryService;
        }

        const distanceValidation = {
          consumer_merchant_distance: deliveryRates.consumer_merchant_distance,
          distance_validation_message: deliveryRates.distance_validation_message,
        };

        cartBackup[currentMerchantId].deliverycharge_discount_amount = deliveryRates.deliveryfee_free_amount;
        cartBackup[currentMerchantId].is_deliveryfee_free = deliveryRates.is_deliveryfee_free;
        cartBackup[currentMerchantId].planet_id = selectedPlanet;

        dispatch(getDistanceValidation(distanceValidation));
        dispatch(incrementQuantity(cartBackup));
        // dispatch(updateCart({
        //   customer_id: deliveryData.customerId,
        //   cart: cartBackup,
        //   // applyCoupon: false
        // }));

        return response.data;
      } else {
        throw new Error('An error has occurred');
      }
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);


export const deleteAddress = createAsyncThunk<DeleteAddressResponse, string>(
  'consumer/deleteAddress',
  async (id, { rejectWithValue }) => {
    try {
      const resp = await axiosInstance.delete(`customer-consumer/customer/service/master/deliveryaddress/${id}`);
      return { id, resp };
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const calculateDeliveryRateWithMerchantId = createAsyncThunk<number, any>(
  'consumer/generateDeliveryFee',
  async (deliveryData, { rejectWithValue }) => {
    try {
      const response = await axiosInstance.post(`order-consumer/order/service/deliveryfee/generate-delivery-fee`, deliveryData);
      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);

export const getCartAllData = createAsyncThunk<any, GetCartAllDataPayload>(
  'consumer/getCartAllData',
  async ({ merchantId }, { rejectWithValue, getState }) => {
    try {
      const url = `customer-consumer/customer/service/consumers/checkout/${merchantId}`;
      const response = await axiosInstance.post(url, {}, { headers: { version: 'v2' } });

      return response.data;
    } catch (error: any) {
      return rejectWithValue(error?.response?.data);
    }
  },
);
