import axios from "axios";
import { baseUrl } from "./apiConfig";
import { store } from "../redux/store";
import firebase, { initializeApp } from 'firebase/app';
import 'firebase/remote-config';
import { firebaseConfig } from "./firebase.config";
import { activate, fetchAndActivate, fetchConfig, getRemoteConfig, getValue } from "firebase/remote-config";

const axiosInstance = axios.create({
  baseURL: baseUrl,
  headers: {
    Accept: "*/*",
    "Content-Type": "application/json",
    version: "v1",
  },
});

const app = initializeApp(firebaseConfig);
const remoteConfig = getRemoteConfig(app);


const onRequestSuccess = async (config: any) => {
  return await authTokenSetting(config);
};

async function fetchRemoteConfigValues() {
  return fetchAndActivate(remoteConfig)
    .then(() => {

      const API_KEY = getValue(remoteConfig, 'API_KEY').asString();
      console.log('API_KEY : ' + JSON.stringify(API_KEY));
      return API_KEY;
    })
    .catch((err: any) => {
      console.log("Failed to fetch remote config", err);
      return 'error';
    });
}

const authTokenSetting = async (config: any) => {
  const remoteConfigValues = await fetchRemoteConfigValues();


  if (remoteConfigValues !== '') {
    config.headers['X-API-KEY'] = remoteConfigValues;
  }

  let deviceId = "123456";
  let deviceName = "web";
  if (!config.headers) {
    config.headers = {};
  }
  config.headers['device-id'] = deviceId;
  config.headers['device-type'] = deviceName;

  const { selectedCurrentLocation } = store.getState().location
  if (selectedCurrentLocation?.coords?.latitude) {
    config.headers.location = `${selectedCurrentLocation?.coords?.latitude},${selectedCurrentLocation?.coords?.longitude}`;
  }
  config.headers.Authorization = localStorage.getItem('accessToken');
  config.headers.language = "en_US";


  return config;
};

axiosInstance.interceptors.request.use(onRequestSuccess);

axiosInstance.interceptors.response.use(
  (response: any) => response,
  (error: any) => {
    let customResponse = error?.response;
    if (error?.response) {
      console.log(
        "axios_intercept____",
        error?.response?.status,
        JSON.stringify(error?.response?.data),
        error?.response?.data?.message
      );
      return Promise.reject(customResponse);
    }
  }
);

export default axiosInstance;
