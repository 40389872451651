import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import { API_GET_HOME_CAROUSEL, API_GET_PLANET_CAROUSEL, API_GET_PLANETS } from "../api/planet";
// import { API_GET_HOME_CAROUSEL, API_GET_PLANETS, API_GET_PLANET_CAROUSEL } from "../api/planets.service";

interface Planet {
  id: number;
  name: string;
  description: string;
  imageUrl: string;
}

interface PlanetState {
  planet: Planet[];
  selectedPlanet: string;
  loading: boolean;
  planetCarousel: Planet[];
  planetCarouselLoading: boolean;
}

const initialState: PlanetState = {
  planet: [],
  selectedPlanet: "",
  loading: false,
  planetCarousel: [],
  planetCarouselLoading: false,
};

export const getPlanets = createAsyncThunk<Planet[]>("consumers/planets", async (_, thunkAPI) => {
  try {
    const resp = await API_GET_PLANETS();
    console.log("get planet", resp)
    return resp as Planet[];
  } catch (err) {
    throw err;
  }
});

export const getHomeCarousel = createAsyncThunk<Planet[]>("consumers/homeCarousel", async (_, thunkAPI) => {
  try {
    const resp = await API_GET_HOME_CAROUSEL();
    // console.log("reducer ", resp)
    return resp as Planet[];
  } catch (err) {
    throw err;
  }
});

// export const getPlanetCarousel = createAsyncThunk<Planet[], { planet: string }>("consumers/planetCarousel", async ({ planet }, thunkAPI) => {
//   try {
//     const resp = await API_GET_PLANET_CAROUSEL(planet);
//     // console.log("reducer planet", resp)
//     return resp as Planet[];
//   } catch (err) {
//     throw err;
//   }
// });


export const getPlanetCarousel = createAsyncThunk<Planet[], { planet: string }>(
  "consumers/planetCarousel",
  async ({ planet }, thunkAPI) => {
    try {
      const resp = await API_GET_PLANET_CAROUSEL(planet);
      console.log("reducer planet", resp); // Ensure the console log is placed correctly
      return resp as Planet[];
    } catch (err) {
      console.error("Error fetching planet carousel:", err); // Handle errors properly
      throw err; // Rethrow the error to be caught by the caller
    }
  }
);

export const planetSlice = createSlice({
  name: "planet",
  initialState,
  reducers: {
    setSelectedPlanet: (state, action: PayloadAction<{ selectedPlanet: string }>) => {
      state.selectedPlanet = action.payload.selectedPlanet;
    },
  },

  extraReducers: (builder) => {
    builder.addCase(getPlanets.fulfilled, (state, action) => {
      console.log("reducer planet", action.payload)
      state.planet = action.payload;
      state.loading = false;
    });
    builder.addCase(getPlanets.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getPlanets.rejected, (state) => {
      state.loading = false;
    });
    builder.addCase(getPlanetCarousel.fulfilled, (state, action) => {
      console.log("reducer planet", action.payload)
      state.planetCarousel = action.payload;
      state.planetCarouselLoading = false;
    });
    builder.addCase(getPlanetCarousel.pending, (state) => {
      state.planetCarouselLoading = true;
    });
    builder.addCase(getPlanetCarousel.rejected, (state) => {
      state.planetCarouselLoading = false;
    });
  },
});

export const { setSelectedPlanet } = planetSlice.actions;

export default planetSlice.reducer;
