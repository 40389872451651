// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.card-title {
  font-family: Nunito;
}

.card-img-top {
  width: 100%;
  /* height: 117px; */
  aspect-ratio: 4/2;
  border-radius: 5px;
}

.card-button {
  width: 100%;
  height: 40px;
  border-radius: 5px;
  border: none;
  background-color: #28af4b;
  color: white;
  font-family: Nunito;
}
.out-of-stock-text {
  color: red;
  font-weight: bold;
  text-align: center;
}
.outofstock {
  width: 100%;
  color: red !important;
  background-color: white;
}

.desaturate {
  filter: grayscale(100%);
}

.stock-card {
  height: 100%;
  z-index:106;
}
.out-of-stock {
  color: red;
  font-weight: bold;
}
`, "",{"version":3,"sources":["webpack://./src/components/fishCard/FishCard.css"],"names":[],"mappings":"AAAA;EACE,mBAAmB;AACrB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,iBAAiB;EACjB,kBAAkB;AACpB;;AAEA;EACE,WAAW;EACX,YAAY;EACZ,kBAAkB;EAClB,YAAY;EACZ,yBAAyB;EACzB,YAAY;EACZ,mBAAmB;AACrB;AACA;EACE,UAAU;EACV,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,WAAW;EACX,qBAAqB;EACrB,uBAAuB;AACzB;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,YAAY;EACZ,WAAW;AACb;AACA;EACE,UAAU;EACV,iBAAiB;AACnB","sourcesContent":[".card-title {\n  font-family: Nunito;\n}\n\n.card-img-top {\n  width: 100%;\n  /* height: 117px; */\n  aspect-ratio: 4/2;\n  border-radius: 5px;\n}\n\n.card-button {\n  width: 100%;\n  height: 40px;\n  border-radius: 5px;\n  border: none;\n  background-color: #28af4b;\n  color: white;\n  font-family: Nunito;\n}\n.out-of-stock-text {\n  color: red;\n  font-weight: bold;\n  text-align: center;\n}\n.outofstock {\n  width: 100%;\n  color: red !important;\n  background-color: white;\n}\n\n.desaturate {\n  filter: grayscale(100%);\n}\n\n.stock-card {\n  height: 100%;\n  z-index:106;\n}\n.out-of-stock {\n  color: red;\n  font-weight: bold;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
