import React from "react";

const RefundCancellation = () => {
  return (
    <>
      <div className="col-12 d-flex justify-content-center text-start pb-4">
        <div className="row width-full-page pb-3">
          <div className="col-md-12 text-center">
            <h4 className="heading-pages pt-5">
              <span className="span-with-green">Refund & Cancellation</span>
              <span>Policy</span>
            </h4>
          </div>
          <div className="col-12 d-flex justify-content-center text-start pb-4">
            <div className="row width-full-page pb-3">
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">Introduction</div>
                <ul className="pt-3">
                  <li>
                    This Refund and Cancellation Policy ("Policy") governs the
                    terms and conditions for refund requests and order
                    cancellations on LiveLocal E-comm Private Limited
                    ("LiveLocal") hyperlocal delivery platform.
                  </li>
                  <li>
                    By using our platform and services, you acknowledge that you
                    have read, understood, and agree to abide by this Policy. If
                    you do not agree with this Policy, please do not use our
                    services.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">Refund Policy</div>
                <ul className="pt-3">
                  <li>
                    Refunds may be processed under the following circumstances:
                    <ul className="pt-3 pb-3">
                      <li>Non-Delivery: If an order is not delivered.</li>
                      <li>
                        Defective or Damaged Items: In the event that items
                        delivered are damaged or defective.
                      </li>
                      <li>
                        Overcharges: If you are charged more than the correct
                        order total due to errors in our system.
                      </li>
                    </ul>
                  </li>
                  <li>
                    To request a refund, please contact our customer support
                    within 24 hours from the order completion time.
                  </li>
                  <li>
                    Refund requests are subject to verification, and LiveLocal
                    reserves the right to deny a refund if the request does not
                    meet the eligibility criteria.
                  </li>
                  <li>
                    Refunds will be processed to the original payment method
                    used for the order.
                    <br />
                    (Net Banking, Credit Card and Wallet Payments will be
                    refunded within 5-7 days, UPI Payments will be refunded
                    within 2 hours)
                  </li>
                </ul>
              </div>
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">Cancellation Policy</div>
                <ul className="pt-3">
                  <li>
                    Users may cancel an order under the following circumstances:
                    <ul className="pt-3 pb-3">
                      <li>
                        Users may choose to cancel the order only within 15
                        seconds of the order being placed
                      </li>
                      <li>
                        LiveLocal Platform has right to collect penalties
                        cancelled by the platform for reasons not related to the
                        Livelocal platform but not limited to
                      </li>
                      <li>
                        Address provided by the Buyer is wrong , not reachable
                        or outside the delivery zone
                      </li>
                      <li>
                        Not able to contact the Buyer by phone, email at the
                        time of delivering or booking the order
                      </li>
                      <li>
                        Cancellations for reasons within the LiveLocal platform,
                        no cancellation charge will be collected from the Buyer
                      </li>
                    </ul>
                  </li>
                  <li>
                    Once a delivery provider has accepted an order, cancellation
                    may not be possible, or it may be subject to fees or
                    penalties at the discretion of the delivery provider.
                  </li>
                  <li>
                    To cancel an order, users should use the cancellation
                    feature in the application or contact our customer support.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">
                  User Responsibilities
                </div>
                <ul className="pt-3">
                  <li>
                    Users are responsible for providing accurate order details
                    and ensuring the correct delivery location.
                  </li>
                  <li>
                    Users should verify the order details before confirming an
                    order, as mistakes in orders are not eligible for refunds.
                  </li>
                  <li>
                    .Users must provide accurate payment information for
                    successful transactions.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">
                  Delivery Provider Responsibilities
                </div>
                <ul className="pt-3">
                  <li>
                    Delivery providers must fulfill orders accurately and within
                    the specified timeframe.
                  </li>
                  <li>
                    In the case of defects, damages, or non-delivery, delivery
                    providers are responsible for addressing these issues in
                    compliance with this Policy.
                  </li>
                </ul>
              </div>
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">Dispute Resolution</div>
                <ul className="pt-3">
                  <li>
                    Any dispute, controversy, or claim arising out of or
                    relating to these terms and conditions, or the breach,
                    termination, or invalidity thereof, shall be settled through
                    good faith negotiations between the parties.
                  </li>
                  <li>
                    If the dispute cannot be resolved through negotiations
                    within [number of days] days from the date on which either
                    party notifies the other party in writing of the existence
                    of such dispute, the parties agree to submit the dispute to
                    mediation administered by or any other mutually agreed-upon
                    mediation service.
                  </li>
                  <li>
                    If the dispute is not resolved through mediation within 30
                    days from the commencement of mediation, either party may
                    initiate legal proceedings in accordance with the governing
                    law and jurisdiction specified in these terms and
                    conditions.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Contact Us</div>
                <div className="inner-text-content-two pt-3">
                  If you have any questions or concerns regarding this Cookie
                  Policy, please contact us at:&nbsp;
                  <a
                    href="mailto:support@lilo.co.in"
                    className="span-with-green-mail"
                  >
                    support@lilo.co.in
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default RefundCancellation;
