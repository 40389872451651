// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.saveAndProceedButton {
  background-color: #28af4b;
  border: none;
  width: 100%;
}
.saveAndProceedButton:hover {
  border: 1px solid #28af4b;
  color: #28af4b;
  background-color: white;
}

.inputFlexContainer {
  justify-content: space-between;
}
.addressModalInputFields {
  width: 100%;
}
.addressModalheader {
  font-weight: bold;
}

/* styles.css */

.scroll-container::-webkit-scrollbar {
  width: 3px;
}

.scroll-container::-webkit-scrollbar-thumb {
  background-color: #28af4b;
  border-radius: 4px;
}

.scroll-container::-webkit-scrollbar-track {
  background-color: #f5f5f5;
}
`, "",{"version":3,"sources":["webpack://./src/components/addressModal/styles.css"],"names":[],"mappings":"AAAA;EACE,yBAAyB;EACzB,YAAY;EACZ,WAAW;AACb;AACA;EACE,yBAAyB;EACzB,cAAc;EACd,uBAAuB;AACzB;;AAEA;EACE,8BAA8B;AAChC;AACA;EACE,WAAW;AACb;AACA;EACE,iBAAiB;AACnB;;AAEA,eAAe;;AAEf;EACE,UAAU;AACZ;;AAEA;EACE,yBAAyB;EACzB,kBAAkB;AACpB;;AAEA;EACE,yBAAyB;AAC3B","sourcesContent":[".saveAndProceedButton {\n  background-color: #28af4b;\n  border: none;\n  width: 100%;\n}\n.saveAndProceedButton:hover {\n  border: 1px solid #28af4b;\n  color: #28af4b;\n  background-color: white;\n}\n\n.inputFlexContainer {\n  justify-content: space-between;\n}\n.addressModalInputFields {\n  width: 100%;\n}\n.addressModalheader {\n  font-weight: bold;\n}\n\n/* styles.css */\n\n.scroll-container::-webkit-scrollbar {\n  width: 3px;\n}\n\n.scroll-container::-webkit-scrollbar-thumb {\n  background-color: #28af4b;\n  border-radius: 4px;\n}\n\n.scroll-container::-webkit-scrollbar-track {\n  background-color: #f5f5f5;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
