import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles.css";
const AddressTypes = [
  { id: 1, address: "Home" },
  { id: 2, address: "Work" },
  { id: 3, address: "Hotel" },
  { id: 4, address: "Others" },
];
interface CustomButtonProps {
    onAddressTypeSelect: (addressType: string) => void;
}

function CustomButton({ onAddressTypeSelect }: CustomButtonProps) {
  const [selectedAddressType, setSelectedAddressType] = useState<number | null>(
    null
  );

    const handleClick = (categoryId: number, addressType: string) => {
        setSelectedAddressType(categoryId);
        onAddressTypeSelect(addressType);
    };

    return (
        <div className="customButtoncontainer mt-md-2 ">
            <div className="customButton">
                {AddressTypes?.map((address: any) => (
                    <div
                        key={address.id}
                        className={`address-category ${selectedAddressType === address.id ? "selected" : ""
                            }`}
                        onClick={() => handleClick(address.id, address.address)}
                    >
                        <span className="addressName mt-md-5">{address.address}</span>
                    </div>
                ))}
            </div>
        </div>
    );
}

export default CustomButton;
