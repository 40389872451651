import React, { useEffect, useState } from "react";
import "./productdetails.css";
// import { Carousel } from "react-responsive-carousel";
import { useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import { useLocation } from "react-router-dom";
import { cart_trolly, king_fish, share, tuna_fish } from "../../assets/images";
import Add from "../../components/fishCard/CartButtons/Add";
import Cart from "../../components/fishCard/CartButtons/Cart";
import { useSelector } from "react-redux";
import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";
import { log } from "console";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartSolid } from "@fortawesome/free-solid-svg-icons";
import { faHeart as faHeartRegular } from "@fortawesome/free-regular-svg-icons";
import Carousel from "react-multi-carousel";
import { FlyingImage } from "../../components/flyingImage/FlyingImage";
import { setFlyingImg } from "../../redux/flyingAnimation/flyingAnimationSlice";
import { useDispatch } from "react-redux";

const ProductviewDetails = () => {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const productId = queryParams.get("id");
  // const productId = location.state.product_id;
  const { customerId } = useSelector((state: any) => state.auth);
  const planetId = location.state?.planetId;

  const dispatch=useDispatch();

  interface TabContent {
    energy?: string;
    total_fat?: string;
    saturated_fat?: string;
    sodium?: string;
    carbohydrates_total?: string;
    sugars?: string;
    trans_fat?: string;
  }
  const [isFavourite, setIsFavourite] = useState(false);
  const [tabContent, setTabContent] = useState<TabContent>({});
  const [mainImage, setMainImage] = useState<string>("");
  const [additionalImages, setAdditionalImages] = useState<string[]>([]);

  interface Product {
    out_of_stock: boolean;
    product_id: string;
    parent_category_name: string;
    product_details: {
      description: string;
      images: string[] | null;
    };
    inventories: {
      [key: string]: {
        price: number;
        available_stock: number;
      };
    };
    category_name: string;
    weight: number;
    vendor_product_inventories: {
      price: number;
    }[];
  }
  const imgSrc = useSelector((state: any) => state?. flyingAnimation?.imgSrc);
  const itemOffsets = useSelector((state: any) => state?. flyingAnimation?.itemOffsets);
  
  const {
    data: productDetails,
    isLoading,
    isError,
    refetch,
  } = useQuery(["product", productId], async () => {
    try {
      const response = await axiosInstance.get(
        `${API_URL.SINGLE_PRODUCT_LIST}/${productId}`
      );
      return response.data;
    } catch (error) {
      throw new Error("Error fetching product details");
    }
  });

  useEffect(() => {
    if (productDetails) {
      setMainImage(productDetails?.product_details?.images?.[0] || "");
      setAdditionalImages(
        productDetails?.product_details?.additional_images || []
      );
    }
  }, [productDetails]);

  const handleThumbnailClick = (image: string) => {
    setMainImage(image);
  };

  const handleTabSelect = (key: string | null) => {
    if (
      key === "Nutrition" ||
      key === "Health benefits" ||
      key === "Texture and taste"
    ) {
      setTabContent(productDetails?.product_nutrition?.[0]);
    }
  };
  const { data: recomendedProduct } = useQuery<Product[]>(
    ["recomendedproducts", customerId],
    async () => {
      try {
        const response = await axiosInstance.get<Product[]>(
          `${API_URL.LAST_FIVE_PRODUCT}/${customerId}`
        );
        return response.data;
      } catch (error) {
        throw new Error("Error fetching recomended products");
      }
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (isError) return <div>Error fetching data</div>;

  const addToFavourite = async () => {
    try {
      const response = await axiosInstance.post(
        `product-consumer/products/favourite/insert`,
        {
          product_id: productId,
          planet_id: "fishPlanet",
          customer_id: customerId,
        }
      );

      if (response) {
        setIsFavourite(true);
      } else {
        console.log("error", response);
      }
    } catch (error) {
      console.log("error", error);
    }
  };

  const removeFromFavourite = async () => {
    try {
      const resp = await axiosInstance.delete(
        "product-consumer/products/favourite/remove",
        {
          data: {
            product_id: productId,
            planet_id: "fishPlanet",
            customer_id: customerId,
          },
        }
      );

      console.log("Response", JSON.stringify(resp?.data));
      setIsFavourite(false);
    } catch (error) {
      console.error("Error:", error);
      return null;
    }
  };
  const handleAddToFavourites = () => {
    if (isFavourite) {
      removeFromFavourite();
    } else {
      addToFavourite();
    }
  };

  const handleShare = () => {
    const productUrl = window.location.href;
    if (navigator.share) {
      navigator.share({
        title: "Check out this product",
        url: productUrl,
      });
    } else {
      alert(`Share this link: ${productUrl}`);
    }
  };

  return (
    <div className="product-details-container col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5 relative-div-bg">
        <div className="col-12 ">
          <div className="row ">
            <div className="col-md-4 image-container">
              <div>
                <img
                  src={mainImage}
                  alt="product"
                  className={`image-carousel-product ${
                    productDetails?.out_of_stock ? "desaturate" : ""
                  }`}
                  style={{
                    width: "100%",
                    height: "365px",
                    borderRadius: "4px",
                  }}
                />
              </div>
              <div className="mt-3 col-md-5 additional-image-container">
                {/* <Carousel responsive={responsive} swipeable={true}> */}
                {additionalImages.map((image, index) => (
                  <div
                    key={index}
                    onClick={() => handleThumbnailClick(image)}
                    style={{ cursor: "pointer" }}
                  >
                    <img
                      src={image}
                      alt="product"
                      className={`image-carousel-product ${
                        productDetails?.out_of_stock ? "desaturate" : ""
                      }`}
                      style={{
                        width: "100px",
                        height: "100px",
                        borderRadius: "4px",
                      }}
                    />
                  </div>
                ))}
                {/* </Carousel> */}
              </div>
            </div>
            <div className="col-md-7 right-product-div">
              <div className="fish-details-text">
                {productDetails?.product_name}
              </div>
              <div className="fish-details-text pt-1">
                {productDetails?.category_name}
              </div>
              <div className="fish-details-text-gm pt-1">
                {productDetails?.weight} g
              </div>
              <div className="fish-details-text pt-1">
                Price :
                <span className="weight-inner-text-heading-four">
                  {" "}
                  ₹ {productDetails?.vendor_product_inventories?.[0]?.price}
                </span>
              </div>

              <div className="col-4 pt-2">
                {productDetails?.out_of_stock ? (
                  <div className="out-of-stock">Out of Stock</div>
                ) : (
                  <Cart product={productDetails} planet={planetId} />
                )}
              </div>
              <div className="fish-desc-text pt-32 mt-4">Description</div>
              <div className="desc-text pt-1">
                {productDetails?.product_details?.description}
              </div>
              <div className="mt-3 d-flex align-items-center">
                <div
                  className="d-flex align-items-center"
                  onClick={handleAddToFavourites}
                  style={{ cursor: "pointer", marginRight: "15px" }}
                >
                  <FontAwesomeIcon
                    icon={isFavourite ? faHeartSolid : faHeartRegular}
                    style={{
                      fontSize: "18px",
                      borderColor: "1px solid #333132",
                      color: isFavourite ? "green" : "#333132",
                      transition: "color 0.3s",
                    }}
                    className={isFavourite ? "icon-hover" : ""}
                  />
                  <span className="ms-2">Add to Favourites</span>
                </div>

                <div
                  className="d-flex align-items-center"
                  onClick={handleShare}
                  style={{ cursor: "pointer" }}
                >
                  {/* <FontAwesomeIcon
                    icon={faShareAlt}
                    className="icon-share"
                    style={{ fontSize: "24px" }}
                  /> */}
                  <img className="img-fluid" src={share} />
                  <span className="ms-2">Share this Product</span>
                </div>
              </div>
            </div>
          </div>

          <div className="row mt-5">
            {productDetails?.product_nutrition?.[0] ||
            productDetails?.health_benefits ||
            productDetails?.taste_and_textures ? (
              <Tabs
                defaultActiveKey="Nutrition"
                id="uncontrolled-tab-example"
                className="mb-3 ov"
                onSelect={handleTabSelect}
              >
                {productDetails?.product_nutrition[0] && (
                  <Tab
                    eventKey="Nutrition"
                    title="Nutrition ( per 100g serving )"
                  >
                    <div className="tab-text">
                      &#8226; Energy:{" "}
                      {productDetails?.product_nutrition?.[0].energy}
                    </div>
                    <div className="tab-text">
                      &#8226; Total Fat:{" "}
                      {productDetails?.product_nutrition[0]?.total_fat}
                    </div>
                    <div className="tab-text">
                      &#8226; Saturated Fat:{" "}
                      {productDetails?.product_nutrition[0]?.saturated_fat}
                    </div>
                    <div className="tab-text">
                      &#8226; Sodium:{" "}
                      {productDetails?.product_nutrition[0]?.sodium}
                    </div>
                    <div className="tab-text">
                      &#8226; Carbohydrates:{" "}
                      {
                        productDetails?.product_nutrition[0]
                          ?.carbohydrates_total
                      }
                    </div>
                    <div className="tab-text">
                      &#8226; Sugars:{" "}
                      {productDetails?.product_nutrition[0]?.sugars}
                    </div>
                    <div className="tab-text">
                      &#8226; Trans Fat:{" "}
                      {productDetails?.product_nutrition[0]?.trans_fat}
                    </div>
                  </Tab>
                )}

                {productDetails?.product_details?.health_benefits && (
                  <Tab eventKey="Health benefits" title="Health benefits">
                    <div className="tab-text">
                      {productDetails?.product_details?.health_benefits}
                    </div>
                  </Tab>
                )}

                {productDetails?.product_details?.taste_and_textures && (
                  <Tab eventKey="Texture and taste" title="Texture and taste">
                    <div className="tab-text">
                      {productDetails?.product_details?.taste_and_textures}
                    </div>
                  </Tab>
                )}
              </Tabs>
            ) : null}
          </div>
          {imgSrc && itemOffsets &&(
        <FlyingImage
          imgSrc={imgSrc} 
          itemOffsets={itemOffsets} 
          onAnimationEnd={() => dispatch(setFlyingImg({imgSrc:null,itemOffsets:null}))} 
        />
      )} 
          <div className="col-12 pt-3">
            {/* <div className="benifit-head-text">Health benefits</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.health_benefits}
            </div>
            <div className="benifit-head-text pt-1">Texture and taste</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.taste_and_textures}
            </div>
            <div className="benifit-head-text pt-1">Type of fish</div>
            <div className="benifit-sub-text">
              {" "}
              {productDetails?.product_details?.type_of_fish}
            </div>
            <div className="benifit-head-text pt-1">Typical Kerala dishes</div>
            <div className="benifit-sub-text">
              {productDetails?.product_details?.type_of_fish}
            </div> */}

            <div className="col-md-12 cart-container  pt-3">
              <div className="row pt-2">
                <div className="col-md-12 benifit-sub-text">
                  <img src={cart_trolly} alt="Cart" />
                  <span className="cart-text ps-2">Customers also bought:</span>
                </div>
              </div>
              <div className="row pt-2">
                {recomendedProduct &&
                  recomendedProduct.map((product: any) => (
                    <div className="col-md-3 mb-3" key={product.product_id}>
                      <div className="card">
                        <div className="card-body">
                          {product?.product_details?.images && (
                            <img
                              className="card-img-top"
                              src={product?.product_details?.images?.[0]}
                              alt="Recommended Product"
                            />
                          )}
                          <div className="fish-details-text pt-2">
                            {product?.parent_category_name}
                          </div>
                          <div className="fish-details-text">
                            {product?.category_name} | {product?.weight} g
                          </div>
                          <div className="fish-details-text">
                            Price:{" "}
                            <span className="cart-fish-price">
                              ₹{" "}
                              {product?.vendor_product_inventories?.[0]?.price}
                            </span>
                          </div>
                          <div className=" add-button-container pt-2">
                            <Cart product={product} />
                          </div>
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductviewDetails;
