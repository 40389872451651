// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.div-relative-bestpick {
  position: relative;
  background: transparent !important;
  border-radius: 5px;
}
.div-absolute-bestpicks-one {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -2;
  border-radius: 5px;
}
.image-bg-bestpicks {
  height: 100%;
  width: 100%;
  object-fit: cover;
  border-radius: 5px;
}
.text-div-bestpicks {
  color: white;
}
.div-absolute-bestpicks-two {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
  background-color: #00000086;
  border-radius: 5px;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomePage/BestPicks/bestpicks.css"],"names":[],"mappings":"AAAA;EACE,kBAAkB;EAClB,kCAAkC;EAClC,kBAAkB;AACpB;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,kBAAkB;AACpB;AACA;EACE,YAAY;EACZ,WAAW;EACX,iBAAiB;EACjB,kBAAkB;AACpB;AACA;EACE,YAAY;AACd;AACA;EACE,kBAAkB;EAClB,MAAM;EACN,OAAO;EACP,WAAW;EACX,YAAY;EACZ,WAAW;EACX,2BAA2B;EAC3B,kBAAkB;AACpB","sourcesContent":[".div-relative-bestpick {\n  position: relative;\n  background: transparent !important;\n  border-radius: 5px;\n}\n.div-absolute-bestpicks-one {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: -2;\n  border-radius: 5px;\n}\n.image-bg-bestpicks {\n  height: 100%;\n  width: 100%;\n  object-fit: cover;\n  border-radius: 5px;\n}\n.text-div-bestpicks {\n  color: white;\n}\n.div-absolute-bestpicks-two {\n  position: absolute;\n  top: 0;\n  left: 0;\n  width: 100%;\n  height: 100%;\n  z-index: -1;\n  background-color: #00000086;\n  border-radius: 5px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
