import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import {
  API_GET_CART,
  API_GET_REMOVE_ITEM_CART,
  API_POST_CART,
  API_PUT_CART,
} from "../../api/cart";
// import { setSelectedPromoCode } from "../promoSlice";
import { resetCartAllData } from "../location/locationSlice";
import CryptoJS from "crypto-js";

interface Product {
  product_id: number;
  name: string;
  price: number;
  quantity: number;
}

export interface productItem {
  product_name: string;
  product_id: number;
  merchant_id: string;
  merchant_latitude: string;
  merchant_longitude: string;
  shop_id: string;
  gst: number;
  price?: number;
  quantity: number;
  planet_id: string | undefined;
  cartList?: any[] | undefined;
  product_category_id: string;
  parent_category_id: string;

  group_id: string;

  rating: number;

  local_language_description: string | null;
}

export interface insertProduct {
  product_id?: string;
  base_product_id?: string;
  product_name?: string;
  product_description?: string;
  merchant_id?: string;
  quantity: number;
  item_id: string;
  promotion_id: string;
  price: number;
  toppings: any[];
  buy_more_pay_less: any;
  discount_event: any;
  variant: any;
  shop_id?: string;
  merchand_latitude?: number;
  merchand_longitude?: number;
  planet_id: string;
}

interface ProductState {
  productList: Product[];
  loading: boolean;
  cartList: productItem[];
  deliverySlot?: { startTime: string; endTime: string };
  status: "idle" | "loading" | "succeeded" | "failed";
  count: number;
  foodCount: number;
  incrementQuantity?: number;
  error: string | null;
  location: {
    latitude: number | string | null;
    longitude: number | string | null;
  };
  bill: number;
  offer: number;
  upiData: any | null; // Adjust this type as needed
  offerData: any | null; // Adjust this type as needed
  cartPlanet: string;
  distanceValidation: any | null;
  imgSrc?: string | null;
  itemOffsets?: { top: number; left: number } | null;
}

interface ProductResponse {
  product_id: number;
  merchant_id: string;
  product_category_id: string;
  parent_category_id: string;
  cartList?: [];
  group_id: string;
  product_name: string;
  planet_id: string;
  rating: number;
  merchant_description: string;
  total_reviews: number;
  label: string;
  model_code: string | null;
  tag_code: string | null;
  review: string | null;
  record_status: number;
  brand: string | null;
  business_area: string;
  quantity: number;
  product_details: {
    description: string;
    short_description: string | null;
    details: string | null;
    images: string[];
    toppings: string[] | null;
    product_category_description: string | null;
    product_type: string | null;
    last_order_date: string | null;
  };
  product_specification: string | null;
  variants: any[];
  custom_attributes_list: {
    name: string;
    description: string;
  }[];
  gst: number;
  weight: number;
  parents: {
    depth: number;
    parent_name: string;
    parent_id: string;
  }[];
  inventories: {
    [shopId: string]: {
      product_vendor_id: string;
      price: number;
      original_amount: number | null;
      reorder_level: number;
      max_quantity: number;
      available_stock: number;
      vendor_id: string;
      vendor_name: string | null;
      promotional_prices: any[] | null;
      sales_quantity: number[] | null;
      buy_more_payless_option: any[] | null;
    };
  };
  vendors: any[] | null;
  available_countries: string[];
  vendor_product_inventories: {
    product_vendor_id: string;
    price: number;
    original_amount: number | null;
    reorder_level: number;
    max_quantity: number;
    available_stock: number;
    vendor_id: string;
    vendor_name: string | null;
    promotional_prices: any[] | null;
    sales_quantity: number[] | null;
    buy_more_payless_option: any[] | null;
  }[];
  vendor_IDs: string[];
  is_offer_available: boolean;
  sales_quantity: number;
  product_order_count: number;
  created_at: string | null;
  status: string;
  score: number;
  merchant_latitude: string;
  merchant_longitude: string;
  shop_name: string | null;
  uber_h3_index_3km: string | null;
  uber_h3_index_8km: string | null;
  uber_h3_index_22km: string | null;
  uber_h3_index_1km: string | null;
  uber_h3_index_170m: string | null;
  uber_h3_index_460m: string | null;
  product_timings: {
    day: string;
    timings: {
      from: string;
      to: string;
    }[];
  }[];
  is_available: boolean;
  created_by: string;
  last_updated_by: string | null;
  updated_at: string | null;
  search_rank: number;
  out_of_stock: boolean;
  promotion_type: string | null;
  promotion_code: string | null;
  discount_description: string | null;
  shop_id: string;
  base_product_id: string;
  display_stock: boolean;
  sub_planet: string | null;
  local_language_name: string | null;
  local_language_description: string | null;
}
interface Payload {
  [key: string]: { planet_id?: string | undefined };
}
interface newItem {
  planet_id: string;
}
const initialState: ProductState = {
  productList: [],
  loading: false,
  cartList: [],
  status: "idle",
  count: 0,
  foodCount: 0,

  deliverySlot: {
    startTime: "",
    endTime: "",
  },

  error: null,
  location: {
    latitude: "",
    longitude: "",
  },
  bill: 0,
  offer: 0,
  upiData: null,
  offerData: null,
  cartPlanet: "",
  distanceValidation: null,
 

};
interface CartItem {
  product_id: string;
  quantity: number;
  delivery_boy_share: string;
  climate_margin: string;
  total_delivery_fee: string;
  platform_share: string;
  applied_discount_code: string;
}
interface ShopAddress {
  pinCode: string | null;
  businessName: string | null;
  address: string | null;
  landmark: string | null;
  city: string;
  district: string;
  state: string | null;
  longitude: string;
  latitude: string;
  streetOrArea: string | null;
  ward: string | null;
  number_and_streetname: string | null;
  estimated_delivery_time: string | null;
  estimated_delivery_fee: string | null;
  shop_entity: string | null;
  
}

interface ShopInformation {
  shop_address: ShopAddress;
  shop_name: string | null;
  shop_id: string;
  image: string | null;
  merchant_Logo: string | null;
}

export const getCart = createAsyncThunk(
  "consumers/view/cart",
  async ({ customer_id }: { customer_id: string }) => {
    try {
      const resp = await API_GET_CART(customer_id);
      return resp;
    } catch (err) {
      throw err;
    }
  }
);

export const insertIntoCart = createAsyncThunk(
  "consumers/insert/cart",
  async (
    { customer_id, data }: { customer_id: string; data: any },
    thunkAPI
  ) => {
    try {
      const resp = await API_POST_CART(customer_id, data);
      // let planet_id = resp?.planet_id;

      // if (planet_id) {
      //   thunkAPI.dispatch(setCartPlanet(planet_id));

      // }
      return resp;
    } catch (err: any) {
      console.error("Error inserting into cart:", err);
      return thunkAPI.rejectWithValue(
        err.message || "Failed to insert into cart"
      );
    }
  }
);

const productSlice = createSlice({
  name: "products",
  initialState,
  reducers: {
    setProductsLoading(state) {
      state.status = "loading";
    },

    setProducts(state, action: PayloadAction<Product[]>) {
      state.status = "succeeded";
      state.productList = action.payload;
    },

    setProductsError(state, action: PayloadAction<string>) {
      state.status = "failed";
      state.error = action.payload;
    },

    addToCart(state, action: PayloadAction<productItem>) {
      const productToAdd = action.payload;

      if (!Array.isArray(state.cartList)) {
        state.cartList = [];
      }
      const existingProductIndex = state.cartList.findIndex(
        (item) => item.product_id === productToAdd.product_id
      );
      if (existingProductIndex !== -1) {
        const updatedCartList = [...state.cartList];
        updatedCartList[existingProductIndex].quantity++;
        state.cartList = updatedCartList;
      } else {
        state.cartList = [...state.cartList, { ...productToAdd, quantity: 1 }];
      }
      if (action.payload.planet_id === '"fishPlanet') {
        state.count++;
      } else {
        state.foodCount++;
      }
    },
    removeFromCart(state, action: PayloadAction<number>) {
      const productIdToRemove = action.payload;
      state.cartList = state.cartList.filter(
        (item) => item.product_id !== productIdToRemove
      );
      state.count--;
    },

    incrementQuantity: (state, action: PayloadAction<number>) => {
      const productId = action.payload;

      const productToIncrement = state.cartList.find(
        (item) => item.product_id === productId
      );
      if (productToIncrement) {
        productToIncrement.quantity++;
        state.count++;
      } else {
        console.log("Product not found in cart:", productId);
      }
    },

    decrementQuantity: (state, action: PayloadAction<number>) => {
      const productId = action.payload;
      const itemIndex = state.cartList.findIndex(
        (item) => item.product_id === productId
      );

      if (itemIndex !== -1) {
        const item = state.cartList[itemIndex];
        if (item.quantity === 1) {
          state.cartList.splice(itemIndex, 1);
        } else {
          state.cartList[itemIndex].quantity -= 1;
        }
      }
    },
    updateCartCount(state, action) {
      const { productId, quantity } = action.payload;

      state.count += quantity;
    },
    updateDeliverySlot(
      state,
      action: PayloadAction<{ startTime: any; endTime: any }>
    ) {
      state.deliverySlot = action.payload;
    },

    cleanCart: (state) => {
      state.cartList = [];
    },

    setBill: (state, action: PayloadAction<number>) => {
      state.bill = action.payload;
    },
    setUPIData: (state, action: PayloadAction<any>) => {
      state.upiData = action.payload;
    },
    setOfferData: (state, action: PayloadAction<any>) => {
      state.offerData = action.payload;
    },
    resetOfferData: (state) => {
      state.offerData = null;
    },
    setCartPlanet: (state, action: PayloadAction<string>) => {
      state.cartPlanet = action.payload;
    },
    getDistanceValidation: (state, action: PayloadAction<any>) => {
      state.distanceValidation = action.payload;
    },


    setLocation(
      state,
      action: PayloadAction<{ latitude: number; longitude: number }>
    ) {
      state.location = action.payload; // { longitude: "76.8621733", latitude: "8.598075" };
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getCart.fulfilled, (state, action) => {
        state.cartList = action.payload;
        // state.unavailableProducts = Object?.values(action?.payload)?.[0]?.products?.filter((item) => item?.is_product_available === false);
        state.loading = false;
      })
      .addCase(getCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(getCart.rejected, (state) => {
        state.cartList = [];
        state.loading = false;
      })

      .addCase(insertIntoCart.fulfilled, (state, action) => {
        console.log(
          "decrypted action.payload" + JSON.stringify(action.payload)
        );

        const cartMerchantIds = Object.keys(action.payload) as string[];

        console.log(
          "decrypted action.payload",
          cartMerchantIds,
          "decr",
          action.payload
        );

        cartMerchantIds.forEach((cartMerchantId: string) => {
          const merchantData = action.payload[cartMerchantId];
          if (merchantData && merchantData.products) {
            const productsForMerchant = merchantData.products;

            state.cartList.push(...productsForMerchant);
          }
        });
        state.cartList = action.payload;
        state.loading = false;
      })

      .addCase(insertIntoCart.pending, (state) => {
        state.loading = true;
      })
      .addCase(insertIntoCart.rejected, (state) => {
        console.log("decrypted action failed");
        state.loading = false;
      });
  },
});

export const {
  setProductsLoading,
  setProducts,
  setProductsError,
  addToCart,
  updateCartCount,
  removeFromCart,
  resetOfferData,
  setLocation,
  incrementQuantity,
  decrementQuantity,
  cleanCart,
  updateDeliverySlot,
  setBill,
 
  getDistanceValidation,
} = productSlice.actions;

export const selectProductList = (state: { products: ProductState }) =>
  state.products.productList;

export const selectCartList = (state: { products: ProductState }) =>
  state.products.cartList;

export const selectIncrementQuantity = (state: { products: ProductState }) =>
  state.products.incrementQuantity; // Selector for accessing the incrementQuantity reducer
export default productSlice.reducer;
function setOfferData(arg0: { remainingAmount: number }): any {
  throw new Error("Function not implemented.");
}
function setCartPlanet(planet_id: string): any {
  throw new Error("Function not implemented.");
}
