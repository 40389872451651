import React, { useState } from "react";
import { useQuery } from "react-query";
import "./DeliverySlot.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import axiosInstance from "../../services/axios.instance";
import { calenderIcon } from "../../assets/images";
import { API_URL } from "../../services/apiConfig";
import { useSelector, useDispatch } from "react-redux";
import { updateDeliverySlot } from "../../redux/productList/productListSlice";

function DeliverySlot() {
  const [selectedSlot, setSelectedSlot] = useState<string>("");

  const dispatch = useDispatch();

  const merchantId = useSelector(
    (state: any) => state.merchant?.selectedMerchant?.id
  );

  function formatTime(timeString: string) {
    const date = new Date(timeString);
    const hours = date.getHours();
    const minutes = date.getMinutes().toString().padStart(2, "0");
    const period = hours >= 12 ? "PM" : "AM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${minutes} ${period}`;
  }

  const { data, isLoading, error } = useQuery<any[], Error>(
    "deliverySlots",
    async () => {
      try {
        if (merchantId) {
          const response = await axiosInstance.post(
            API_URL.DELIVERY_SLOT,
            {
              planet_id: "fishPlanet",
              merchant_id: merchantId,
            },
            {
              headers: {
                version: "v2",
              },
            }
          );
          return response.data;
        }
        return [];
      } catch (error) {
        throw new Error("Failed to fetch delivery slots");
      }
    }
  );

  if (isLoading) return <div>Loading...</div>;
  if (error) return <div>Error: {error.message}</div>;

  const currentTime: Date = new Date();

  const todaySlots = data
    ?.filter(
      (slot) =>
        !slot.is_delivery_tomorrow &&
        new Date(slot.order_start_time) <= currentTime &&
        new Date(slot.order_end_time) > currentTime
    )
    .map((slot) => ({
      ...slot,
      label: `Today (${formatTime(slot.delivery_start_time)} - ${formatTime(
        slot.delivery_end_time
      )})`,
    }));

  const tomorrowSlots = data
    ?.filter((slot) => slot.is_delivery_tomorrow)
    .map((slot) => ({
      ...slot,
      label: `Tomorrow (${formatTime(slot.delivery_start_time)} - ${formatTime(
        slot.delivery_end_time
      )})`,
    }));

  const allSlots = [...(todaySlots || []), ...(tomorrowSlots || [])];

  const handleChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedValue = e.target.value;
    const selectedSlot = allSlots.find((slot) => slot.label === selectedValue);

    if (selectedSlot) {
      setSelectedSlot(selectedValue);
      dispatch(
        updateDeliverySlot({
          startTime: selectedSlot.delivery_start_time,
          endTime: selectedSlot.delivery_end_time,
        })
      );
    }
  };

  return (
    <div className="col-12 deliverySlot-container">
      <div className="row">
        <div className="express-card col-md-12">
          <div className="row align-items-center">
            <div className="col-md-12 d-flex align-items-center">
              <img src={calenderIcon} alt="time" className="mr-2" />
              &nbsp;
              <label htmlFor="slotDropdown" className="slot-title">
                Choose delivery slot
              </label>
            </div>
          </div>
          <div className="row pt-3">
            <div className="col-md-9">
              <div className="select-wrapper">
                <select
                  id="slotDropdown"
                  onChange={handleChange}
                  className="select-time-slot"
                >
                  <option value="">Select a time slot</option>
                  {allSlots.map((slot, index) => (
                    <option key={index} value={slot.label}>
                      {slot.label}
                    </option>
                  ))}
                </select>
                <FontAwesomeIcon icon={faCaretDown} className="select-icon" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default DeliverySlot;
