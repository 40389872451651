import React from "react";
import { CategoryImageicon } from "../../../assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, CardBody } from "reactstrap";
import "./freshfish.css";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

interface category {
  category_id: string;
  name: string;
}

const FreshFishhome = () => {
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const navigate = useNavigate();
  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 5,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const handleImageClick = (category_id: string) => {
    console.log("fish id..", category_id);
    navigate("/FishCategory", { state: { id: category_id } });
  };

  const { data: response, refetch } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        `${API_URL.FRESH_FISH_HOME}`,
        {
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          businessArea: "fishPlanet",
        },
        {
          headers: { planetId: "fishPlanet" },
        }
      ),
    queryKey: ["getCarousal_Fish", location?.latitude],
    enabled: !!location?.latitude,
  });

  const freshFish = response?.data || [];

  return (
    <div className="col-md-12 pt-4 ">
      <div className="div-caroysel-relative">
        {freshFish && freshFish.length > 0 && (
          <div className="sub-div-headings d-flex ">
            <img src={CategoryImageicon} alt="" />
            &nbsp;
            <span>Fresh Fish</span>&nbsp;
            <span className="line-span">
              <div className="line-div-max-length-ecommerce"></div>
            </span>
          </div>
        )}
        <div className="col-md-12">
          <Carousel responsive={responsive} swipeable={true}>
            {freshFish.map((fish: any) => (
              <div className="p-2 content-fishcard" key={fish.category_id}>
                <Card className="card-freshfish-carousel card-hghlght">
                  <CardBody>
                    <div className="text-center">
                      <img
                        src={fish.stock_photo}
                        alt=""
                        className="img-freshfish-carousel"
                        onClick={() => handleImageClick(fish.category_id)}
                      />
                    </div>
                    <div className="sub-text-pages text-center pt-1">
                      {fish.category_id}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </Carousel>
        </div>
      </div>
    </div>
  );
};

export default FreshFishhome;
