import React, { useState } from "react";
import "bootstrap/dist/css/bootstrap.min.css";
import { hook_Img } from "../../assets/images";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

interface FilterProps {
  onTypeofCutChange: (selectedTypeofCut: string) => void;
  onQuantityChange: (selectedQuantity: string) => void;
}

function Catogeryfilter({ onTypeofCutChange, onQuantityChange }: FilterProps) {
  const [typeOfCut, setTypeofCut] = useState("");
  const [quantity, setQuantity] = useState("");
  const { productList } = useSelector((state: any) => state.productDataList);

  const filterUniqueValues = (data: any[] = [], key: string) => {
    if (!data) return [];
    const uniqueValues = new Set(data.map((item) => item[key]));
    return Array.from(uniqueValues);
  };

  const handleTypeofCutChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedTypeofCut = event.target.value;
    setTypeofCut(selectedTypeofCut);
    onTypeofCutChange(selectedTypeofCut);
  };

  const handleQuantityChange = (
    event: React.ChangeEvent<HTMLSelectElement>
  ) => {
    const selectedQuantity = event.target.value;
    setQuantity(selectedQuantity);
    onQuantityChange(selectedQuantity);
  };

  const location = useLocation();
  const fishId = location.state?.id;

  return (
    <div className="row filterButtons text-md-start pt-4">
      <div className="col-md-12 pt-4 text-center">
        <div className="sub-div-headings">{fishId}</div>
      </div>
      <div className="col-md-6 row">
        <div className="col-md-4">
          <select
            id="product"
            className="custom-select-filter"
            value={typeOfCut}
            onChange={handleTypeofCutChange}
          >
            <option value="">Type of Cut</option>
            {filterUniqueValues(productList.content, "category_name").map(
              (typeOfCut: any, index: number) => (
                <option key={`typeOfCut_${index}`} value={typeOfCut}>
                  {typeOfCut}
                </option>
              )
            )}
          </select>
        </div>
        <div className="col-md-4">
          <select
            id="typeofCut"
            className="custom-select-filter"
            value={quantity}
            onChange={handleQuantityChange}
          >
            <option value="">Quantity</option>
            {filterUniqueValues(productList.content, "weight").map(
              (quantity: any, index: number) => (
                <option key={`quantity_${index}`} value={quantity}>
                  {quantity}gm
                </option>
              )
            )}
          </select>
        </div>
      </div>
    </div>
  );
}

export default Catogeryfilter;
