import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { banner, succesicon } from "../../assets/images";

interface confirmModalProps {
  isOpenModal: boolean;
  onCloseModal: () => void;
  toggleModal: () => void;
  message: string;
  onConfirm: () => void;
}

const confirmModal: React.FC<confirmModalProps> = ({
  isOpenModal,
  onCloseModal,
  toggleModal,
  message,
  onConfirm,
}) => {
  return (
    <Modal isOpen={isOpenModal} toggle={onCloseModal} backdrop="static">
      <ModalBody>
        <div>
          <div className="text-end">
            <Button onClick={onCloseModal} className="btn btn-danger">
              X
            </Button>
          </div>
          <div className="p-2 text-center">
            <img
              src={succesicon}
              alt=""
              className="img-fluid img-modal-icons"
            />
            <div className="sub-div-headings pt-3">
              {" "}
              <h6>
                Do you want to replace this product with the already existing
                cart
              </h6>
            </div>
            <div className="pt-3">
              <button
                className="btn green-button-style px-3"
                onClick={onConfirm}
              >
                Confirm
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default confirmModal;
