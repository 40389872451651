import React from "react";
import "./Banner.css";
import "bootstrap/dist/css/bootstrap.min.css";
import { fish_wood, fishmaid_logo, ellipse, banner } from "../../assets/images";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from "react-responsive-carousel";
import { useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { useSelector } from "react-redux";
import { API_URL } from "../../services/apiConfig";
import { useLocation } from "react-router-dom";

function Banner() {
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const { pathname } = useLocation();
  // alert(pathname);

  // const apiURL =
  //   pathname === "/FreshFish"
  //     ? API_URL.CAROUSEL_LIST
  //     : API_URL.CAROUSEL_LIST_FOOD;
  let apiURL: string;
  if (pathname === "/FreshFish") {
    apiURL = API_URL.CAROUSEL_LIST;
  } else if (pathname === "/Food") {
    apiURL = API_URL.CAROUSEL_LIST_FOOD;
  } else if (pathname === "/Grocery") {
    apiURL = API_URL.CAROUSEL_LIST_GROCERY;
  }

  const { data: carousal, refetch } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        apiURL,
        { latitude: location?.longitude, longitude: location?.latitude },
        { headers: { version: "v2" } }
      ),

    queryKey: ["getCarousal", location?.latitude, pathname],
    enabled: !!location?.latitude,
  });

  return (
    <div className="banner-container mt-md-5 z-2 ml-3">
      <Carousel
        autoPlay
        interval={3000}
        transitionTime={1000}
        infiniteLoop
        showThumbs={false}
      >
        {carousal?.data?.map((obj: any) => (
          <div key={obj.id}>
            <img src={obj.imageUrl} alt="banner" className="image-carousel" />
          </div>
        ))}
      </Carousel>
    </div>
  );
}

export default Banner;
