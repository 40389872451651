import React from "react";
import { previousordericon } from "../../../assets/images";

const AboutUs = () => {
  return (
    <div className="col-md-12 height-custom pt-5 text-start">
      <div className="col-md-12 text-center pt-4">
        <h4 className="heading-pages">
          About <span className="span-with-green">Us</span>
        </h4>
        <div className="sub-text-pages pt-4">
          Welcome to LILO, Your <span className="span-with-green">Trusted</span>{" "}
          Source for <span className="span-with-green">Fresh, Cut,</span> and{" "}
          <span className="span-with-green">Clean</span> Fish Online.
        </div>{" "}
      </div>
      <div className="innerdiv-center">
        <div className="col-md-12  pb-3 relative-div-bg  text-start">
          <div className="row">
            {/* firstdiv */}
            {/* <div className="col-md-4 pt-4  text-center ">
              <img src="" alt="" className="img-fluid" />
            </div> */}
            <div className="col-md-12">
              <div className="">
                <div className="sub-div-headings d-flex pt-4">
                  <span>Our</span>&nbsp;
                  <span className="span-with-green">Journey</span>&nbsp;
                  <span className="line-span">
                    <div className="line-div-max-length"></div>
                  </span>
                </div>
                <div className="inner-text-content-two pt-4">
                  <div>
                    <span className="span-with-green">LILO</span> was founded in
                    2023 with a simple yet profound mission: to make fresh,
                    high-quality seafood accessible to everyone.
                  </div>
                  <br />
                  <div className="pt-4">
                    Born from a passion for the sea and its bounty, our journey
                    began on the docks of Kovalam, where we forged lasting
                    relationships with local fishermen and aquaculture
                    enthusiasts. Our commitment to freshness and sustainability
                    has been the cornerstone of our business, guiding us from
                    humble beginnings to becoming a leading online marketplace
                    for seafood lovers in Kerala.
                  </div>
                </div>
              </div>
            </div>
            {/* second div */}
            <div className="col-md- pt-4 pb-3">
              <div className="sub-div-headings d-flex pt-4">
                <span>Our </span>&nbsp;
                <span className="span-with-green">Vision</span>&nbsp;
                <span className="line-span">
                  <div className="line-div-max-length"></div>
                </span>
              </div>
              <div className="inner-text-content-two pt-4">
                To revolutionize the way seafood is enjoyed by bringing the
                freshest, sustainably sourced fish directly from the sea to your
                table. We envision a world where everyone has access to
                nutritious, delicious, and ethically sourced seafood,
                contributing to a healthier society and a thriving, sustainable
                marine ecosystem.
              </div>
            </div>
            {/* 3rd div */}
            <div className="col-md-12 pt-4">
              <div className="sub-div-headings d-flex pt-4">
                <span>Our</span>&nbsp;
                <span className="span-with-green">Mission</span>&nbsp;
                <span className="line-span">
                  <div className="line-div-max-length"></div>
                </span>
              </div>
              <div className="inner-text-content-two">
                <ul className="list-about-us">
                  <li>
                    <span className="inner-text-heading-two">
                      To Deliver{" "}
                      <span className="span-with-green">
                        {" "}
                        Unmatched Freshness
                      </span>
                      :
                    </span>
                     We go to great lengths to ensure that our seafood is the
                    freshest available, implementing rigorous quality checks and
                    state-of-the-art preservation techniques from catch to
                    delivery.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      To Champion{" "}
                      <span className="span-with-green">Sustainability</span>:
                    </span>{" "}
                    We are committed to responsible sourcing practices,
                    partnering with suppliers who share our dedication to the
                    health of our oceans. Our goal is to support and promote
                    sustainable fishing and farming practices that protect
                    marine life and habitats.
                  </li>
                  <li>
                    <span className="inner-text-heading-two">
                      To{" "}
                      <span className="span-with-green">
                        Educate and Inspire
                      </span>
                      :
                    </span>
                     We believe in empowering our customers with knowledge about
                    the seafood they consume, including its origins, nutritional
                    benefits, and the best preparation methods. Our mission is
                    to inspire a community of seafood enthusiasts who value
                    quality, transparency, and sustainability.
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-12 relative-div-bg">
              <div className="absolute-side-div-img-aboutus-bottom" />
              <div className="row  ">
                <div className="col-md-12 pt-4">
                  <div className="sub-div-headings d-flex pt-4">
                    <span>Our</span>&nbsp;
                    <span className="span-with-green">
                      Ethical Sourcing Practices
                    </span>
                    &nbsp;
                    <span className="line-span">
                      <div className="line-div-max-length"></div>
                    </span>
                  </div>
                  <div className="inner-text-content-two pt-3">
                    At LILO, ethical sourcing is not just a policy; it's a
                    promise. We meticulously select our partners based on their
                    commitment to environmental stewardship and ethical
                    practices. Our criteria include:
                  </div>
                  <div>
                    <ul className="list-about-us">
                      <li>
                        <span className="inner-text-heading-two">
                          Sustainable Harvesting Methods:
                        </span>
                         Ensuring our seafood comes from sources that use
                        practices aimed at preserving fish populations and the
                        marine environment.
                      </li>
                      <li>
                        <span className="inner-text-heading-two">
                          Traceability:
                        </span>
                         We prioritize transparency, offering our customers
                        detailed information about the source and journey of
                        their seafood.
                      </li>
                      <li>
                        <span className="inner-text-heading-two">
                          Supporting Local Communities:
                        </span>
                        as By partnering with local fishermen and aquaculture
                        farms, we help sustain livelihoods and promote economic
                        growth within those communities.
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
