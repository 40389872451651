import React, { useEffect, useState } from "react";
import { Outlet, useLocation } from "react-router-dom";
import Header from "../../components/header/Header";
import Banner from "../../components/banner/Banner";
import FishCard from "../../components/fishCard/FishCard";
import Filter from "../../components/filterComponent/filterComponent";
import Footer from "../../components/footer/Footer";

import "../css/style.css";
import "../css/ecommerce.css";
import "./Home.css";
import ScrollToTop from "../../components/ScrollToTop";

function Home() {
  const [currentPage, setCurrentPage] = useState("Home");
  return (
    <>
      <ScrollToTop />
      <Header currentPage={currentPage} setCurrentPage={setCurrentPage} />
      <div className="unknown-div" />
      <Outlet />
      <Footer currentPage={currentPage} setCurrentPage={setCurrentPage} />
    </>
  );
}

export default Home;
