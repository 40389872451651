import { createAsyncThunk, createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  getAddresses,
  deleteAddress,
  addAddress,
  checkDeliveryLocation,
  calculateDeliveryRate,
  calculateDeliveryRateWithMerchantId,
  getAddressesForCart,
  getCartAllData
} from './locationAction';
import { log } from 'console';


interface Coordinates {
  latitude: string;
  longitude: string;
}

interface Address {
  coords: Coordinates;
  formattedAddress: string;
  addressId?: string;
  addressType?: string;
}

interface LocationState {
  isLoading: boolean;
  isDeliveryFeeLoading: boolean;
  isDisableProceedToCheckout: boolean;
  fetchingLocation: boolean;
  isSuccess: boolean;
  errorMessage: string;
  refresh: boolean;
  isLocationValid: boolean;
  loadLocationValidity: boolean;
  currentLocation: Address;
  selectedCurrentLocation: Address;
  savedAddresses: Address[];
  recentSearches: Address[];
  locationServiceEnabled: boolean;
  initialLocation: {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
  };
  mapRegion: {
    latitude: number;
    longitude: number;
    latitudeDelta: number;
    longitudeDelta: number;
  };
  deliveryData: any; // Update this type to match your delivery data structure
  isLoadingCartAll: boolean;
  cartAllData: any; // Update this type to match your cart data structure
  isDeliveryFeeCalculated: boolean;
}

const initialState: LocationState = {
  isLoading: false,
  isDeliveryFeeLoading: false,
  isDisableProceedToCheckout: false,
  fetchingLocation: false,

  isSuccess: false,
  errorMessage: "",
  refresh: false,
  isLocationValid: true,
  loadLocationValidity: false,
  currentLocation: {
    coords: { latitude: '', longitude: '' },
    formattedAddress: 'wait, loading address ',
  },
  selectedCurrentLocation: {
    coords: { latitude: '', longitude: '' },
    formattedAddress: '',
    addressId: '',
    addressType: '',
  },
  savedAddresses: [],
  recentSearches: [],
  locationServiceEnabled: false,
  initialLocation: {
    latitude: 0,
    longitude: 0,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  },
  mapRegion: {
    latitude: 8.5291244,
    longitude: 76.955889,
    latitudeDelta: 0.01,
    longitudeDelta: 0.01,
  },
  deliveryData: {},
  isLoadingCartAll: false,
  cartAllData: {},
  isDeliveryFeeCalculated: false,
};

export const locationSlice = createSlice({
  name: 'location',
  initialState,
  reducers: {
    updateCurrentLocation: (state, action: PayloadAction<Address>) => {
      const { coords, formattedAddress, addressType = 'Deliver to' } = action.payload;
      console.log("location redux==", action.payload);

      state.currentLocation.coords = coords;
      state.currentLocation.formattedAddress = formattedAddress;
      state.mapRegion.latitude = parseFloat(coords.latitude);
      state.mapRegion.longitude = parseFloat(coords.longitude);
      state.selectedCurrentLocation.coords = { ...coords };
      state.selectedCurrentLocation.formattedAddress = formattedAddress;
      state.selectedCurrentLocation.addressType = addressType;
    },
    changeSelectedCurrentAddress: (state, action: PayloadAction<Address>) => {
    
      const { coords, formattedAddress, addressId, addressType } = action.payload;
      state.selectedCurrentLocation.coords = coords;
      state.selectedCurrentLocation.formattedAddress = formattedAddress;
      state.selectedCurrentLocation.addressId = addressId;
      state.selectedCurrentLocation.addressType = addressType || '';
    },
    updateLocationServiceEnabled: (state, action: PayloadAction<boolean>) => {
      state.locationServiceEnabled = action.payload;
    },
    changeMapRegion: (state, action: PayloadAction<{ latitude: number; longitude: number }>) => {
      const { latitude, longitude } = action.payload;
      state.mapRegion.latitude = latitude;
      state.mapRegion.longitude = longitude;
    },
    updateInitialLocation: (state, action: PayloadAction<{ latitude: number; longitude: number }>) => {
      const { latitude, longitude } = action.payload;
      state.initialLocation.latitude = latitude;
      state.initialLocation.longitude = longitude;
    },
    resetMapRegion: (state) => {
      const { latitude, longitude } = state.currentLocation.coords;
      state.mapRegion.latitude = parseFloat(latitude);
      state.mapRegion.longitude = parseFloat(longitude);
    },
    updateFetchingLocation: (state, action: PayloadAction<boolean>) => {
      state.fetchingLocation = action.payload;
    },
    enableProceedToCheckout: (state, action: PayloadAction<boolean>) => {
      const value = action.payload;
      state.isDeliveryFeeLoading = value;
      state.isDisableProceedToCheckout = value;
    },
    resetCartAllData: (state) => {
      state.cartAllData = {};
    },
    setDeliveryRate: (state, action: PayloadAction<any>) => {

      console.log("delivary redux==", action.payload);

      state.isLoading = false;
      state.isDeliveryFeeLoading = false;
      state.isDisableProceedToCheckout = false;
      state.deliveryData = action.payload;
    },
    resetIsDeliveryFeeCalculated: (state, action: PayloadAction<boolean>) => {
      state.isDeliveryFeeCalculated = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getAddresses.pending, (state) => {


        state.isLoading = true;
      })
      .addCase(getAddresses.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refresh = false;
        // state.savedAddresses = [...payload];
      })
      .addCase(getAddresses.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        // state.errorMessage = payload;
      })
      .addCase(addAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(addAddress.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        state.refresh = true;
        //state.savedAddresses = [...state.savedAddresses, ...payload];
      })
      .addCase(addAddress.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        // state.errorMessage = payload;
      })
      .addCase(deleteAddress.pending, (state) => {
        state.isLoading = true;
      })
      .addCase(deleteAddress.fulfilled, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = true;
        const myAddresses = [...state.savedAddresses];
        const updatedSavedAddresses = myAddresses.filter((address) => address.addressId !== payload.id);
        state.savedAddresses = [...updatedSavedAddresses];
      })
      .addCase(deleteAddress.rejected, (state, { payload }) => {
        state.isLoading = false;
        state.isSuccess = false;
        // state?.errorMessage = payload;
      })
      .addCase(checkDeliveryLocation.fulfilled, (state, { payload }) => {
        state.loadLocationValidity = true;
        state.isLocationValid = payload;
      })
      .addCase(checkDeliveryLocation.pending, (state) => {
        state.loadLocationValidity = false;
      })
      .addCase(checkDeliveryLocation.rejected, (state) => {
        state.loadLocationValidity = true;
        state.isLocationValid = false;
      })
      .addCase(calculateDeliveryRate.fulfilled, (state, action) => {

        console.log("calc delivery fee", action.payload);

        state.isLoading = false;
        state.isDeliveryFeeLoading = false;
        state.isDisableProceedToCheckout = false;
        state.deliveryData = action.payload;
        state.isDeliveryFeeCalculated = true;
      })
      .addCase(calculateDeliveryRate.pending, (state) => {
        state.isLoading = true;
        state.isDeliveryFeeLoading = true;
        state.isDisableProceedToCheckout = true;
        state.deliveryData = {};
        state.isDeliveryFeeCalculated = false;
      })
      .addCase(calculateDeliveryRate.rejected, (state) => {
        state.isLoading = false;
        state.isDeliveryFeeLoading = false;
        state.isDisableProceedToCheckout = false;
        state.deliveryData = {};
        state.isDeliveryFeeCalculated = true;
      })
      .addCase(calculateDeliveryRateWithMerchantId.fulfilled, (state, action) => {
        state.isLoading = false;
        state.deliveryData = action.payload;
      })
      .addCase(calculateDeliveryRateWithMerchantId.pending, (state) => {
        state.isLoading = true;
        state.deliveryData = {};
      })
      .addCase(calculateDeliveryRateWithMerchantId.rejected, (state) => {
        state.isLoading = false;
        state.deliveryData = {};
      })
      .addCase(getAddressesForCart.fulfilled, (state, action) => {
        state.isLoading = false;
        // state.savedAddresses = action.payload;
      })
      .addCase(getAddressesForCart.pending, (state) => {
        state.isLoading = true;
        // state.savedAddresses = {};
      })
      .addCase(getAddressesForCart.rejected, (state) => {
        state.isLoading = false;
        // state.savedAddresses = {};
      })
      .addCase(getCartAllData.fulfilled, (state, action) => {
        state.isLoadingCartAll = false;
        state.cartAllData = action.payload;
      })
      .addCase(getCartAllData.pending, (state) => {
        state.isLoadingCartAll = true;
        state.cartAllData = {};
      })
      .addCase(getCartAllData.rejected, (state) => {
        state.isLoadingCartAll = false;
        state.cartAllData = {};
      });
  },
});

export const {
  updateCurrentLocation,
  changeSelectedCurrentAddress,
  updateLocationServiceEnabled,
  changeMapRegion,
  resetMapRegion,
  updateInitialLocation,
  updateFetchingLocation,
  enableProceedToCheckout,
  resetCartAllData,
  setDeliveryRate,
  resetIsDeliveryFeeCalculated,
} = locationSlice.actions;

export default locationSlice.reducer;
