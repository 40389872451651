// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.headerLine {
  display: flex;
  flex-wrap: wrap;
}

.headingText {
  font-weight: bold;

  font-size: large;
  white-space: nowrap;
}

.blue_text {
  color: #01afee;
}

.hook_img {
  margin-left: 4%;
  padding-bottom: 0%;
}

.color-component {
  cursor: pointer;
  border-radius: 50%;
  overflow: hidden;
  width: 100px;
  height: 100px;
}

.selected {
  border: 2px solid #007bff;
}

.color-component img {
  width: 100%;
  height: 100%;
}

.fish-category {
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #dde0de;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
  padding: 0.3em 0.6em;
}

.fish-category:hover {
  background-color: #28af4b;
  color: #fff;
}

.fish-category.selected {
  background-color: #28af4b;
  color: #fff;
}

.iconImg {
  width: 30px;
  aspect-ratio: 1/1;
}

.custom-select-filter{
  width: 100%;
  border-radius: 10px;
  padding: 3px;
  outline: 1px solid #28af4b;
}`, "",{"version":3,"sources":["webpack://./src/components/filterComponent/filterComponent.css"],"names":[],"mappings":"AAAA;EACE,aAAa;EACb,eAAe;AACjB;;AAEA;EACE,iBAAiB;;EAEjB,gBAAgB;EAChB,mBAAmB;AACrB;;AAEA;EACE,cAAc;AAChB;;AAEA;EACE,eAAe;EACf,kBAAkB;AACpB;;AAEA;EACE,eAAe;EACf,kBAAkB;EAClB,gBAAgB;EAChB,YAAY;EACZ,aAAa;AACf;;AAEA;EACE,yBAAyB;AAC3B;;AAEA;EACE,WAAW;EACX,YAAY;AACd;;AAEA;EACE,eAAe;EACf,sBAAsB;EACtB,yBAAyB;EACzB,mBAAmB;EACnB,6CAA6C;EAC7C,oBAAoB;AACtB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,WAAW;EACX,iBAAiB;AACnB;;AAEA;EACE,WAAW;EACX,mBAAmB;EACnB,YAAY;EACZ,0BAA0B;AAC5B","sourcesContent":[".headerLine {\n  display: flex;\n  flex-wrap: wrap;\n}\n\n.headingText {\n  font-weight: bold;\n\n  font-size: large;\n  white-space: nowrap;\n}\n\n.blue_text {\n  color: #01afee;\n}\n\n.hook_img {\n  margin-left: 4%;\n  padding-bottom: 0%;\n}\n\n.color-component {\n  cursor: pointer;\n  border-radius: 50%;\n  overflow: hidden;\n  width: 100px;\n  height: 100px;\n}\n\n.selected {\n  border: 2px solid #007bff;\n}\n\n.color-component img {\n  width: 100%;\n  height: 100%;\n}\n\n.fish-category {\n  cursor: pointer;\n  background-color: #fff;\n  border: 1px solid #dde0de;\n  border-radius: 10px;\n  transition: background-color 0.3s, color 0.3s;\n  padding: 0.3em 0.6em;\n}\n\n.fish-category:hover {\n  background-color: #28af4b;\n  color: #fff;\n}\n\n.fish-category.selected {\n  background-color: #28af4b;\n  color: #fff;\n}\n\n.iconImg {\n  width: 30px;\n  aspect-ratio: 1/1;\n}\n\n.custom-select-filter{\n  width: 100%;\n  border-radius: 10px;\n  padding: 3px;\n  outline: 1px solid #28af4b;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
