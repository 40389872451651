// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
.flying-img-container {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
    z-index: 100;
  }
  
 
   
  @keyframes spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  .b-flying-img {
    animation: spin 1.2s linear infinite;
    transition: all 1.5s ease, opacity 0.5s ease-out;
  }
  
 
 
  
  @media (max-width: 768px) {
    .b-flying-img {
      transition: all 0.9s ease, opacity 0.5s ease-out;
    }
  }
  
  @media (min-width: 769px) and (max-width: 1200px) {
    .b-flying-img {
      transition: all 1.5s ease, opacity 0.5s ease-out;
    }
  }
  
  @media (min-width: 1201px) {
    .b-flying-img {
      transition: all 1.5s ease, opacity 0.5s ease-out;
    }
  }
  
  
  `, "",{"version":3,"sources":["webpack://./src/components/flyingImage/FlyingImage.css"],"names":[],"mappings":";AACA;IACI,kBAAkB;IAClB,MAAM;IACN,OAAO;IACP,WAAW;IACX,YAAY;IACZ,oBAAoB;IACpB,YAAY;EACd;;;;EAIA;IACE;MACE,uBAAuB;IACzB;IACA;MACE,yBAAyB;IAC3B;EACF;;EAEA;IACE,oCAAoC;IACpC,gDAAgD;EAClD;;;;;EAKA;IACE;MACE,gDAAgD;IAClD;EACF;;EAEA;IACE;MACE,gDAAgD;IAClD;EACF;;EAEA;IACE;MACE,gDAAgD;IAClD;EACF","sourcesContent":["\n.flying-img-container {\n    position: absolute;\n    top: 0;\n    left: 0;\n    width: 100%;\n    height: 100%;\n    pointer-events: none;\n    z-index: 100;\n  }\n  \n \n   \n  @keyframes spin {\n    from {\n      transform: rotate(0deg);\n    }\n    to {\n      transform: rotate(360deg);\n    }\n  }\n  \n  .b-flying-img {\n    animation: spin 1.2s linear infinite;\n    transition: all 1.5s ease, opacity 0.5s ease-out;\n  }\n  \n \n \n  \n  @media (max-width: 768px) {\n    .b-flying-img {\n      transition: all 0.9s ease, opacity 0.5s ease-out;\n    }\n  }\n  \n  @media (min-width: 769px) and (max-width: 1200px) {\n    .b-flying-img {\n      transition: all 1.5s ease, opacity 0.5s ease-out;\n    }\n  }\n  \n  @media (min-width: 1201px) {\n    .b-flying-img {\n      transition: all 1.5s ease, opacity 0.5s ease-out;\n    }\n  }\n  \n  \n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
