import {
  faEnvelope,
  faMapMarkerAlt,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Card, CardBody } from "reactstrap";

const ContactUs = () => {
  return (
    <div className="col-md-12 height-custom pt-5">
      <div className="col-md-12 text-center pt-4">
        <h4 className="heading-pages">
          Contact <span className="span-with-green">Us</span>
        </h4>
        <div className="sub-text-pages pt-3">Reach Out and Connect with Us</div>
      </div>
      <div className="row pt-5 pb-5">
        <div className="col-md-6 pt-4">
          <div className="col-md-10">
            <div className="sub-div-headings d-flex">
              <span className="span-with-green">Connect</span>&nbsp;
              <span>with Us</span>&nbsp;
              <span className="line-span">
                <div className="line-div"></div>
              </span>
            </div>
            <div className="inner-text-heading pt-4">
              {/* <img src="" alt="" className="me-2" /> */}
              <FontAwesomeIcon icon={faMapMarkerAlt} className="me-2" />
              Headquarters
            </div>
            <div className="inner-text-content pt-4">
              60/49E, Third Floor, JC chambers, Panampilly Nagar, Ernakulam -
              682036, Kerala
            </div>
            <div className="inner-text-heading pt-4">
              {/* <img src="" alt="" className="me-2" /> */}
              <FontAwesomeIcon icon={faEnvelope} className="me-2" />
              Email Us
            </div>
            <div className="inner-text-content pt-4">
              <a href="mailto:mail@livelocal.co.in" className="email-link">
                mail@livelocal.co.in
              </a>
            </div>
            <div className="inner-text-heading pt-4">
              {/* <img src="" alt="" className="me-2" /> */}
              <FontAwesomeIcon icon={faPhone} className="me-2" />
              Phone
            </div>
            <div className="inner-text-content pt-4">
              <a href="tel:+918301002200" className="phoneno-link">
                (+91) 8301002200
              </a>
            </div>
          </div>
        </div>
        <div className="col-md-6 pt-4">
          <Card className="contactus-card">
            <CardBody>
              <div className="col-md-12 ">
                <label className="form-label contactus-label">Full Name*</label>
                <input
                  type="text"
                  name="fullname"
                  id="fullname"
                  className="contactus-input"
                />
              </div>
              <div className="col-md-12 pt-4">
                <label className="form-label contactus-label">Email*</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  className="contactus-input"
                />
              </div>
              <div className="col-md-12 pt-4">
                <label className="form-label contactus-label">
                  Mobile Number
                </label>
                <input
                  type="text"
                  name="mobilenumber"
                  id="mobilenumber"
                  className="contactus-input"
                />
              </div>
              <div className="col-md-12 pt-4">
                <label htmlFor="" className="form-label contactus-label">
                  How can we help you?*
                </label>
                {/* <input type="textarea" name="" id="" /> */}
                <textarea
                  name="howcanhelp"
                  id="howcanhelp"
                  rows={3}
                  className="contactus-input"
                ></textarea>
              </div>
              <div className="col-md-12 text-end pt-4">
                <button className="btn green-button-style">Send Message</button>
              </div>
            </CardBody>
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ContactUs;
