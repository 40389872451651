import React from "react";
import {
  Bestpickersicon,
  Pre_demo_img,
  homepagefishimg,
} from "../../../assets/images";
import { Card, CardBody } from "reactstrap";
import "./bestpicks.css";

const BestPicks = () => {
  return (
    <>
      <div className="col-md-12 pt-4">
        <div className="sub-div-headings d-flex">
          <img src={Bestpickersicon} alt="" />
          &nbsp;
          <span>Best picks for you</span>&nbsp;
          <span className="line-span">
            <div className="line-div-max-length-ecommerce"></div>
          </span>
        </div>
      </div>
      <div className="col-12 pt-3">
        <div className="row">
          <div className="col-md-2">
            <Card className="div-relative-bestpick">
              <div className="div-absolute-bestpicks-one">
                <img
                  src={homepagefishimg}
                  alt=""
                  className="image-bg-bestpicks"
                />
              </div>
              <div className="div-absolute-bestpicks-two" />

              <CardBody className="">
                <div className="text-div-bestpicks">
                  <div>King Fish / Neymeen</div>
                  <div>Curry cut | 300 g</div>
                  <div>Price : ₹ 139</div>
                </div>
              </CardBody>
            </Card>
          </div>
        </div>
      </div>
    </>
  );
};

export default BestPicks;
