// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.FoodSpecialOffers-width-main {
  width: 100%;
  overflow-x: scroll;
  overflow-y: hidden;
}

.FoodSpecialOffers-width-in-pixel {
  width: 200px;
  min-width: 200px;
}

.FoodSpecialOffersdiv {
  /* border: 0.7px solid #eeeeee; */
  /* box-shadow: 5px 5px 5px #eeeeee; */
  /* box-shadow: 0 2px 4px 0 #eeeeee, 0 3px 10px 0 #eeeeee; */
  /* width */
  ::-webkit-scrollbar {
    width: 0.1px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    /* box-shadow: inset 0 0 5px grey; */
    /* border-radius: 10px; */
    border-radius: 0;
    box-shadow: none;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #ffffff;
    /* border-radius: 10px; */
    border-radius: 0;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #ffffff;
  }
}
`, "",{"version":3,"sources":["webpack://./src/components/Food/FoodSpecialOffers/FoodSpecialOffers.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;EAClB,kBAAkB;AACpB;;AAEA;EACE,YAAY;EACZ,gBAAgB;AAClB;;AAEA;EACE,iCAAiC;EACjC,qCAAqC;EACrC,2DAA2D;EAC3D,UAAU;EACV;IACE,YAAY;EACd;;EAEA,UAAU;EACV;IACE,oCAAoC;IACpC,yBAAyB;IACzB,gBAAgB;IAChB,gBAAgB;EAClB;;EAEA,WAAW;EACX;IACE,mBAAmB;IACnB,yBAAyB;IACzB,gBAAgB;EAClB;;EAEA,oBAAoB;EACpB;IACE,mBAAmB;EACrB;AACF","sourcesContent":[".FoodSpecialOffers-width-main {\n  width: 100%;\n  overflow-x: scroll;\n  overflow-y: hidden;\n}\n\n.FoodSpecialOffers-width-in-pixel {\n  width: 200px;\n  min-width: 200px;\n}\n\n.FoodSpecialOffersdiv {\n  /* border: 0.7px solid #eeeeee; */\n  /* box-shadow: 5px 5px 5px #eeeeee; */\n  /* box-shadow: 0 2px 4px 0 #eeeeee, 0 3px 10px 0 #eeeeee; */\n  /* width */\n  ::-webkit-scrollbar {\n    width: 0.1px;\n  }\n\n  /* Track */\n  ::-webkit-scrollbar-track {\n    /* box-shadow: inset 0 0 5px grey; */\n    /* border-radius: 10px; */\n    border-radius: 0;\n    box-shadow: none;\n  }\n\n  /* Handle */\n  ::-webkit-scrollbar-thumb {\n    background: #ffffff;\n    /* border-radius: 10px; */\n    border-radius: 0;\n  }\n\n  /* Handle on hover */\n  ::-webkit-scrollbar-thumb:hover {\n    background: #ffffff;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
