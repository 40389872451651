import React from "react";
import Banner from "../../components/banner/Banner";
import ProductCategory from "../../components/Food/ProductCategory/ProductCategory";

import { useQuery } from "react-query";
import { useSelector } from "react-redux";
import axiosInstance from "../../services/axios.instance";
import { API_URL } from "../../services/apiConfig";
import { useDispatch } from "react-redux";
import { setSelectedGroceryMerchant } from "../../redux/merchandSlice";

const Groceries = () => {
  const dispatch = useDispatch();
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );
  const { data: response } = useQuery({
    queryFn: () =>
      axiosInstance.get(
        `${API_URL.GROCERY_STORE_LIST}/${location?.latitude}/${location?.longitude}`
      ),

    queryKey: ["getGroceryMerchants", location?.latitude],
    enabled: !!location?.latitude,
  });

  const groceryMerchantId = response?.data?.merchant_info?.merchant_id;

  dispatch(setSelectedGroceryMerchant(groceryMerchantId));

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5 relative-div-bg">
        <Banner />
        <ProductCategory />
      </div>
    </div>
  );
};

export default Groceries;
