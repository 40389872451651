import React, { useState } from "react";
import FishCard from "../../components/fishCard/FishCard";
import Filter from "../../components/filterComponent/filterComponent";
import Banner from "../../components/banner/Banner";
import { ProductCategory } from "../../components";

interface Props {
  onProductChange: (selectedProduct: string) => void;
  onTypeofCutChange: (selectedTypeofCut: string) => void;
  onQuantityChange: (selectedQuantity: string) => void;
}

const FreshFish: React.FC<Props> = ({ onProductChange }) => {
  const [selectedProduct, setSelectedProduct] = useState("");
  const [selectedTypeofCut, setselectedTypeofCut] = useState("");
  const [selectedQuantity, setselectedQuantity] = useState("");

  const handleProductChange = (selectedProduct: string) => {
    setSelectedProduct(selectedProduct);
  };
  const handleTypeofCutChange = (selectedTypeofCut: string) => {
    setselectedTypeofCut(selectedTypeofCut);
  };
  const handleQuantityChange = (selectedQuantity: string) => {
    setselectedQuantity(selectedQuantity);
  };
  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4">
      <div className="row width-full-page pb-5 relative-div-bg">
        <ProductCategory />
        <Filter
          onProductChange={handleProductChange}
          onTypeofCutChange={handleTypeofCutChange}
          onQuantityChange={handleQuantityChange}
        />
        <FishCard
          selectedProduct={selectedProduct}
          selectedTypeofCut={selectedTypeofCut}
          selectedQuantity={selectedQuantity}
        />
        <Banner />
      </div>
    </div>
  );
};

export default FreshFish;
