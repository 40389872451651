import React from "react";
import {
  CategoryImageicon,
  Pre_demo_img,
  rectangles,
} from "../../../assets/images";
import { Card, CardBody } from "reactstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import {
  setSelectedMerchant,
  setSelectedFoodMerchant,
} from "../../../redux/merchandSlice";
import "./RestaurantsList.css";

const RestaurantsList = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  console.log(location, "locationss");

  const handleImageClick = (merchant: any) => {
    console.log("Navigating to /Restaurant with merchant:", merchant);
    dispatch(setSelectedFoodMerchant(merchant));
    navigate("/Restaurant", { state: { id: merchant } });
  };

  const { data: response, refetch } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        `${API_URL.FOOD_MERCHANTS}`,
        {
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          businessArea: "foodPlanet",
        },
        {
          headers: { version: "v3" },
        }
      ),
    queryKey: ["getFoodMerchants", location?.latitude],
    enabled: !!location?.latitude,
  });

  const foodMerchants = response?.data || [];
  return (
    <div className="col-md-12 pt-4 ">
      <div className="div-caroysel-relative">
        <div className="sub-div-headings d-flex ">
          <img src={rectangles} alt="" />
          &nbsp;
          <span>Restaurants with connected</span>&nbsp;
          <span className="line-span">
            <div className="line-div-max-length-ecommerce"></div>
          </span>
        </div>
        <div className="col-md-12">
          <div className="row">
            {foodMerchants.map((merchant: any) => (
              <div
                key={merchant.merchant_info.merchant_id}
                className="col-md-3 pt-3 content-fishcard"
              >
                <Card
                  className={`card-hghlght-reverse ${
                    merchant?.shop_info?.shop_status !== "available"
                      ? "disabled-card"
                      : ""
                  }`}
                  onClick={() => handleImageClick(merchant.merchant_info)}
                >
                  <CardBody>
                    <img
                      src={
                        merchant?.merchant_info?.siteLogo ||
                        merchant?.shop_info?.image ||
                        Pre_demo_img
                      }
                      alt=""
                      className="img-ratio-card"
                    />
                    <div>
                      <div className="inner-text-heading-two">
                        {merchant?.shop_info?.shop_name}
                      </div>
                      <div className="inner-text-heading-four">
                        <FontAwesomeIcon
                          icon={faStar}
                          className="yellow-star"
                        />
                        &nbsp; {merchant?.merchant_info?.rating} (
                        {merchant?.merchant_info?.number_of_rating} reviews)
                      </div>
                      <div className="inner-text-content-four">
                        {merchant?.shop_info?.shop_address?.address} |{" "}
                        {merchant?.map_distance < 1
                          ? (merchant.map_distance * 1000).toFixed(0) +
                            " meters"
                          : merchant.map_distance.toFixed(2) + " KM"}{" "}
                        |{" "}
                        {
                          merchant.shop_info?.shop_address
                            ?.estimated_delivery_time
                        }
                      </div>
                      {merchant?.shop_info?.shop_status !== "available" && (
                        <div className="text-danger  mt-1">
                          Shop currently unavailable
                        </div>
                      )}
                    </div>
                  </CardBody>
                </Card>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantsList;
