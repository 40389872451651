import React, { useState } from "react";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
  AccordionItem,
  Card,
  CardBody,
} from "reactstrap";
import { Pre_demo_img } from "../../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import Cart from "../../fishCard/CartButtons/Cart";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL, guestId, sessionId } from "../../../services/apiConfig";
import { useDispatch } from "react-redux";
import FoodSpecialOffers from "../FoodSpecialOffers/FoodSpecialOffers";
import FoodCart from "../FoodCart/FoodCart";
import { setEncryptedResponse } from "../../../redux/checkoutSlice/checkoutSlice";
import { useParams } from "react-router-dom";
import MerchantOfferProducts from "../../HomePage/MerchantOfferProducts/MerchantProducts";

const RestaurantProduct = () => {
  const [open, setOpen] = useState("0");
  const [selectedMerchantId, setSelectedMerchantId] = useState(null);
  const [cartKey, setCartKey] = useState(Number);
  const [Cartresponse, setResponse] = useState<any | null>(null);
  const dispatch = useDispatch();
  interface GroupedProducts {
    [category: string]: any[]; // Assuming each category contains an array of products
  }
  const selectedMerchant = useSelector(
    (state: any) => state.merchant.selectedfoodmerchant.merchant_id
  );
  const { "*": restaurantName } = useParams();

  const toggle = (id: any) => {
    if (open === id) {
      setOpen("");
    } else {
      setOpen(id);
    }
  };

  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );
  const { data: response, refetch } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        `${API_URL.FOOD_MERCHANTS}`,
        {
          latitude: location?.latitude,
          longitude: location?.longitude,
          res: 6,
          businessArea: "foodPlanet",
        },
        {
          headers: { version: "v3" },
        }
      ),
    queryKey: ["getFoodMerchants", location?.latitude],
    enabled: !!location?.latitude,
  });

  const foodMerchants = response?.data || [];

  const { data: productlist } = useQuery({
    queryFn: () =>
      axiosInstance.post(
        `${API_URL.MERCHANT_PRODUCT_LIST}`,
        {
          index: "0",
          count: "500",
          country: "ALL",
          merchant_id: selectedMerchant,
          business_area: "foodPlanet",
        },
        {
          headers: { version: "v8" },
        }
      ),
    queryKey: ["getMerchantproducts", location?.latitude],
    enabled: !!location?.latitude,
  });
  let merchantProducts = productlist?.data || [];
  // console.log("merchant products..", merchantProducts);

  const groupedProducts: GroupedProducts = {};
  merchantProducts?.content?.forEach((productObj: any) => {
    const category = productObj?.parent_category_name;
    // console.log("category name.....", category);
    if (!groupedProducts[category]) {
      groupedProducts[category] = [];
    }
    groupedProducts[category].push(productObj);
  });

  const { data, isLoading, isError } = useQuery({
    queryKey: "cartItemEncryption",
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(
          guestId && sessionId
            ? API_URL.GUEST_USER_GET_CARTS
            : API_URL.CART_ITEM_ENCRYPTION,
          {
            headers: {
              "Content-Type": "text/plain",
              version: "v2",
            },
          }
        );
        console.log("encryptCartResponse", response);
        setResponse(response.data);
        dispatch(setEncryptedResponse(response.data));

        if (response.data) {
          const cartKey: any = Object.keys(response.data).find((key) =>
            key.includes("mer")
          );
          setCartKey(cartKey);
        }

        return response.data;
      } catch (error) {
        console.error("Error fetching cart item encryption:", error);
        throw new Error("Error fetching cart item encryption");
      }
    },
  });

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page-product pb-5 relative-div-bg">
        <div className="col-12">
          <Card className="border-none">
            {foodMerchants
              .filter(
                restaurantName
                  ? (obj: any) => obj.shop_info.shop_name === restaurantName
                  : (obj: any) =>
                      obj.merchant_info.merchant_id === selectedMerchant
              )

              ?.map((merchant: any) => (
                <CardBody>
                  <div
                    className="d-flex justify-content-center"
                    key={merchant.merchant_info.merchant_id}
                  >
                    <div className="col-md-8">
                      <div className="row">
                        <div className="col-4">
                          <img
                            src={
                              merchant?.merchant_info?.siteLogo ||
                              merchant?.shop_info?.image ||
                              Pre_demo_img
                            }
                            alt=""
                            className="img-ratio-card"
                          />
                        </div>
                        <div className="col align-content-center">
                          <div className="sub-div-headings">
                            {merchant?.shop_info?.shop_name}
                          </div>
                          <div className="inner-text-heading-four">
                            <FontAwesomeIcon
                              icon={faStar}
                              className="yellow-star"
                            />
                            &nbsp; Ratting (no of reviews)
                          </div>
                          <div className="col-md-6 row inner-text-content-three">
                            {/* <div className="col">Delivery expect time</div> */}
                            <div className="col span-with-green text-end">
                              Deliver from
                            </div>
                          </div>
                          <div className="inner-text-content-four">
                            {merchant?.shop_info?.shop_address?.address} |
                            {merchant?.map_distance < 1
                              ? (merchant.map_distance * 1000).toFixed(0) +
                                " meters"
                              : merchant.map_distance.toFixed(2) + " KM"}{" "}
                            |{" "}
                            {
                              merchant.shop_info?.shop_address
                                ?.estimated_delivery_time
                            }{" "}
                            mins
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              ))}
          </Card>
        </div>
        <FoodSpecialOffers products={groupedProducts} />
        <div className="col-12 text-center pt-3">
          <div className="inner-text-heading-three">Menu</div>

          <div className="profile-minimum-height food-list">
            {/* --------------------mapping------------------------- */}
            {Object.keys(groupedProducts).map(
              (category: string, index: number) => (
                <Accordion
                  open={open}
                  toggle={toggle}
                  className="accordion-custom product-custom-food bottom-border"
                >
                  <AccordionItem>
                    <AccordionHeader targetId={index.toString()}>
                      <span className="inner-text-heading">{category}</span>
                    </AccordionHeader>

                    <AccordionBody accordionId={index.toString()}>
                      {/* --------------product----------------- */}
                      {groupedProducts[category].map((product: any) => (
                        <>
                          <div className="inner-text-heading text-start">
                            {product?.category_name}
                          </div>
                          <div className="">
                            <Card className="card-hghlght-reverse ">
                              <CardBody>
                                <div className="row reverse-display">
                                  <div className="col">
                                    <div className="row">
                                      <div className="col text-start">
                                        <div className="inner-text-heading-two">
                                          {product?.product_name}
                                        </div>
                                        <div className="inner-text-heading-four">
                                          {product?.price}
                                        </div>
                                        <div className="inner-text-heading-three">
                                          <FontAwesomeIcon
                                            icon={faStar}
                                            className="yellow-star"
                                          />
                                          &nbsp; {product?.rating} (no of
                                          reviews)
                                        </div>
                                        <div className="inner-text-content-four">
                                          {/* Delivery Details: */}
                                          {
                                            product.inventories?.shoptuvkdndrcq
                                              ?.available_stock
                                          }
                                        </div>
                                        <div className="col inner-text-heading-three">
                                          {
                                            product?.product_details
                                              ?.description
                                          }
                                        </div>
                                      </div>
                                      <div className="col-md-4 align-content-end">
                                        <FoodCart product={product} />
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <img
                                      src={Pre_demo_img}
                                      alt=""
                                      className="img-ratio-card"
                                    />
                                  </div>
                                </div>
                              </CardBody>
                            </Card>
                          </div>
                        </>
                      ))}
                      {/* --------------product----------------- */}
                    </AccordionBody>
                  </AccordionItem>

                  {/* -------- mapping  ------ */}
                </Accordion>
              )
            )}

            {/* --------------------------------------------- */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default RestaurantProduct;
