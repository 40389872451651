// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.img-previousOrder {
  width: 100%;
  aspect-ratio: 4/2;
}
`, "",{"version":3,"sources":["webpack://./src/components/HomePage/PreviousOrders/previousOrder.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,iBAAiB;AACnB","sourcesContent":[".img-previousOrder {\n  width: 100%;\n  aspect-ratio: 4/2;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
