import React, { useEffect, useRef, useState } from "react";
import "./DelveryDetails.css";
import "bootstrap/dist/css/bootstrap.min.css";
import {
  dropdown_icon,
  home_icon,
  work_icon,
  location_pin,
  double_arrow,
  hotel_icon,
  Pre_demo_img,
  availableoffers,
  foodIcon,
} from "../../assets/images";
import { Row, Col, ModalTitle } from "react-bootstrap";
import {
  Button,
  Card,
  CardBody,
  CloseButton,
  DropdownToggle,
  Input,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  UncontrolledDropdown,
} from "reactstrap";
import { RootState } from "../../redux/store";

import { Link, useLocation, useNavigate } from "react-router-dom";

import { API_URL, guestId, mapurl, sessionId } from "../../services/apiConfig";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import offer1 from "../../assets/images/offer1.png";

import "react-perfect-scrollbar/dist/css/styles.css";
import { useDispatch } from "react-redux";
import {
  changeSelectedCurrentAddress,
  updateCurrentLocation,
} from "../../redux/location/locationSlice";
import { apiAddressFormat } from "../../utils/location.utils";
import CryptoJS from "crypto-js";

import { useSelector } from "react-redux";

import DeliverySlot from "../deliverySlots/DeliverySlot";

import "../fishCard/CartButtons/CartButtons.css";
import { vendorId } from "../../services/apiConfig";

import AddressModal from "../addressModal/addressModal";
import SuccesModal from "../SuccesModal/SuccesModal";
import ErrorModal from "../ErrorModal/ErrorModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTicket } from "@fortawesome/free-solid-svg-icons";
import {
  setEncryptedResponse,
  setSelectedPromocode,
} from "../../redux/checkoutSlice/checkoutSlice";
import {
  Merchant,
  setCartMerchantId,
  setSelectedFoodMerchant,
  setSelectedMerchant,
} from "../../redux/merchandSlice";

interface DeliveryPayload {
  merchant_longitude: string;
  merchant_latitude: string;
  customer_longitude: string;
  customer_latitude: string;
  amount: number;
}

interface OrderCreation {
  merchant_id: string;
  address_id: string;
  payment_method: string;
  promocode: string;
  user_code: string;
}

interface OrderGuestCreation {
  validate_otp_request: {
    phoneNo: string;
    userType: string;
  };
  session_id: string;
  guest_id: string;
  address_id: string;
  channel: string;
  payment_method: string;
  merchant_id: string;
  promocode: string;
  user_code: string;
}

type DeliveryFeeResponse = {
  delivery_fee: number;
  platform_share: number;
  delivery_boy_share: number;
  climate_margin: number;
  is_deliveryfee_free: boolean;
  deliveryfee_free_amount: number;
  min_amount: number;
  consumer_merchant_distance: number;
  distance_validation_message: string | null;
  not_deliverable: boolean;
  delivery_type: string;
};

function DeliveryDetails(args: any) {
  // const locationUrl = "https://osrm.livelocal.co.in/";
  const dispatch = useDispatch();
  const navlocation = useLocation();
  const searchParams = new URLSearchParams(navlocation.search);
  const planet: any = searchParams.has("planet")
    ? searchParams.get("planet") === "food"
      ? "foodPlanet"
      : searchParams.get("planet") === "Freshfish"
      ? "fishPlanet"
      : searchParams.get("planet")
    : null;

  const navigate = useNavigate();
  const [modal, setModal] = useState(false);
  const [moreBtn, setMoreBtn] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [deliveryInstructions, setDeliveryInstructions] = useState("");
  const [errorMessages, setErrorMessages] = useState<
    Array<{ index: number; message: string }>
  >([]);

  const [isErrorCartOpenMessage, setIsErrorCartOpenMessage] = useState("");
  const [totalCartAmount, setTotalCartAmount] = useState(0);
  const [deliveryFees, setDeliveryFees] = useState<null | DeliveryFeeResponse>(
    null
  );

  const setErrorMesssagWithIndex = (index: number, message: string) => {
    setErrorMessages((prevErrorMessages) => {
      const updatedErrorMessages = [...prevErrorMessages];
      updatedErrorMessages[index] = { index, message };
      return updatedErrorMessages;
    });
    setIsErrorStockOpen(true);
  };

  const encryptedResponses = useSelector(
    (state: any) => state.checkout.encryptedDataResponse
  );
  const shopId: any = Object.keys(encryptedResponses || {}).find((key) =>
    key.includes("mer")
  );
  const [merchantId, setMerchantId] = useState("");
  const [getPlanet, setGetPlanet] = useState<string | null>(null);

  const { promocode: promocodecoupon, index: promoIndex } = useSelector(
    (state: any) => state.checkout
  );

  const [couponCode, setCouponCode] = useState("");

  const selectedDeliverySlot = useSelector(
    (state: any) => state.products?.deliverySlot
  );

  let globalCount = {};
  let count = globalCount;

  const offer = () => setModal(!modal);
  const handleMore = () => {
    setMoreBtn(!moreBtn);
  };
  const handleInstructionsChange = (e: any) => {
    setDeliveryInstructions(e.target.value);
  };

  const { data: getCart, refetch } = useQuery({
    queryKey: "cartItemEncryption",
    queryFn: async () => {
      try {
        const resp = await axiosInstance.get(
          guestId && sessionId
            ? API_URL.GUEST_USER_GET_CARTS
            : API_URL.GET_CART_ITEM_ENCRYPTION,
          {
            headers: !guestId
              ? {
                  "Content-Type": "text/plain",
                  version: "v2",
                }
              : undefined,
          }
        );
        dispatch(setEncryptedResponse(resp.data));

        return resp.data;
      } catch (error) {
        console.error("Error fetching cart item encryption:", error);
        throw new Error("Error fetching cart item encryption");
      }
    },
    onSuccess: () => {
      if (getCart) {
        const merchantid = Object.keys(getCart)[0];
        setMerchantId(merchantid);
        dispatch(setCartMerchantId(merchantId));
        const merObj = {
          id: Number(merchantid),
          name: "",
          address: "",
        };

        dispatch(setSelectedFoodMerchant(merObj));

        if (planet) {
          const totalPrice = Object.keys(getCart).reduce((acc, key) => {
            const products = getCart[key]?.products;
            if (products) {
              return (
                acc +
                products.reduce((subtotal: any, product: any) => {
                  return (
                    subtotal +
                    (product.original_amount || product.price) *
                      product.quantity
                  );
                }, 0)
              );
            }
            return acc;
          }, 0);
          setTotalCartAmount(totalPrice);

          Object.keys(getCart).forEach((shopId) => {
            const products = getCart[shopId]?.products;
            if (products) {
              products.forEach((product: any, index: number) => {
                if (
                  product.available_stock < product.quantity &&
                  !product.is_product_available
                ) {
                  handleIncrementDecrement(
                    "decrement",
                    product.product_id,
                    product.available_stock,
                    index
                  );
                }
              });
            }
          });
        }
      }
    },
  });

  useEffect(() => {
    if (getCart) {
      const merchantid = Object.keys(getCart)[0];
      setMerchantId(merchantid);

      const planetValue = getCart[merchantid]?.planet_id;
      setGetPlanet(planetValue);
    }
  }, [getCart]);

  const { data: promoCodes } = useQuery({
    queryKey: "promoCodes",
    queryFn: async () => {
      try {
        const resp: any = await axiosInstance.get(
          `${API_URL.PROMO_CODE_LIST}/${merchantId}`,
          {}
        );

        return resp.data;
      } catch (error) {
        console.error("Error fetching promo codes:", error);
        throw new Error("Error fetching promo codes");
      }
    },
    enabled: !!merchantId,
  });

  const verifyCodes = useMutation({
    mutationFn: async (payload: any) => {
      try {
        const response = await axiosInstance.put(
          `${API_URL.PROMO_CODE_VALIDATE}`,
          payload
        );

        return response.data;
      } catch (error) {
        console.error("Validation error:", error);
        throw error;
      }
    },
    onSuccess: async (payload) => {
      try {
        let promoCode = promocodecoupon;
        await applySelectedPromoCode(promoCode, promoIndex);
      } catch (error) {
        console.error("Application error:", error);
      }
    },
  });

  const calculateTotalPrice = () => {
    let total = 0;
    if (getCart) {
      for (const shopId in getCart) {
        const products = getCart[shopId].products;
        for (const product of products) {
          total += product.quantity * product.price;
        }
      }
    }
    return total;
  };
  const calculatePackingCharge = () => {
    let totalPackingCharge = 0;
    if (getCart) {
      for (const shopId in getCart) {
        const packagingCharge = getCart[shopId].packaging_charge;
        totalPackingCharge += packagingCharge;
      }
    }
    return totalPackingCharge;
  };

  const handleAddItem = () => {
    if (getPlanet === "fishPlanet") {
      navigate("/FreshFish");
    } else {
      navigate("/Restaurant");
    }
  };
  const handleAddAddress = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const totalSubPrice = calculateTotalPrice();
  const packingCharge = calculatePackingCharge();

  const [selectedTipOption, setSelectedTipOption] = useState(0);
  const [customTipAmount, setCustomTipAmount] = useState(0);
  const [showTipInput, setShowTipInput] = useState(false);
  const [orderResponseMessage, setOrderResponseMessage] = useState("");

  const handleTipSelection = (tipAmount: any) => {
    if (tipAmount === "Other") {
      setShowTipInput(true);
      setSelectedTipOption(0);
    } else {
      setSelectedTipOption(Number(tipAmount));
      setCustomTipAmount(0);
      setShowTipInput(false);
    }
  };

  const handleCustomTipAmount = (amount: string) => {
    const parsedAmount = Number(amount);
    setCustomTipAmount(parsedAmount);
    setSelectedTipOption(0);
  };

  const handleTextInput = (value: string) => {
    setCustomTipAmount(Number(value));
  };

  const handleIncrementDecrement = async (
    type: "increment" | "decrement",
    productID: number,
    index: number,
    optionalArg?: any
  ) => {
    const updatedCartResponse = JSON.parse(JSON.stringify(encryptedResponses));

    const productInCart: any = updatedCartResponse[shopId]?.products.find(
      (product: any) => product.product_id === productID
    );

    if (productInCart) {
      if (optionalArg) {
        setIsErrorCartOpenMessage(
          "Your current quantity is greater than available stock"
        );
        setIsErrorCartOpen(true);
        productInCart.quantity = parseInt(optionalArg, 10);
      } else {
        const errorMEssages = [...errorMessages];
        productInCart.quantity =
          type === "increment"
            ? productInCart.quantity + 1
            : Math.max(productInCart.quantity - 1, 0);

        errorMEssages.splice(index, 1);
        setErrorMessages(errorMEssages);
      }
    }
    if (guestId && sessionId) {
      const secretKey: any = guestId;
      updatedCartResponse[shopId].products = productInCart;
      const hashedData = JSON.stringify(updatedCartResponse);
      const encryptDataRequest = CryptoJS.AES.encrypt(
        hashedData,
        secretKey
      ).toString();
      const GuestPaylod = {
        session_id: sessionId,
        guest_id: guestId,
        cart: encryptDataRequest,
      };
      try {
        await Updatemutation.mutateAsync(GuestPaylod);
      } catch (error) {
        console.error("Error calling mutation:", error);
      }
    } else {
      const secretKey: any = vendorId;
      updatedCartResponse[shopId].products = productInCart;
      const hashedData = JSON.stringify(updatedCartResponse);
      const encryptDataRequest = CryptoJS.AES.encrypt(
        hashedData,
        secretKey
      ).toString();
      try {
        await Updatemutation.mutateAsync(encryptDataRequest);
      } catch (error) {
        console.error("Error calling mutation:", error);
      }
    }
  };

  const Updatemutation = useMutation(
    async (encryptDataRequest: any) => {
      try {
        let resp;
        if (guestId && sessionId) {
          resp = await axiosInstance.put(
            API_URL.GUEST_USER_UPDATE_CART,
            encryptDataRequest
          );
        } else {
          resp = await axiosInstance.put(
            API_URL.UPDATE_CART,
            encryptDataRequest,
            {
              headers: {
                "Content-Type": "text/plain",
              },
            }
          );
        }

        dispatch(setEncryptedResponse(resp.data));
        return resp.data;
      } catch (error: any) {
        console.error("Error updating cart:", error);
        throw error;
      }
    },
    {
      onSuccess: () => {
        refetch();
      },
    }
  );
  const location = useSelector(
    (state: { products: { location: any } }) => state.products.location
  );

  const guestNumber = useSelector(
    (state: RootState) => state.auth.localPhoneNumber
  );

  const toggleAddressModal = () => {
    setIsAddressModalOpen(!isAddressModalOpen);
    setIsOpen(false);
  };

  const { name, phoneNumber } = useSelector((state: RootState) => state.auth);
  const [isOpen, setIsOpen] = useState(false);
  const [isAddressModalOpen, setIsAddressModalOpen] = useState(false);
  const [feeSuccess, setFeeSuccess] = useState(false);
  const [selectedAddress, setSelectedAddress] = useState<any>({});
  const [promoStatus, setPromoStatus] = useState<boolean[]>([]);
  const [promoDiscount, setPromoDiscount] = useState(0);

  var c: any = console.log(document);

  const [selectedAddressCoords, setSelectedAddressCoords] = useState<any>({
    latitude: "",
    longitude: "",
  });

  const [proceedToPayment, setProceedToPayment] = useState(false);

  const handleSelectAddress = (address: any) => {
    if (selectedAddress !== address) {
      setSelectedAddress(address);
      setSelectedAddressCoords({
        latitude: address.latitude,
        longitude: address.longitude,
      });

      dispatch(
        changeSelectedCurrentAddress({
          coords: { latitude: address.latitude, longitude: address.longitude },
          formattedAddress: apiAddressFormat(address),
          addressId: address?.addressId,
          addressType: address?.addressType,
        })
      );
      setShowModal(false);
    } else {
      setSelectedAddress(null);
      setSelectedAddressCoords({ latitude: "", longitude: "" });
    }
  };

  const applyPromoCode = async (promoCodeName: any, index: number) => {
    try {
      dispatch(
        setSelectedPromocode({ PromoCode: promoCodeName, index: index })
      );

      if (couponCode != "") {
        await verifyCodes.mutateAsync({
          promo_code_id: promoCodeName,
          code: couponCode,
          index: index,
        });
      } else {
        await applySelectedPromoCode(promoCodeName, index);
      }
    } catch (error) {
      console.error(`Error applying promo code "${promoCodeName}":`, error);
    }
  };

  const applySelectedPromoCode = async (promoCodeName: any, index: number) => {
    try {
      const response = await axiosInstance.post(
        `${API_URL.PROMO_CODE_APPLY}/${promoCodeName}`,
        {}
      );
      if (response) {
        const discount = response?.data?.discount_price || 0;
        setPromoDiscount(discount);
        setPromoStatus((prevStatus) => {
          const updatedStatus = [...prevStatus];
          updatedStatus[index] = true;
          return updatedStatus;
        });
      }
    } catch (error) {
      console.error("Application error:", error);
    }
  };

  const { data: addressList = [] } = useQuery({
    queryFn: async () => {
      let response;
      if (guestId && sessionId) {
        response = await axiosInstance.get(API_URL.GUEST_USER_GET_ADDRESS);
      } else {
        response = await axiosInstance.get(API_URL.ADDRESS_LIST);
      }
      return response.data;
    },
  });

  useEffect(() => {
    const fetchData = async () => {
      if (location?.latitude && location?.longitude) {
        try {
          const response = await axiosInstance.get(
            `${mapurl}reverse?format=json&lat=${location.latitude}&lon=${location.longitude}`
          );

          const checkLat = response?.data?.lat;
          const checkLon = response?.data?.lon;
          const match = addressList.find(
            (address: { latitude: any; longitude: any }) =>
              address.latitude === checkLat && address.longitude === checkLon
          );
          if (match) {
            setSelectedAddress(match);
          } else {
            setSelectedAddress(null);
          }
        } catch (error) {
          console.error("Error fetching data:", error);
        }
      } else {
        console.error("Latitude or longitude is missing.");
      }
    };

    fetchData();
  }, [location, addressList]);

  const deliveryFee = useMutation({
    mutationFn: async (payload: DeliveryPayload) => {
      let response;

      const headers = {
        version: guestId && sessionId ? "v2" : "v6",
        planetId: getPlanet,
        location: "7.36768,5.4567",
      };

      if (guestId && sessionId) {
        response = await axiosInstance.post(
          API_URL.GUEST_USER_CALCULATE_FEE,
          payload,
          { headers }
        );
      } else {
        response = await axiosInstance.post(API_URL.DELIVERY_FEE, payload, {
          headers,
        });
      }

      setDeliveryFees(response.data);
      const deliveryfeess = deliveryFees?.delivery_fee;
      setFeeSuccess(true);
      return deliveryfeess;
    },
  });

  const calculateTotalAmount = () => {
    const totalAmountDeliverys =
      totalSubPrice +
      packingCharge +
      selectedTipOption +
      customTipAmount -
      promoDiscount;
    return totalAmountDeliverys;
  };

  const totalDeliveryAmount = calculateTotalAmount();

  const calculateTotalDisplayAmount = () => {
    const deliveryFee = deliveryFees?.delivery_fee || 0;
    const totalDisplayAmount =
      totalSubPrice +
      packingCharge +
      deliveryFee +
      (selectedTipOption || customTipAmount || 0) -
      promoDiscount;
    return totalDisplayAmount.toFixed(2);
  };

  const totalPayable = calculateTotalDisplayAmount();

  const merchantLongitude = location.longitude;
  const merchantLatitude = location.latitude;
  const handleDeliveryFee = (latitude: string, longitude: string) => {
    const payload: DeliveryPayload = {
      merchant_longitude: String(merchantLongitude),
      merchant_latitude: String(merchantLatitude),
      customer_longitude: longitude,
      customer_latitude: latitude,
      amount: totalDeliveryAmount,
    };
    deliveryFee.mutate(payload);
  };

  const updateCart = async (updatedCartData: any) => {
    let payload;

    let secretKey: any = guestId ? guestId : vendorId;

    const hashedData = JSON.stringify(updatedCartData);
    let encryptDataRequest = CryptoJS.AES.encrypt(
      hashedData,
      secretKey
    ).toString();

    if (guestId && sessionId) {
      let encryptedPaylaod = {
        session_id: sessionId,
        guest_id: guestId,
        cart: encryptDataRequest,
      };
      try {
        await Updatemutation.mutateAsync(encryptedPaylaod);
      } catch (error) {
        console.error("Error calling updateCart mutation:", error);
        setIsErrorModalOpen(true);
      }
    } else {
      try {
        await Updatemutation.mutateAsync(encryptDataRequest);
      } catch (error) {
        console.error("Error calling updateCart mutation:", error);
        setIsErrorModalOpen(true);
      }
    }
  };

  const handleProceedNext = async () => {
    if (!deliveryFees) return;

    const updatedCartData = JSON.parse(JSON.stringify(getCart));
    const dynamicKey = Object.keys(updatedCartData)[0];

    if (dynamicKey) {
      updatedCartData[dynamicKey].total_delivery_fee =
        deliveryFees.delivery_fee;
      updatedCartData[dynamicKey].platform_share = deliveryFees.platform_share;
      updatedCartData[dynamicKey].delivery_boy_share =
        deliveryFees.delivery_boy_share;
      updatedCartData[dynamicKey].climate_margin = deliveryFees.climate_margin;
      updatedCartData[dynamicKey].tip_amount =
        selectedTipOption || customTipAmount || 0;
      updatedCartData[dynamicKey].order_total_price = totalPayable;
      updatedCartData[dynamicKey].is_deliveryfee_free =
        deliveryFees.is_deliveryfee_free;
      updatedCartData[dynamicKey].delivery_start_time =
        selectedDeliverySlot?.startTime;
      updatedCartData[dynamicKey].delivery_end_time =
        selectedDeliverySlot?.endTime;
    }

    try {
      await updateCart(updatedCartData);
      handleDeliveryFeesss(selectedAddress);
    } catch (error) {
      console.error("Error updating cart and handling delivery fees:", error);
    }
  };

  const orderCreation = useMutation({
    mutationFn: async (payload: OrderCreation) => {
      try {
        let response;
        if (guestId && sessionId) {
          response = await axiosInstance.post(
            API_URL.GUEST_ORDER_CREATE,
            payload,
            {
              headers: {
                version: "v6",
                location: "7.36768,5.4567",
              },
            }
          );
        } else {
          response = await axiosInstance.post(API_URL.ORDER_CREATION, payload, {
            headers: {
              version: "v6",
              location: "7.36768,5.4567",
            },
          });
        }
        return response;
      } catch (error) {
        console.error("Error creating order:", error);
        throw error;
      }
    },
    onSuccess: (response) => {
      let message;
      if (guestId && sessionId) {
        message = response?.data;
      } else {
        message = response?.data?.order_number;
      }
      if (message) {
        setIsSuccessModalOpen(true);
        setProceedToPayment(true);
        setOrderResponseMessage(message);
      } else {
        console.error("Invalid response format");
      }
    },
    onError: (error) => {
      console.error("Error occurred:", error);
      setIsErrorModalOpen(true);
    },
  });

  const handleDeliveryFeesss = (address: any) => {
    let payload;
 
    if (guestId && sessionId) {
      payload = {
        validate_otp_request: {
          phoneNo: guestNumber,
          userType: "CONSUMER",
        },
        session_id: sessionId,
        guest_id: guestId,
        merchant_id: merchantId,
        address_id: address,
        payment_method: "COD",
        channel: "WEB",
        promocode: "",
        user_code: "",
      } as OrderGuestCreation;
    } else {
      payload = {
        merchant_id: merchantId,
        address_id: address?.addressId,
        payment_method: "COD",
        promocode: "",
        user_code: "",
      } as OrderCreation;
    }

    orderCreation.mutate(payload);
  };
  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false);
  const [isErrorStockOpen, setIsErrorStockOpen] = useState(false);
  const [isErrorCartOpen, setIsErrorCartOpen] = useState(false);

  const [showAllCoupons, setShowAllCoupons] = useState(false);

  const handleViewMoreCoupons = () => {
    setShowAllCoupons(true);
  };

  return (
    <div className="row">
      <div className="col-lg-7 justify-content-center pt-3">
        <Card className="card-howitswork border-radius-none pb-3">
          <CardBody className="pb-3">
            <div className="col-12">
              <Card className="card-custom-delverydetails">
                <CardBody className="pb-0">
                  <div className="row head-style size">
                    <div className="col ">Products</div>
                    <div className="col  text-end"></div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12">
              <Card className="card-custom-delverydetails">
                <CardBody className="pb-2">
                  <ul className="list-unstyled">
                    {getCart &&
                      Object.keys(getCart)?.map((shopId) =>
                        getCart[shopId].products?.map(
                          (item: any, index: number) => {
                            const productQuantity =
                              encryptedResponses &&
                              (encryptedResponses[shopId]?.products?.find(
                                (obj: any) => obj.product_id === item.product_id
                              )?.quantity ??
                                item.quantity);

                            if (productQuantity > 0) {
                              const totalPrice = (
                                item.price * productQuantity
                              ).toFixed(2);

                              return (
                                <li
                                  key={item.product_id}
                                  className="checkout-item"
                                >
                                  <div className="row align-items-center">
                                    <div className="col-1 get-cart-img-div">
                                      <img
                                        src={item.product_image || foodIcon}
                                        alt="foodIcon"
                                        className="get-cart-img"
                                      />
                                    </div>
                                    <div className="col-5">
                                      <div className="inner-text-heading-four">
                                        {item.product_name}
                                      </div>
                                      <div className="product-weight pt-1">
                                        {item.weight ? `${item.weight} g` : " "}
                                      </div>
                                    </div>
                                    <div className="col d-flex flex-column align-items-end">
                                      <div className="inner-text-heading-four text-center">
                                        ₹ {totalPrice}
                                      </div>
                                      <div className="d-flex align-items-center justify-content-center cart-buttons">
                                        <div
                                          className="cart-counter px-1"
                                          onClick={() =>
                                            handleIncrementDecrement(
                                              "decrement",
                                              item.product_id,
                                              index
                                            )
                                          }
                                        >
                                          -
                                        </div>{" "}
                                        <div className=" text-center product-count px-1">
                                          {productQuantity}
                                        </div>
                                        <div
                                          className="cart-counter px-1"
                                          onClick={() =>
                                            item.is_product_available &&
                                            item.available_stock > item.quantity
                                              ? handleIncrementDecrement(
                                                  "increment",
                                                  item.product_id,
                                                  index
                                                )
                                              : setErrorMesssagWithIndex(
                                                  index,
                                                  "Quantity cannot be greater than available stock"
                                                )
                                          }
                                        >
                                          +
                                        </div>
                                      </div>
                                    </div>
                                    {errorMessages[index]?.index === index && (
                                      <p className="text-danger">
                                        {errorMessages[index]?.message}
                                      </p>
                                    )}
                                    {errorMessages[index]?.index === index && (
                                      <ErrorModal
                                        isOpenModal={isErrorStockOpen}
                                        onCloseModal={() =>
                                          setIsErrorStockOpen(false)
                                        }
                                        toggleModal={() =>
                                          setIsErrorStockOpen(!isErrorStockOpen)
                                        }
                                        message={errorMessages[index]?.message}
                                      />
                                    )}
                                  </div>
                                </li>
                              );
                            }
                            return null;
                          }
                        )
                      )}
                  </ul>

                  <div className="col-12">
                    <div className="add-new-item">
                      <div className="col inner-text-heading-seven px-0">
                        Add new item
                      </div>
                      <div
                        className="col add-new-item-icon text-right px-0"
                        onClick={() => handleAddItem()}
                      >
                        +
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="col-12">
              <Card className="card-custom-delverydetails">
                <CardBody className="pb-2">
                  <div className="row">
                    <div className="p-2 FoodSpecialOffers-width-in-pixel content-fishcard align-items-center">
                      <img src={availableoffers} alt="time" className="mr-2" />
                      &nbsp;
                      <label className="inner-text-heading-two">
                        Available Offers
                      </label>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
            <div className="row">
              {promoCodes && promoCodes.length > 0 ? (
                promoCodes.filter(
                  (item: any) => item.minimum_cart_amount < totalCartAmount
                ).length > 0 ? (
                  promoCodes.map((item: any, index: any) =>
                    item.minimum_cart_amount < totalCartAmount ? (
                      <div className="col-6" key={index}>
                        <Card className="card-hghlght border-none">
                          <CardBody>
                            <img
                              src={offer1}
                              alt=""
                              className="img-ratio-card"
                            />
                            <div className="pt-2">
                              <div className="inner-text-heading-four">
                                {item.pid}
                              </div>
                              <div className="inner-text-content-three">
                                Price:{" "}
                                <span className="inner-text-heading-four">
                                  {item.discount_amount}
                                </span>
                              </div>
                              <div className="inner-text-content-three">
                                Minimum cart amount:{" "}
                                <span className="inner-text-heading-four">
                                  {item.minimum_cart_amount}
                                </span>
                              </div>
                              <div className="inner-text-content-six">
                                Rating: {item.rating} ({item.numberOfReviews})
                              </div>
                              {item.is_code_required && (
                                <div>
                                  {!promoStatus[index] && (
                                    <div style={{ height: "85px" }}>
                                      <Label
                                        for={`couponCode_${index}`}
                                        size="10"
                                      >
                                        Enter Coupon Code:
                                      </Label>
                                      <Input
                                        type="text"
                                        placeholder="eg:RSN232"
                                        required
                                        value={couponCode}
                                        onChange={(e) =>
                                          setCouponCode(e.target.value)
                                        }
                                      />
                                    </div>
                                  )}{" "}
                                </div>
                              )}
                              {!item.is_code_required ? (
                                promoStatus[index] ? (
                                  <p className="text-success">
                                    Promo code applied
                                  </p>
                                ) : (
                                  <button
                                    className="card-button"
                                    onClick={() =>
                                      applyPromoCode(item.code_id, index)
                                    }
                                  >
                                    Apply <FontAwesomeIcon icon={faTicket} />
                                  </button>
                                )
                              ) : couponCode ? (
                                promoStatus[index] ? (
                                  <p className="text-success">
                                    Promo code applied
                                  </p>
                                ) : (
                                  <button
                                    className="card-button"
                                    onClick={() =>
                                      applyPromoCode(item.code_id, index)
                                    }
                                  >
                                    Apply <FontAwesomeIcon icon={faTicket} />
                                  </button>
                                )
                              ) : (
                                <p className="text-danger">
                                  Please enter the coupon code
                                </p>
                              )}
                            </div>
                          </CardBody>
                        </Card>
                      </div>
                    ) : null
                  )
                ) : (
                  <p className="text-danger">No coupon's available now !!!</p>
                )
              ) : (
                <p className="text-danger">No coupon's available now !!!</p>
              )}
            </div>
            {/* {promoCodes?.map((item: any, index: any) =>
                  item.minimum_cart_amount < totalCartAmount ||
                  showAllCoupons ? (
                    <div className="col-6">
                      <Card key={index} className="card-hghlght border-none">
                        <CardBody>
                          <img src={offer1} alt="" className="img-ratio-card" />
                          <div className="pt-2">
                            <div className="inner-text-heading-four">
                              {item.pid}
                            </div>
                            <div className="inner-text-content-three">
                              Price:{" "}
                              <span className="inner-text-heading-four">
                                {item.discount_amount}
                              </span>
                            </div>
                            <div className="inner-text-content-three">
                              minimum cart amount:{" "}
                              <span className="inner-text-heading-four">
                                {item.minimum_cart_amount}
                              </span>
                            </div>
                            <div className="inner-text-content-six">
                              Rating: {item.rating} ({item.numberOfReviews})
                            </div>

                            {item.is_code_required && (
                              <div>
                                {!promoStatus[index] && (
                                  <div style={{ height: "85px" }}>
                                    <Label
                                      for={`couponCode_${index}`}
                                      size="10"
                                    >
                                      Enter Coupon Code:
                                    </Label>
                                    <Input
                                      type="text"
                                      placeholder="eg:LILO20"
                                      required
                                      value={couponCode}
                                      onChange={(e) =>
                                        setCouponCode(e.target.value)
                                      }
                                    />
                                  </div>
                                )}{" "}
                              </div>
                            )}
                            {!item.is_code_required ? (
                              promoStatus[index] ? (
                                <p className="text-success">
                                  Promo code applied
                                </p>
                              ) : (
                                <button
                                  className="card-button"
                                  onClick={() =>
                                    applyPromoCode(item.code_id, index)
                                  }
                                >
                                  Apply <FontAwesomeIcon icon={faTicket} />
                                </button>
                              )
                            ) : couponCode ? (
                              promoStatus[index] ? (
                                <p className="text-success">
                                  Promo code applied
                                </p>
                              ) : (
                                <button
                                  className="card-button"
                                  onClick={() =>
                                    applyPromoCode(item.code_id, index)
                                  }
                                >
                                  Apply <FontAwesomeIcon icon={faTicket} />
                                </button>
                              )
                            ) : (
                              <p className="text-danger">
                                Please enter the coupon code
                              </p>
                            )}
                          </div>
                        </CardBody>
                      </Card>
                    </div>
                  ) : (
                    ""
                  )
                )} */}

            {/* </CardBody>
              </Card> */}

            <div className="col-12">
              <Card className="card-custom-delverydetails">
                <CardBody className="pb-0">
                  {getPlanet === "fishPlanet" && <DeliverySlot />}{" "}
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
        <Card className="card-howitswork border-radius-none pb-3 mt-3">
          <CardBody className="pb-3">
            <div className="col-12  mt-4">
              <div className="col-12">
                <h3 className="tip-selection-heading ms-2">
                  Tip your delivery partner
                </h3>
                <h6 className="tip-selection-para ms-2">
                  Thank your delivery partner by leaving them a tip. 100 percent
                  of your tip will go to your delivery partner.
                </h6>
                <Card className="card-custom-delverydetails border-bottom-div">
                  <CardBody className="pb-3">
                    <div className="delivery-details-container">
                      <div className="tip-options">
                        {[20, 30, 50].map((tipAmount) => (
                          <div
                            key={tipAmount}
                            className={`tip-option ${
                              selectedTipOption === tipAmount
                                ? "selected-tip"
                                : ""
                            }`}
                            onClick={() => handleTipSelection(tipAmount)}
                          >
                            {tipAmount}
                          </div>
                        ))}
                        <div
                          className={`tip-option ${
                            showTipInput ? "selected-tip" : ""
                          }`}
                          onClick={() => handleTipSelection("Other")}
                        >
                          Other
                        </div>
                      </div>
                    </div>

                    {showTipInput && (
                      <div className="custom-tip-input mt-3">
                        <input
                          type="text"
                          placeholder="Enter Amount"
                          value={customTipAmount}
                          onChange={(e) => handleTextInput(e.target.value)}
                          onBlur={() =>
                            handleCustomTipAmount(customTipAmount.toString())
                          }
                          className="tip-amount-other"
                        />
                      </div>
                    )}
                  </CardBody>
                </Card>
              </div>
            </div>
            <div className="col-12">
              <Card className="card-custom-delverydetails">
                <CardBody className="pb-0">
                  <div className="row">
                    <div className="col-12">
                      <textarea
                        placeholder=" Delivery Instructions (Optional)"
                        className="textarea-input"
                        onChange={handleInstructionsChange}
                      ></textarea>
                      <div className="character-count">0/300</div>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          </CardBody>
        </Card>
        <Card className="card-howitswork border-radius-none mt-3 ">
          <CardBody className="pb-3">
            <div className="p-3">
              <p>
                Review your order and address details to avoid cancellations
              </p>
              <p>
                <strong className="color-green">Note:</strong> Once the payment
                is completed, we won’t be able to process any cancellations.
              </p>
              <p> Avoid cancellations as it leads to food wastage.</p>
              <Link to="#" className="color-green">
                Read Cancellation Policy
              </Link>
            </div>
          </CardBody>
        </Card>
      </div>
      <div className="col-lg-5 pt-3">
        <div className=" ">
          <div className="card-body col-12 p-2">
            <div className="row">
              <Card className="no-border">
                <CardBody>
                  <div className="row">
                    <div className="col-md-8">
                      {selectedAddress && (
                        <div className="selected-address-details">
                          <h5 className="inner-text-heading-address">
                            {selectedAddress.addressType}
                          </h5>
                          <p className="inner-text-content-three">
                            {selectedAddress.address1}
                          </p>
                        </div>
                      )}
                    </div>
                    <div className="col-md-4">
                      <div className="link-like" onClick={handleAddAddress}>
                        {selectedAddress ? "Change Address" : "Add Address"}
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>

              <Card className="no-border mt-3">
                <CardBody className="pb-0">
                  <div className="row ">
                    <div className=" col-md-12">
                      <div className="para ">
                        <div className="d-flex justify-content-between align-items-center">
                          <p className="inner-text-content-three-color">
                            Item Total
                          </p>

                          <p className="inner-text-content-three-color">
                            &#8377;
                            {calculateTotalPrice()
                              ? calculateTotalPrice()
                              : "0.00"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {" "}
                          <p className="inner-text-content-three-color">
                            Packaging Charge
                          </p>{" "}
                          <p className="inner-text-content-three-color">
                            &#8377;
                            {calculatePackingCharge()
                              ? calculatePackingCharge()
                              : "0.00"}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {" "}
                          <p className="inner-text-content-three-color">
                            Delivery Fee
                          </p>
                          <p className="inner-text-content-three-color">
                            {deliveryFees?.delivery_fee}
                          </p>
                        </div>
                        <div className="d-flex justify-content-between align-items-center">
                          {" "}
                          <p className="inner-text-content-three-color">
                            Delivery Tip
                          </p>
                          <p className="inner-text-content-three-color">
                            {" "}
                            &#8377;
                            {selectedTipOption || customTipAmount || "0.00"}
                          </p>
                        </div>
                        <div className="custom-topay">
                          <div className="d-flex justify-content-between align-items-center mt-3">
                            {" "}
                            <p className="inner-text-content-three-color">
                              To Pay
                            </p>
                            <p className="inner-text-content-three-color">
                              {" "}
                              &#8377;
                              {totalPayable}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </CardBody>
              </Card>
              <div className="d-flex justify-content-between mt-3">
                <Button
                  className="btn enable-btn"
                  onClick={handleProceedNext}
                  disabled={!(selectedAddress && feeSuccess)}
                >
                  Pay On Delivery
                </Button>
                &nbsp;
                <Button
                  className="btn enable-btn"
                  onClick={handleProceedNext}
                  disabled={!(selectedAddress && feeSuccess)}
                >
                  Proceed to payment
                </Button>
              </div>
            </div>
          </div>
        </div>
        <Modal
          isOpen={showModal}
          onHide={handleCloseModal}
          centered
          dialogClassName="modal-fullscreen"
          className="modal-address-custom"
        >
          <ModalHeader className="text-end justify-content-end card-custom-address ">
            <Button variant="secondary" onClick={handleCloseModal}>
              X
            </Button>
          </ModalHeader>
          <ModalBody>
            <div className="row justify-content-center">
              {addressList &&
                Array.isArray(addressList) &&
                addressList.map((item) => (
                  <div
                    className="col-md-6 mb-3 text-left"
                    style={{ textAlign: "left" }}
                    key={item.addressId}
                  >
                    <div
                      className={` card ${
                        selectedAddress === item.addressId
                          ? "select-address-card"
                          : "address-card"
                      }`}
                      onClick={() => handleSelectAddress(item)}
                    >
                      <div className="card-body" style={{ textAlign: "left" }}>
                        <div className="row">
                          <div className="col-1 align-content-center">
                            <img
                              src={
                                item.addressType === "Home"
                                  ? home_icon
                                  : item.addressType === "Work"
                                  ? work_icon
                                  : item.addressType === "Hotel"
                                  ? hotel_icon
                                  : location_pin
                              }
                              alt="Logo"
                            />
                          </div>
                          <div className="col-11 small-size mt-1 mr-3 address-details">
                            <h5 className="card-title">{item?.addressType}</h5>
                            <p className="card-text address-text">
                              {item.address1}
                            </p>
                            <button
                              className={`p-0 ${
                                selectedAddress === item.addressId
                                  ? "select-confirm-address"
                                  : "confirm-address"
                              }`}
                              onClick={() =>
                                handleDeliveryFee(item.latitude, item.longitude)
                              }
                            >
                              Deliver to this address
                              <img src={double_arrow} alt="Arrow" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              {!proceedToPayment && (
                <div className="col-md-6 mb-3 text-start">
                  <div
                    className="card address-card "
                    onClick={toggleAddressModal}
                  >
                    <div className="card-body  align-content-center text-center">
                      <div className="row">
                        <div className="col-12">
                          <div className="card-title">
                            <img
                              src={location_pin}
                              alt="Location"
                              className="location-custom"
                            />
                            &nbsp;
                            <span> Add new address</span>
                          </div>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </ModalBody>
          <ModalFooter className=" card-custom-address ">
            {/* <Button variant="secondary" onClick={handleCloseModal}>
              Close
            </Button> */}
          </ModalFooter>
        </Modal>

        <AddressModal isOpen={isAddressModalOpen} toggle={toggleAddressModal} />
      </div>
      <SuccesModal
        isOpenModal={isSuccessModalOpen}
        onCloseModal={() => setIsSuccessModalOpen(false)}
        toggleModal={() => setIsSuccessModalOpen(!isSuccessModalOpen)}
        message={orderResponseMessage}
        onNav={() => {
          navigate("/payment");
        }}
      />
      <ErrorModal
        isOpenModal={isErrorModalOpen}
        onCloseModal={() => setIsErrorModalOpen(false)}
        toggleModal={() => setIsErrorModalOpen(!isErrorModalOpen)}
        message="Sorry for the inconvineance, please try again later"
      ></ErrorModal>

      <ErrorModal
        isOpenModal={isErrorCartOpen}
        onCloseModal={() => setIsErrorCartOpen(false)}
        toggleModal={() => setIsErrorCartOpen(!isErrorModalOpen)}
        message={isErrorCartOpenMessage}
      ></ErrorModal>
    </div>
  );
}

export default DeliveryDetails;
