import React, { useState } from "react";
import { Button, Modal, ModalBody } from "reactstrap";
import { banner, succesicon } from "../../assets/images";

interface SuccesModalProps {
  isOpenModal: boolean;
  onCloseModal: () => void;
  toggleModal: () => void;
  message: string;
  onNav: () => void;
}

const SuccesModal: React.FC<SuccesModalProps> = ({
  isOpenModal,
  onCloseModal,
  toggleModal,
  message,
  onNav,
}) => {
  return (
    <Modal isOpen={isOpenModal} toggle={onCloseModal} backdrop="static">
      <ModalBody>
        <div>
          <div className="text-end">
            <Button onClick={onCloseModal} className="btn btn-danger">
              X
            </Button>
          </div>
          <div className="p-2 text-center">
            <img
              src={succesicon}
              alt=""
              className="img-fluid img-modal-icons"
            />
            <div className="sub-div-headings pt-3">
              {" "}
              <h6>Your order placed Successfully!</h6>
              <br></br>
              Order Number: {message}
            </div>
            <div className="pt-3">
              <button className="btn green-button-style px-3" onClick={onNav}>
                Continue
              </button>
            </div>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};

export default SuccesModal;
