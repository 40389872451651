import React from "react";

const TermsConditions = () => {
  return (
    <>
      <div className="col-12 d-flex justify-content-center text-start pb-4">
        <div className="row width-full-page pb-3">
          <div className="col-md-12 text-center">
            <h4 className="heading-pages pt-5">
              <span className="span-with-green">T</span>erms
              <span className="span-with-green"> & C</span>onditions
            </h4>
          </div>
          <div className="col-12 d-flex justify-content-center text-start pb-4">
            <div className="row width-full-page pb-3">
              <div className="col-md-12 pt-3">
                <div className="sub-div-headings pt-4">Acceptance of Terms</div>
                <ul className="pt-3">
                  <li>
                    By accessing or using Livelocal E-comm Private Limited
                    ("Livelocal") platform, including its website and mobile
                    applications, you agree to be bound by these Terms and
                    Conditions. If you do not agree with these terms, please
                    refrain from using our services.
                  </li>
                  <li>
                    These Terms and Conditions represent a legal agreement
                    between you ("User," "you," "your") and Livelocal. By using
                    our platform, you represent that you are of legal age to
                    enter into a binding contract.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Services</div>
                <ul className="pt-3">
                  <li>
                    Livelocal provides a hyperlocal delivery platform that
                    connects users with delivery providers. The services offered
                    may include but are not limited to order placement, payment
                    processing, and delivery of goods.
                  </li>
                  <li>
                    Livelocal reserves the right to modify, suspend, or
                    discontinue its services, in whole or in part, without
                    notice. We are not liable for any loss or inconvenience
                    resulting from such actions.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">User Accounts</div>
                <ul className="pt-3">
                  <li>
                    To access certain features and services on Livelocal, you
                    may be required to create a user account. You are
                    responsible for maintaining the confidentiality of your
                    account information.
                  </li>
                  <li>
                    You agree to provide accurate and up-to-date information
                    during the registration process. Livelocal is not
                    responsible for any inaccuracies in the information
                    provided.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">User Conduct</div>
                <ul className="pt-3">
                  <li>
                    You agree to use Livelocal's platform and services in
                    compliance with all applicable laws and regulations.
                  </li>
                  <li>
                    You must not engage in any behavior that is offensive,
                    harmful, or violates the rights of others. This includes but
                    is not limited to harassment, fraud, and unauthorized use of
                    intellectual property.
                  </li>
                  <li>
                    Inappropriate behavior and fraudulent activities are grounds
                    for the suspension or termination of your account.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Orders and Deliveries
                </div>
                <ul className="pt-3">
                  <li>
                    Users may place orders through the Platform for goods and
                    services offered by Merchants.
                  </li>
                  <li>
                    By placing an order, Users agree to provide accurate
                    delivery information, including the delivery address and
                    contact details.
                  </li>
                  <li>
                    Users acknowledge that delivery times provided on the
                    Platform are estimates only and actual delivery times may
                    vary depending on various factors such as traffic and
                    weather conditions.
                  </li>
                  <li>
                    Livelocal E-Comm Private Limited and its Delivery Partners
                    shall make reasonable efforts to deliver orders within the
                    estimated delivery time. However, Livelocal E-Comm Private
                    Limited shall not be liable for any delays in delivery
                    beyond its control.
                  </li>
                  <li>
                    In the event of non-delivery due to reasons attributable to
                    the User (e.g., incorrect address provided), the User may be
                    charged additional fees for redelivery, or the order may be
                    canceled, at the discretion of Livelocal E-Comm Private
                    Limited.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Payments</div>
                <ul className="pt-3">
                  <li>
                    In the event of any payment disputes, including unauthorized
                    transactions, billing errors, or discrepancies in charges,
                    Users are encouraged to contact Livelocal E-Comm Private
                    Limited customer support immediately to seek resolution.
                  </li>
                  <li>
                    Livelocal E-Comm Private Limited will investigate payment
                    disputes promptly upon receipt of a complaint from the User.
                    Users may be required to provide relevant information and
                    documentation to facilitate the investigation.
                  </li>
                  <li>
                    If a payment dispute is found to be valid, Livelocal E-Comm
                    Private Limited will take appropriate measures to rectify
                    the error, which may include issuing refunds, adjusting
                    charges, or providing compensation, as deemed necessary by
                    Livelocal E-Comm Private Limited.
                  </li>
                  <li>
                    Users agree to cooperate with Livelocal E-Comm Private
                    Limited during the investigation of payment disputes and to
                    provide accurate and truthful information to facilitate
                    resolution.
                  </li>
                  <li>
                    Livelocal E-Comm Private Limited reserves the right to
                    suspend or terminate User accounts or restrict access to the
                    Platform in cases of suspected fraudulent activity or abuse
                    of the payment system.
                  </li>
                  <li>
                    Users acknowledge that Livelocal E-Comm Private Limited may
                    be subject to the policies and procedures of third-party
                    payment processors, and disputes related to payments
                    processed through such third-party payment processors may be
                    subject to their terms and conditions.
                  </li>
                  <li>
                    Users are responsible for payments associated with orders
                    and deliveries. Payment processing is facilitated by
                    Livelocal, and you agree to provide accurate payment
                    information.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Payment Terms</div>
                <ul className="pt-3">
                  <li>
                    Users agree to pay the purchase price for goods and services
                    ordered through the Platform, as well as any applicable
                    taxes and delivery fees.
                  </li>
                  <li>
                    Payment for orders may be made through the payment methods
                    accepted by the Platform, including but not limited to
                    credit/debit cards, net banking, digital wallets, or cash on
                    delivery (where available).
                  </li>
                  <li>
                    Users acknowledge that Livelocal E-Comm Private Limited may
                    use third-party payment processors to facilitate payment
                    transactions. By making a payment, Users agree to abide by
                    the terms and conditions of such third-party payment
                    processors.
                  </li>
                  <li>
                    All payments are final and non-refundable, except as
                    provided in our refund policy.
                  </li>
                  <li>
                    In the case of payment via cash on delivery, Users agree to
                    provide the exact amount due at the time of delivery.
                    Livelocal E-Comm Private Limited and its Delivery Partners
                    reserve the right to refuse delivery if the exact amount is
                    not provided or if there are concerns about the payment.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Intellectual Property
                </div>
                <ul className="pt-3">
                  <li>
                    Livelocal's website, mobile applications, and related
                    content are protected by intellectual property rights,
                    including copyrights and trademarks.
                  </li>
                  <li>
                    Users may not reproduce, distribute, or use Livelocal's
                    intellectual property without permission.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Privacy</div>
                <ul className="pt-3">
                  <li>
                    Your use of Livelocal's platform is subject to our Privacy
                    Policy, which explains how we collect, use, and protect your
                    personal information.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Liability and Disclaimers
                </div>
                <ul className="pt-3">
                  <li>
                    Livelocal provides its services on an "as is" and "as
                    available" basis. We do not guarantee that our services will
                    be error-free, secure, or uninterrupted.
                  </li>
                  <li>
                    Livelocal is not responsible for the quality or safety of
                    the goods or services delivered by third-party providers.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">Termination</div>
                <ul className="pt-3">
                  <li>
                    Livelocal reserves the right to terminate or suspend user
                    accounts for violations of these Terms and Conditions.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Changes to Terms and Conditions
                </div>
                <ul className="pt-3">
                  <li>
                    Livelocal may update these Terms and Conditions to reflect
                    changes in our practices. Users will be notified of
                    significant changes.
                  </li>
                </ul>
              </div>
              <div className="col-md-12">
                <div className="sub-div-headings pt-4">
                  Governing Law and Jurisdiction
                </div>
                <ul className="pt-3">
                  <li>
                    These Terms and Conditions are governed by and construed in
                    accordance with the laws of India. Any disputes arising
                    under these terms shall be subject to the exclusive
                    jurisdiction of the courts of India
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default TermsConditions;
