import React from "react";
import { Card, CardBody } from "reactstrap";
import { Pre_demo_img } from "../../../assets/images";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import FoodCart from "../FoodCart/FoodCart";
import { useSelector } from "react-redux";
import { useQuery } from "react-query";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import "./FoodSpecialOffers.css";
import { useNavigate } from "react-router-dom";

interface ProductDetails {
  product_id: string;
  product_name: string;
  rating: number;
  total_reviews: number;
  price: number;
  is_offer_available: boolean;
  inventories: {
    [vendorId: string]: {
      price: number;
    };
  };
  product_details: {
    description: string;
    short_description: string | null;
    details: any;
    images: string[];
    toppings: any;
    product_category_description: string | null;
    product_type: string | null;
    last_order_date: string | null;
    method_of_use: string | null;
    serving_per_bag: number | null;
    serving_size: number | null;
    additional_images: string[] | null;
    health_benefits: string[] | null;
    typical_kerala_dishes: string[] | null;
    taste_and_textures: string[] | null;
    type_of_fish: string | null;
  };
  product_category_id: string;
}

interface FoodSpecialOffersProps {
  products: { [key: string]: ProductDetails[] };
}

const FoodSpecialOffers: React.FC<FoodSpecialOffersProps> = ({ products }) => {
  const navigate = useNavigate();
  const selectedMerchant = useSelector(
    (state: any) => state.merchant.selectedfoodmerchant.merchant_id
  );

  const { data: specialProducts } = useQuery<ProductDetails[]>({
    queryKey: ["specialOffers", selectedMerchant],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `${API_URL.SPECIAL_OFFERS}/${selectedMerchant}`
      );
      return response.data;
    },
  });

  const handleImageClick = (product_id: string) => {
    navigate(`/ProductDetails?id=${product_id}`, { state: { product_id } });
  };

  return (
    <div className="FoodSpecialOffersdiv pt-4">
      {specialProducts && specialProducts.length > 0 && (
        <div className="text-center inner-text-heading-four span-with-green">
          Exclusive offers on our premium products! #SpecialDeals
          #LimitedTimeOffers
        </div>
      )}
      <div className="d-flex FoodSpecialOffers-width-main pb-3">
        {specialProducts && specialProducts.length > 0 ? (
          specialProducts.map((product) => (
            <div
              className="p-2 FoodSpecialOffers-width-in-pixel content-fishcard"
              key={product.product_id}
            >
              <Card className="card-hghlght border-none">
                <CardBody>
                  <img
                    src={product?.product_details?.images?.[0] || Pre_demo_img}
                    alt={product.product_name}
                    className="img-ratio-card"
                  />
                  <div className="pt-2">
                    <div className="inner-text-heading-four">
                      {product.product_name}
                    </div>
                    <div className="inner-text-content-three">
                      Price:{" "}
                      <span className="inner-text-heading-four">
                        {
                          product.inventories[
                            Object.keys(product.inventories)[0]
                          ].price
                        }
                      </span>
                    </div>
                    <div className="inner-text-content-six">
                      <FontAwesomeIcon icon={faStar} className="yellow-star" />
                      &nbsp; {product.rating} ({product.total_reviews} reviews)
                    </div>
                    <div className="pt-2">
                      <button
                        className="card-button text-white add-to-cart-button"
                        onClick={() => handleImageClick(product.product_id)}
                      >
                        View
                      </button>
                    </div>
                  </div>
                </CardBody>
              </Card>
            </div>
          ))
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default FoodSpecialOffers;
