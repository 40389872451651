import React, { useEffect } from "react";
import "./FlyingImage.css";

interface FlyingImageProps {
  imgSrc: string;
  itemOffsets: { top: number; left: number };
  onAnimationEnd: () => void;
}

export const FlyingImage: React.FC<FlyingImageProps> = ({
  imgSrc,
  itemOffsets,
  onAnimationEnd,
}) => {
  const imgStyle: React.CSSProperties = {
    top: `${itemOffsets.top}px`,
    left: `${itemOffsets.left}px`,
    width: "230px",
    height: "230px",
    position: "absolute",
    zIndex: 101,
  };

  useEffect(() => {
    const cart = document.querySelector(".b-cart");
    if (!cart) return;

    const cartOffset = cart.getBoundingClientRect();
    const flyingImg = document.querySelector(
      ".b-flying-img"
    ) as HTMLImageElement;

    if (flyingImg) {
      setTimeout(() => {
        if (window.innerWidth <= 768) {
          flyingImg.style.top = `${cartOffset.top - 400}px`;
          flyingImg.style.left = `${cartOffset.left + 200}px`;
        } else {
          flyingImg.style.top = `${cartOffset.top - 400}px`;
          flyingImg.style.left = `${cartOffset.left + 900}px`;
        }

        flyingImg.style.width = "50px";
        flyingImg.style.height = "50px";
        flyingImg.style.zIndex = "105";
      }, 0);

      flyingImg.addEventListener("transitionend", onAnimationEnd);
      return () =>
        flyingImg.removeEventListener("transitionend", onAnimationEnd);
    }
  }, [itemOffsets, onAnimationEnd]);

  return (
    <div
      className="flying-img-container"
      style={{ position: "absolute", top: "-200px" }}
    >
      <img
        className="b-flying-img"
        src={imgSrc}
        alt="Flying"
        style={imgStyle}
      />
    </div>
  );
};
