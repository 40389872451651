import axiosInstance from "../../services/axios.instance";

interface Merchant {
    id: number;
    name: string;
    address: string;
}

const API_GET_MERCHANTS_RECENT = async (): Promise<Merchant[]> => {
  try {
    // const resp = await axiosInstance.get(`/consumers/previousorders-merchantinfo`)
    return []; // Placeholder empty array, replace with actual data
  } catch (err) {
    throw err;
  }
};

const API_GET_MERCHANTS_ID = async (
  id: number,
  latitude: number,
  longitude: number
): Promise<Merchant> => {
  try {
    const resp = await axiosInstance.get(`customer-consumer/customer/service/consumers/merchants/${id}`, { headers: { "location": `${latitude},${longitude}` } });
    return resp.data as Merchant;
  } catch (err) {
    throw err;
  }
};

const API_GET_MERCHANTS_ID_NEARBY = async (
  latitude: number,
  longitude: number,
  res: number,
  business_area: string
): Promise<Merchant[]> => {
  try {
    const resp = await axiosInstance.get(`customer-consumer/customer/service/merchants/${latitude}/${longitude}/${res}?business_area=${business_area}`);
    return resp.data as Merchant[];
  } catch (err) {
    throw err;
  }
};

const API_MERCHANT_VISITOR_COUNT_UPDATE = async (
  id: number,
  latitude: number,
  longitude: number
): Promise<number> => {
  try {
    const resp = await axiosInstance.get(`customer-consumer/customer/service/merchantInfo?merchant_id=${id}`, { headers: { "location": `${latitude},${longitude}` } });
    return resp.data as number;
  } catch (err) {
    throw err;
  }
};

export { 
  API_GET_MERCHANTS_RECENT, 
  API_GET_MERCHANTS_ID, 
  API_GET_MERCHANTS_ID_NEARBY, 
  API_MERCHANT_VISITOR_COUNT_UPDATE 
};
