import axiosInstance from "../../services/axios.instance";

interface Planet {
    // Define the structure of the Planet object
    id: number;
    name: string;
    // Add more properties as needed
  }
const API_GET_PLANETS = async (): Promise<Planet[]> => {
  try {
    const resp = await axiosInstance.get(`product-consumer/planets`);
    return resp.data as Planet[];
  } catch (err) {
    throw err;
  }
};

const API_GET_HOME_CAROUSEL = async (): Promise<any> => {
  try {
    const resp = await axiosInstance.get(`customer-consumer/customer/service//carousal-configs/type/home_all`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_GET_PLANET_CAROUSEL = async (planetName: string): Promise<any> => {
  try {
    const resp = await axiosInstance.get(`customer-consumer/customer/service//carousal-configs/type/merchanthome_${planetName}`);
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export { API_GET_PLANETS, API_GET_HOME_CAROUSEL, API_GET_PLANET_CAROUSEL };
