// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.editprofile-input{
    width: 100%;
}

.edit-profile-btn {
    background-color: #17af4b;
    color: white;
  }

  .edit-profile-btn:hover {
    background-color: white;
    color: #17af4b;
    border: .5px solid #17af4b;
  }`, "",{"version":3,"sources":["webpack://./src/components/Profile/EditProfile/editprofile.css"],"names":[],"mappings":"AAAA;IACI,WAAW;AACf;;AAEA;IACI,yBAAyB;IACzB,YAAY;EACd;;EAEA;IACE,uBAAuB;IACvB,cAAc;IACd,0BAA0B;EAC5B","sourcesContent":[".editprofile-input{\n    width: 100%;\n}\n\n.edit-profile-btn {\n    background-color: #17af4b;\n    color: white;\n  }\n\n  .edit-profile-btn:hover {\n    background-color: white;\n    color: #17af4b;\n    border: .5px solid #17af4b;\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
