// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.customButton {
  display: flex;
}

.address-category {
  cursor: pointer;
  background-color: #f5f5f5;
  margin-right: 10px;
  transition: background-color 0.3s, color 0.3s;
  border: 1px solid #f5f5f5;
  border-radius: 10px;
  width: 20%;
  height: 40px;
  text-align: center;
  align-items: center;
  padding-top: 1.5%;
}

.address-category:hover {
  background-color: #01afee;
  color: #fff;
}

.address-category.selected {
  background-color: #def6ff;
  color: #28af4b;
  border: 1px solid #28af4b !important;
  border-radius: 10px;
}
`, "",{"version":3,"sources":["webpack://./src/components/customButton/styles.css"],"names":[],"mappings":"AAAA;EACE,aAAa;AACf;;AAEA;EACE,eAAe;EACf,yBAAyB;EACzB,kBAAkB;EAClB,6CAA6C;EAC7C,yBAAyB;EACzB,mBAAmB;EACnB,UAAU;EACV,YAAY;EACZ,kBAAkB;EAClB,mBAAmB;EACnB,iBAAiB;AACnB;;AAEA;EACE,yBAAyB;EACzB,WAAW;AACb;;AAEA;EACE,yBAAyB;EACzB,cAAc;EACd,oCAAoC;EACpC,mBAAmB;AACrB","sourcesContent":[".customButton {\n  display: flex;\n}\n\n.address-category {\n  cursor: pointer;\n  background-color: #f5f5f5;\n  margin-right: 10px;\n  transition: background-color 0.3s, color 0.3s;\n  border: 1px solid #f5f5f5;\n  border-radius: 10px;\n  width: 20%;\n  height: 40px;\n  text-align: center;\n  align-items: center;\n  padding-top: 1.5%;\n}\n\n.address-category:hover {\n  background-color: #01afee;\n  color: #fff;\n}\n\n.address-category.selected {\n  background-color: #def6ff;\n  color: #28af4b;\n  border: 1px solid #28af4b !important;\n  border-radius: 10px;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
