import CryptoJS from 'crypto-js';

// Simulating RNSecureKeyStore
const RNSecureKeyStore = {
  get: async (key: string) => {
    // Simulating fetching private key from storage
    return 'your_private_key';
  }
};

// Simulating sha256
const sha256 = async (data: string) => {
  // Simulating sha256 hashing
  return CryptoJS.SHA256(data).toString();
};

export const encryptData = async (data: any): Promise<string> => {
  try {
    const privateKey = await RNSecureKeyStore.get('privateKey');
    const ciphertext = CryptoJS.AES.encrypt(JSON.stringify(data), privateKey).toString();
    return ciphertext;
  } catch (error) {
    throw error;
  }
};

export const decryptData = async (data: string): Promise<any> => {
  try {
    const privateKey = await RNSecureKeyStore.get('privateKey');
    const bytes = CryptoJS.AES.decrypt(data, privateKey);
    const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
    return decryptedData;
  } catch (error) {
    throw error;
  }
};

export const createHash = async (data: string): Promise<string> => {
  try {
    const hash = await sha256(data);
    return hash;
  } catch (error) {
    throw error;
  }
};
