import { log } from "console";
import axiosInstance from "../../services/axios.instance";
import { encryptData } from "../../utils/encryption";

interface CartItem {
  // Define properties of CartItem
  product_id: string;
  quantity: number;
  // Add other properties as needed
}

// Define API functions
const API_GET_CART = async (customer_id: string) => {
  try {
    const resp = await axiosInstance.get(
      `customer-consumer/customer/service/consumers/${customer_id}/carts`
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_PUT_CART = async (customer_id: string, cart: CartItem[]) => {
  try {
    let encryptDataResponse = await encryptData(cart);
    const resp = await axiosInstance.put(
      `customer-consumer/customer/service/consumers/${customer_id}/carts`,
      encryptDataResponse,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    return resp.data;
  } catch (err) {
    console.log(
      "🚀 ~ file: cart.service.js:20 ~ constAPI_PUT_CART= ~ err:",
      err
    );
    throw err;
  }
};

const API_POST_CART = async (customer_id: string, data: any) => {
  console.log("api post", customer_id + " ::: " + data);
  try {
    // let encryptDataRequest = await encryptData(data)
    // console.log('encryptDataRequest : ' + encryptDataRequest);

    const resp = await axiosInstance.post(
      `customer-consumer/customer/service/consumers/${customer_id}/carts`,
      data,
      {
        headers: {
          "Content-Type": "text/plain",
        },
      }
    );
    console.log("insert 1 : " + JSON.stringify(resp));

    return resp.data;
  } catch (err) {
    console.log("🚀 ~ file: cart.service.js:20 ~ API_POST_CART= ~ err:", err);
    throw err;
  }
};

const API_GET_REMOVE_ITEM_CART = async (customer_id: string) => {
  try {
    const resp = await axiosInstance.get(
      `customer-consumer/customer/service/consumers/${customer_id}/carts/clear`
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

const API_REMOVE_CART = async (customer_id: string, merchant_id: string) => {
  try {
    const resp = await axiosInstance.get(
      `customer-consumer/customer/service/consumers/${customer_id}/carts/${merchant_id}/clear`
    );
    return resp.data;
  } catch (err) {
    throw err;
  }
};

export {
  API_GET_CART,
  API_POST_CART,
  API_PUT_CART,
  API_GET_REMOVE_ITEM_CART,
  API_REMOVE_CART,
};
