import React from "react";
import { previousordericon } from "../../../assets/images";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import {
  BrowserView,
  MobileView,
  isBrowser,
  isMobile,
} from "react-device-detect";

const Faq = () => {
  const navigate = useNavigate();

  const { data, isLoading, isError, error } = useQuery({
    queryKey: "faqList",
    queryFn: async () => {
      try {
        const resp = await axiosInstance.get(API_URL.FAQ_LIST, {
          headers: {
            "Content-Type": "text/plain",
            version: "v2",
          },
        });
        console.log("faq", resp);
        return resp.data;
      } catch (error) {
        console.error("Error fetching faq:", error);
        throw new Error("Error fetching faq");
      }
    },
  });

  if (isLoading) {
  }

  if (isError) {
    console.error("Error:", error);
  }
  console.log("data-----", data);

  const handleMapHelp = () => {
    navigate("/MapHelp");
  };

  return (
    <div className="col-md-12 height-custom pt-5 text-start">
      <div className="col-md-12  pt-4">
        <h4 className="heading-pages">
          FA<span className="span-with-green">Q</span>
        </h4>

        <div className="accordion accordion-custom" id="accordionExample">
          {data &&
            data.map((item: any, index: number) => {
              console.log("map item", item);
              return (
                <div className="accordion-item" key={index}>
                  <h2 className="accordion-header" id={`heading${index}`}>
                    <button
                      className="accordion-button collapsed sub-text-pages"
                      type="button"
                      data-bs-toggle="collapse"
                      data-bs-target={`#collapse${index}`}
                      aria-expanded="true"
                      aria-controls={`collapse${index}`}
                    >
                      {item.question}
                    </button>
                  </h2>
                  <div
                    id={`collapse${index}`}
                    className="accordion-collapse collapse"
                    aria-labelledby={`heading${index}`}
                    data-bs-parent="#accordionExample"
                  >
                    <div className="accordion-body inner-text-content-two">
                      <div>{item.answer}</div>
                    </div>
                  </div>
                </div>
              );
            })}
          <MobileView>
            <div className="accordion-item">
              <h2 className="accordion-header" id="headingFour">
                <button
                  className="accordion-button collapsed sub-text-pages"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#collapseFour"
                  aria-expanded="false"
                  aria-controls="collapseFour"
                  onClick={handleMapHelp}
                >
                  Map help
                </button>
                <div className="ps-3 inner-text-content-two">
                  Calibrate your blue dot’s compass
                </div>
              </h2>
            </div>
          </MobileView>
        </div>
      </div>
    </div>
  );
};

export default Faq;
