import React, { useState } from "react";
import {
  CategoryImageicon,
  SpecialOfferIcon,
  fish_thorny,
  homepagefishimg,
} from "../../../assets/images";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Card, CardBody } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import axiosInstance from "../../../services/axios.instance";
import { API_URL } from "../../../services/apiConfig";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import productListSlice, {
  setLocation,
} from "../../../redux/productList/productListSlice";

const Favourites = () => {
  const navigate = useNavigate();
  const { customerId } = useSelector((state: any) => state.auth);

  const responsive = {
    desktop: {
      breakpoint: { max: 3000, min: 640 },
      items: 5,
    },
    tablet: {
      breakpoint: { max: 604, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  interface Product {
    out_of_stock: any;
    vendor_product_inventories: any;
    weight: string;
    product_id: string;
    product_name: string;
    category_name: string;
    product_details?: {
      images?: string[];
    };
    variants?: {
      price?: number;
    }[];
  }

  interface Coordinates {
    latitude: number;
    longitude: number;
  }
  const [currentLocation, setCurrentLocation] = useState<Coordinates | null>(
    null
  );
  const { longitude, latitude } = useSelector(
    (state: any) => state.location.mapRegion
  );

  const { data: fishProducts } = useQuery({
    queryKey: ["specialOffers"],
    queryFn: async () => {
      const response = await axiosInstance.get(
        `product-consumer/products/favourite/${customerId}`
      );
      return response.data;
    },
  });

  const allFishProducts = fishProducts?.content || [];

  const handleImageClick = (product_id: string) => {
    navigate(`/ProductDetails?id=${product_id}`, { state: { product_id } });
  };

  return (
    <div className="col-md-12 pt-4">
      <div className="">
        {allFishProducts && allFishProducts.length > 0 && (
          <div className="sub-div-headings d-flex">
            <img src={SpecialOfferIcon} alt="" />
            &nbsp;
            <span>Favourites</span>&nbsp;
            <span className="line-span">
              <div className="line-div-max-length-ecommerce"></div>
            </span>
          </div>
        )}
        <div className="col-md-12 cart-container div-caroysel-relative  pt-4">
          <div className="row">
            <Carousel responsive={responsive} swipeable={true} rewind={true}>
              {/* maping start  */}
              {allFishProducts
                .filter(
                  (product: { out_of_stock: false }) => !product.out_of_stock
                )
                .map((product: Product) => (
                  <div
                    className="p-1 mb-3 content-fishcard fish-card-div-height px-2"
                    key={product.product_id}
                  >
                    <div className="card stock-card card-no-border card-hghlght">
                      <div className="card-body ">
                        <img
                          className={`card-img-top ${
                            product.out_of_stock
                              ? "out-of-stock desaturate"
                              : ""
                          }`}
                          src={
                            product?.product_details?.images?.[0] ?? fish_thorny
                          }
                          alt=""
                          onClick={() => handleImageClick(product?.product_id)}
                        />

                        <div className="inner-text-heading-four pt-2">
                          {product?.product_name}
                        </div>
                        <div className="inner-text-content-five">
                          <span className="">{product?.category_name}</span>
                          <span>&nbsp;|&nbsp;</span>
                          <span className="">{product?.weight}gm</span>
                        </div>
                        {product.out_of_stock ? (
                          <div className="out-of-stock-text pt-4">
                            <div className=" outofstock p-1">Out of Stock</div>
                          </div>
                        ) : (
                          <>
                            <div className="inner-text-content-five pt-1 pb-1">
                              Price :
                              <span className="inner-text-heading-four">
                                {" "}
                                ₹
                                {product?.vendor_product_inventories?.[0]
                                  ?.price ?? 0}
                              </span>
                            </div>
                            <div className="mb-0">
                              <button
                                className="card-button text-white add-to-cart-button"
                                onClick={() =>
                                  handleImageClick(product?.product_id)
                                }
                              >
                                View
                              </button>
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Favourites;
