import fish_thorny from "./fish_thorny.svg";
import lilo_logo from "./lilo_logo.svg";
import search_icon from "./search_icon.svg";
import fish_wood from "./fish_wood.svg";
import fishmaid_logo from "./Fishmaid.svg";
import ellipse from "./Ellipse .svg";
import profile_Icon from "./profile_Icon.svg";
import location_Icon from "./location_Icon.svg";
import cart_Icon from "./cart_Icon.svg";
import banner from "./banner.svg";
import hook_Img from "./hook_Img.svg";
import seaFish from "./seaFish.png";
import shellFish from "./shellFish.png";
import freshWaterFish from "./freshWaterFish.png";
import comboFish from "./comboFish.png";
import liveLocal_Logo from "./liveLocal_logo_img.svg";
import dropdown_icon from "./dropdown_icon.svg";
import lilo_logo_02 from "./lilo_logo_02.svg";
import play_store from "./play_store.svg";
import app_store from "./app_store.svg";
import home_icon from "./home.svg";
import work_icon from "./work.svg";
import location_pin from "./location_pin.svg";
import double_arrow from "./double_arrow.svg";
import hotel_icon from "./hotel_icon.svg";
import CategoryImageicon from "./CategoryImageicon.svg";
import previousordericon from "./previousordericon.svg";
import Bestpickersicon from "./Bestpickersicon.svg";
import homepagefishimg from "./homepagefishimg.svg";
import instagramicon from "./instagramicon.svg";
import facebookicon from "./facebookicon.svg";
import youtubeicon from "./youtubeicon.svg";
import playstore from "./playstore.svg";
import appstoreicon from "./appstoreicon.svg";
import Pre_demo_img from "./Pre_demo_img.svg";
import cart_trolly from "./cart_trolly.svg";
import squid_fish from "./squid_fish.svg";
import prawns_fish from "./prawns_fish.svg";
import king_fish from "./king_fish.svg";
import tuna_fish from "./tuna_fish.svg";
import companyicons from "./companyicons.svg";
import SpecialOfferIcon from "./SpecialOfferIcon.svg";
import GPSCalibration from "./GPSCalibration.gif";
import succesicon from "./succesicon.svg";
import Erroricon from "./Erroricon.svg";
import Bucket from "./Bucket.png";
import calenderIcon from "./calenderIcon.svg";
import CartIcon from "./Cart.svg";
import availableoffers from "./availableOffers.svg";
import foodIcon from "./foodIcon.svg";
import rectangleGreen from "./rectangleGreen.svg";
import rectangles from "./CategoryImage.svg";
import share from "./share.svg";

export {
  SpecialOfferIcon,
  fish_thorny,
  lilo_logo,
  search_icon,
  ellipse,
  fishmaid_logo,
  fish_wood,
  lilo_logo_02,
  play_store,
  app_store,
  profile_Icon,
  location_Icon,
  cart_Icon,
  banner,
  hook_Img,
  seaFish,
  shellFish,
  freshWaterFish,
  comboFish,
  liveLocal_Logo,
  dropdown_icon,
  work_icon,
  home_icon,
  location_pin,
  double_arrow,
  hotel_icon,
  CategoryImageicon,
  previousordericon,
  Bestpickersicon,
  homepagefishimg,
  instagramicon,
  facebookicon,
  youtubeicon,
  playstore,
  appstoreicon,
  Pre_demo_img,
  cart_trolly,
  tuna_fish,
  squid_fish,
  prawns_fish,
  king_fish,
  companyicons,
  GPSCalibration,
  succesicon,
  Erroricon,
  Bucket,
  calenderIcon,
  CartIcon,
  availableoffers,
  foodIcon,
  rectangleGreen,
  rectangles,
  share,
};
