import React from "react";
import { RestaurantsList } from "../../components";
import Banner from "../../components/banner/Banner";
import ProductCategory from "../../components/Food/ProductCategory/ProductCategory";

const FoodHome = () => {
  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-5">
      <div className="row width-full-page pb-5 relative-div-bg">
        <Banner />
        <ProductCategory />
        <RestaurantsList />
      </div>
    </div>
  );
};

export default FoodHome;
