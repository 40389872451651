import React from "react";

import "./Checkout.css";

import "bootstrap/dist/css/bootstrap.min.css";
import { useSelector } from "react-redux";
// import DeliveryDetails from "../../components/deliveryDetails/DeliveryDetails";
import AddAddress from "../../components/addAddress/addAddress";
import MergedHeader from "../../components/header/Header";
import DeliveryDetails from "../../components/deliveryDetails/DeliveryDetails";

function Checkout() {
  const cartListData = useSelector(
    (state: { products: { cartList: any[] } }) => state.products.cartList
  );

  return (
    <div className="col-12 d-flex justify-content-center text-start pb-4 pt-3 background-light-white">
      <div className="row width-full-page pb-5 relative-div-bg checkout-scroll background-light-white">
        <div className="col-md-12">
          <DeliveryDetails />
        </div>
      </div>
    </div>
  );
}

export default Checkout;
