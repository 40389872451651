import { createSlice } from '@reduxjs/toolkit';

const checkoutSlice = createSlice({
  name: 'checkout',
  initialState: {
 
    encryptedDataResponse: null,
    promocode:'',
    index:0
  },
  reducers: {
    // Other reducers
    setEncryptedResponse(state, action) {
      state.encryptedDataResponse = action.payload;
    },
    setSelectedPromocode(state, action) {
      const{PromoCode,index} = action.payload;
      state.promocode=PromoCode
      state.index=index
    },
  }, 
});

export const { setEncryptedResponse ,setSelectedPromocode} = checkoutSlice.actions;
export default checkoutSlice.reducer;
