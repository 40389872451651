// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.focusRing___1airF.carousel__slide-focus-ring {
  outline: none !important;
}

.selected {
  border: none !important;
}

.image-carousel {
  width: 100%;
  height: 250px;
  aspect-ratio: 6/2;
}

.banner-container {
  /* position: relative; */

  width: 100%;
  /* left: 5rem; */
  /* top: 7rem; */
  /* z-index: 6; */
}

/* .carousel .thumb img {
  /* width: 100% !important;
  height: 100% !important;
}

.carousel .slide img {
  max-height: 300px;  /* change this to whatever you want */
/* width: auto;} */

/* .carousol-custom{
   width: '1120px';
    height: '250px' 
 } */

/* @media (max-width: 450px) {
  .banner-container {
    position: relative;
    width: 70%;
    left: 56px;
    top: 120px;
    z-index: 9;
  }
} */
`, "",{"version":3,"sources":["webpack://./src/components/banner/Banner.css"],"names":[],"mappings":"AAAA;EACE,wBAAwB;AAC1B;;AAEA;EACE,uBAAuB;AACzB;;AAEA;EACE,WAAW;EACX,aAAa;EACb,iBAAiB;AACnB;;AAEA;EACE,wBAAwB;;EAExB,WAAW;EACX,gBAAgB;EAChB,eAAe;EACf,gBAAgB;AAClB;;AAEA;;;;;;2DAM2D;AAC3D,kBAAkB;;AAElB;;;IAGI;;AAEJ;;;;;;;;GAQG","sourcesContent":[".focusRing___1airF.carousel__slide-focus-ring {\n  outline: none !important;\n}\n\n.selected {\n  border: none !important;\n}\n\n.image-carousel {\n  width: 100%;\n  height: 250px;\n  aspect-ratio: 6/2;\n}\n\n.banner-container {\n  /* position: relative; */\n\n  width: 100%;\n  /* left: 5rem; */\n  /* top: 7rem; */\n  /* z-index: 6; */\n}\n\n/* .carousel .thumb img {\n  /* width: 100% !important;\n  height: 100% !important;\n}\n\n.carousel .slide img {\n  max-height: 300px;  /* change this to whatever you want */\n/* width: auto;} */\n\n/* .carousol-custom{\n   width: '1120px';\n    height: '250px' \n } */\n\n/* @media (max-width: 450px) {\n  .banner-container {\n    position: relative;\n    width: 70%;\n    left: 56px;\n    top: 120px;\n    z-index: 9;\n  }\n} */\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
