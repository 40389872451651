import React, { useState, useEffect, useRef } from "react";
import { MapContainer, TileLayer, Marker, Popup, useMap } from "react-leaflet";
import { Button } from "reactstrap";
import { LatLng } from "leaflet";
import axios from "axios";
import { mapurl } from "../../services/apiConfig";
import { changeSelectedCurrentAddress, updateCurrentLocation } from "../../redux/location/locationSlice";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";

interface MapComponentProps {
    onSelect(data: any): any;
}

const MapComponent: React.FC<MapComponentProps> = ({ onSelect = () => { } }) => {
    const dispatch = useDispatch()
    // Access latitude and longitude from Redux state
    const [position, setPosition] = useState<LatLng | null>(null);

    const mapCenter: [number, number] = [8.8720168, 76.8922617];

    const MapLogic: React.FC<{
        setPosition: React.Dispatch<React.SetStateAction<LatLng | null>>;
    }> = ({ setPosition }) => {
        const map = useMap();

        const handleMarkerDragEnd = (e: any) => {
            const newPosition = e.target.getLatLng();
            // console.log("New marker position:", newPosition);
            setPosition(newPosition);
        };

        useEffect(() => {
            const handleMapClick = (e: any) => {
            };

            const handleLocationFound = (e: any) => {
                setPosition(e?.latlng);
                map.flyTo(e?.latlng, map.getZoom());
                getAddress(e?.latlng?.lat, e?.latlng?.lng);
            };

            map.on("click", handleMapClick);
            map.on("locationfound", handleLocationFound);

            return () => {
                map.off("click", handleMapClick);
                map.off("locationfound", handleLocationFound);
            };
        }, [map, setPosition]);

        if (position) {
            return (
                <>
                    <Marker
                        position={position}
                        draggable={true}
                        eventHandlers={{ dragend: handleMarkerDragEnd }}
                    >
                        <Popup>
                            <span>Deliver Here</span>
                        </Popup>
                    </Marker>
                </>
            );
        } else {
            return null;
        }
    };

    const handleMapClick = (e: any) => {
        // map.locate();
    };
    const handleSetLocation = () => (e: any) => {
        // console.log("payload update new position", position?.lat, position?.lng)
        getAddress(position?.lat, position?.lng)
    };
    function getAddress(lat: any, lng: any) {
        // console.log("getAddress_lat_lng", lat, lng);
        axios(`${mapurl}reverse?format=json&lat=${position?.lat ? position?.lat : lat}&lon=${position?.lng ? position?.lng : lng}`)
            .then(({ data }) => {
                console.log("map lat--", data, "lat", data.lat);

                onSelect({ ...data, lat, lng })



            })
            .catch((error) => {
                console.error("Error occurred:", error);
            });
    }

    const mapRef = useRef<any>(null);

    return (
        <>
            <div>
                <MapContainer
                    center={mapCenter}
                    zoom={13}
                    style={{ height: "300px", width: "100%" }}
                    ref={mapRef}
                >
                    <TileLayer url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png" />
                    <MapLogic setPosition={setPosition} />
                </MapContainer>
                <div className="d-flex justify-content-end mt-3">
                    <Button
                        className="btn bg-danger border-0 me-1"
                        onClick={handleSetLocation}
                    >
                        Set Location
                    </Button>
                    <Button
                        className="btn bg-success border-0"
                        onClick={() => mapRef.current.locate()}
                    >
                        Locate ME
                    </Button>
                </div>
            </div>
        </>
    );
};

export default MapComponent;