import React, { useEffect, useRef, useState } from "react";
import { Modal, ModalBody } from "reactstrap";
import { liveLocal_Logo } from "../../assets/images";
import "./styles.css";
import { useMutation, useQuery } from "react-query";
import axiosInstance from "../../services/axios.instance";
import { API_URL, baseUrl } from "../../services/apiConfig";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import authSlice, {
  setAppData,
  setEmailAddress,
  setName,
  setPhoneNumber,
} from "../../redux/authSlice/authSlice";
import { useSelector } from "react-redux";
import ErrorModal from "../ErrorModal/ErrorModal";
import { error } from "console";
interface SignInModalProps {
  isOpen: boolean;
  toggle: () => void;
}

const SignInModal: React.FC<SignInModalProps> = ({ isOpen, toggle }) => {
  const [showPhoneNumberContainer, setShowPhoneNumberContainer] =
    useState(true);
  const [showOtpContainer, setShowOtpContainer] = useState(false);
  const [showDetailsContainer, setShowDetailsContainer] = useState(false);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [phoneNum, setPhoneNum] = useState("");
  const [otpNum, setOtpNum] = useState("");
  const [otp, setOtp] = useState<any>(["", "", "", "", "", ""]);
  const [isOtpVerified, setIsOtpVerified] = useState(false);
  const [transactionId, setTransactionId] = useState("");
  const [userName, setuserName] = useState("");
  const navigate = useNavigate();
  const [otpRefs] = useState<any>([
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
    useRef(),
  ]);

  const dispatch = useDispatch();

  useEffect(() => {
    setOtpNum(otpNum || "");
  }, [otpNum]);
  const getCombinedOtpValue = () => {
    const combinedValue = otpRefs
      .map((ref: any) => ref?.current?.value)
      .join("");
    // setOtpNum(combinedValue)
    return combinedValue;
  };

  const closeModal = () => {
    window.location.reload();
    toggle();
    window.location.reload();
  };
  const handleOtpChange = (index: number, e: any) => {
    const newOtp = [...otp];
    if (e.target.value === "" && index > 0) {
      otpRefs[index - 1].current.focus();
    } else {
      newOtp[index] = e.target.value;
      setOtp(newOtp);

      if (e.target.value && index < otpRefs.length - 1) {
        otpRefs[index + 1].current.focus();
      }
    }
  };

  const [phoneNumberError, setPhoneNumberError] = useState<string>("");
  const [emailError, setEmailError] = useState<string>("");

  const handlePhoneNumberChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputPhoneNum = e.target.value;
    const phoneNumPattern = /^\d{10}$/;
    if (phoneNumPattern.test(inputPhoneNum)) {
      setPhoneNum(inputPhoneNum);
      setPhoneNumberError("");
    } else {
      setPhoneNumberError("Please enter a valid 10-digit phone number.");
    }
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputEmail = e.target.value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailPattern.test(inputEmail)) {
      setEmailAddress(inputEmail);
      setEmailError("");
    } else {
      setEmailError("Please enter a valid email address.");
    }
  };

  const handleClick = async (data: any) => {
    try {
      const userData = {
        phoneNo: phoneNum,
        userType: "CONSUMER",
        event: "loginOrRegister",
      };

      dispatch(setPhoneNumber(phoneNum));
      await sendOtp.mutateAsync(userData);
    } catch (error) {
      // Handle the error here
      console.error("Error occurred:", error);
      setShowErrorModal(true);
    }
  };

  const sendOtp = useMutation({
    mutationFn: (userData: any) =>
      axiosInstance.post(`${baseUrl}${API_URL.SEND_OTP}`, userData, {
        headers: {
          version: "v2",
        },
      }),
    onSuccess: (successResponse) => {
      // console.log("successResponse", successResponse);
      if (successResponse?.status && successResponse?.data?.transactionId) {
        setTransactionId(successResponse?.data?.transactionId);
        sessionStorage.setItem(
          "transactionId",
          successResponse?.data?.transactionId
        );

        setShowPhoneNumberContainer(false);
        setShowOtpContainer(true);
      }
    },
    onError: (error: any) => {
      // alert(error.data.message);
      setShowErrorModal(true);
    },
  });
  const { vendorId } = useSelector((state: any) => state.auth);
  const handleOtpVerify = async (otpData: any) => {
    try {
      if (otpData.otp) {
        setOtpNum(otpData.otp);
      }

      const otpDetails: any = {
        phoneNo: phoneNum,
        transactionId: transactionId,
        otp: getCombinedOtpValue(),
        userType: "CONSUMER",
        event: "register",
      };

      localStorage.setItem("phoneNumber", phoneNum);
      const response = await verifyOtp.mutateAsync(otpDetails);
      console.log("Response verify:", response?.data);
      localStorage.setItem("accessToken", response?.data?.access_token);
      localStorage.setItem("vendorId", response?.data?.vendor);
      if (response && response.data && response?.data?.customer_id) {
        dispatch(setAppData(response?.data));
      } else {
        console.error("No customerId found in the response data.");
      }
    } catch (error) {
      console.error("Error verifying OTP:", error);
      alert("Error Verifying OTP. Please try again.");
      setIsOtpVerified(false);
    }
  };

  const verifyOtp = useMutation({
    mutationFn: (userOtpData: any) =>
      axiosInstance.post(API_URL.VERIFY_OTP, userOtpData, {
        headers: {
          version: "v3",
        },
      }),
    onSuccess: (successResponse) => {
      console.log("Mutation Success:", successResponse);
      if (successResponse.status === 200 && successResponse?.data?.status) {
        setIsOtpVerified(true);
        setShowOtpContainer(false);
        setShowDetailsContainer(true);
        loginDetailsQuery.refetch();
      }
    },
    onError: (error: any) => {
      console.error("Mutation Error:", error);
      if (error?.data?.message) {
        setIsOtpVerified(false);
      }
    },
  });

  const [otpTimer, setOtpTimer] = useState(30);
  const [isResendDisabled, setIsResendDisabled] = useState(false);

  const startOtpTimer = () => {
    setIsResendDisabled(true);

    const intervalId = setInterval(() => {
      setOtpTimer((prevTimer) => {
        if (prevTimer === 1) {
          clearInterval(intervalId);
          setIsResendDisabled(false);
          return 30;
        }
        return prevTimer - 1;
      });
    }, 1000);
  };

  const handleClickResend = async () => {
    try {
      await sendOtp.mutateAsync({
        phoneNo: phoneNum,
        userType: "CONSUMER",
        event: "loginOrRegister",
      });

      startOtpTimer();
    } catch (error) {
      console.error("Error resending OTP:", error);
      alert("Error sending OTP. Please try again.");
    }
  };

  const handleLoginDetails = async () => {
    try {
      const nameInput = document.getElementById("Name") as HTMLInputElement;
      const emailInput = document.getElementById("Email") as HTMLInputElement;
      dispatch(setName(nameInput.value));
      dispatch(setEmailAddress(emailInput.value));
      const firstname = nameInput.value;
      const email = emailInput.value;
      localStorage.setItem("userName", firstname);

      const access_token = localStorage.getItem("accessToken");
      const loginDetailsData = {
        firstname,
        email,
      };

      await loginDetailsMutation.mutateAsync({
        data: loginDetailsData,
      });
      closeModal();
      window.location.reload();
    } catch (error) {
      console.error("Error submitting login details:", error);
    }
  };
  const loginDetailsQuery = useQuery({
    queryKey: "loginDetails",
    queryFn: async () => {
      try {
        const response = await axiosInstance.get(
          `${baseUrl}${API_URL.ACCOUNT_LOGIN_DETAILS}`
        );
        return response.data;
      } catch (error: any) {
        throw new Error(error.message);
      }
    },
    enabled: isOpen,
    onSuccess: (data) => {
      console.log("Login details fetched:", data.firstname);
      if (
        data.firstname !== "" &&
        data.email !== "" &&
        data?.firstname !== null &&
        data?.email !== null
      ) {
        dispatch(setName(data.firstname));
        dispatch(setEmailAddress(data?.email));
        localStorage.setItem("userName", data.firstname);
        window.location.reload();
        toggle();
      }
    },
    onError: (error) => {
      console.error("Error fetching login details:", error);
    },
  });

  const loginDetailsMutation = useMutation({
    mutationFn: async ({ data }: any) => {
      // console.log("Headers in config:", config.headers);
      return axiosInstance.put(
        `${baseUrl}${API_URL.ACCOUNT_LOGIN_DETAILS}`,
        data
      );
    },
    onSuccess: (successResponse) => {
      console.log("Login Details Submission Success:", successResponse);

      navigate("/");
    },
    onError: (error: any) => {
      console.error("Login Details Submission Error:", error);
    },
  });

  return (
    <Modal
      isOpen={isOpen}
      toggle={toggle}
      backdrop="static"
      className="custom-modal"
    >
      <ModalBody className="custom-modal-body">
        <div className="row pb-3">
          <div className="col text-start">
            <img
              src={liveLocal_Logo}
              alt="SignIn Img"
              className="img-fluid liveLocalLogo"
            />
          </div>
          <div className="col text-end">
            <button className="closebutton" onClick={closeModal}>
              X
            </button>
          </div>
        </div>
        <div className={showPhoneNumberContainer ? "" : "d-none"}>
          <div className="phonenumber-container">
            <div className="num-box">
              <div className="pt-4">
                <b>SIGN IN</b>
              </div>
              <div className="pt-3">Enter your mobile number</div>
              <form>
                <div className="form-grouppt-2">
                  <input
                    type="text"
                    className="form-control input-field"
                    id="phoneNo"
                    name="phoneNo"
                    onChange={handlePhoneNumberChange}
                    maxLength={10}
                  />
                  {phoneNumberError && (
                    <div className="error-message pt-2">{phoneNumberError}</div>
                  )}
                </div>
              </form>
              <div className="pt-3">
                <button
                  className="btn btn-success btn-lg"
                  onClick={handleClick}
                >
                  Send OTP
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className={showOtpContainer ? "" : "d-none"}>
          <div className="num-box">
            <div className="OTP-container  ">
              <div className="num-box">
                <span className="mt-5">
                  <b>Enter Verification Code</b>
                </span>
                <div className="d-flex flex-row">
                  <p>
                    LILO have sent a verification code to <b> {phoneNum}</b>
                  </p>
                </div>
                <form>
                  <div className="form-group">
                    <div className="text-center">
                      <input
                        type="text"
                        className="otpBoxStyle"
                        placeholder="0"
                        maxLength={1}
                        onChange={(e) => handleOtpChange(0, e)}
                        ref={otpRefs[0]}
                      />
                      <input
                        type="text"
                        className="otpBoxStyle"
                        placeholder="0"
                        maxLength={1}
                        onChange={(e) => handleOtpChange(1, e)}
                        ref={otpRefs[1]}
                      />
                      <input
                        type="text"
                        className="otpBoxStyle"
                        placeholder="0"
                        maxLength={1}
                        onChange={(e) => handleOtpChange(2, e)}
                        ref={otpRefs[2]}
                      />
                      <input
                        type="text"
                        className="otpBoxStyle"
                        placeholder="0"
                        maxLength={1}
                        onChange={(e) => handleOtpChange(3, e)}
                        ref={otpRefs[3]}
                      />
                      <input
                        type="text"
                        className="otpBoxStyle"
                        placeholder="0"
                        maxLength={1}
                        onChange={(e) => handleOtpChange(4, e)}
                        ref={otpRefs[4]}
                      />
                      <input
                        type="text"
                        className="otpBoxStyle"
                        placeholder="0"
                        maxLength={1}
                        onChange={(e) => handleOtpChange(5, e)}
                        ref={otpRefs[5]}
                      />
                    </div>
                  </div>
                </form>
                <div className="text-center">
                  Didn’t receive OTP?{" "}
                  {isResendDisabled ? (
                    <span className="resendOtp">Resend in {otpTimer}s...</span>
                  ) : (
                    <span className="resendOtp" onClick={handleClickResend}>
                      Resend now
                    </span>
                  )}
                </div>
                <div>
                  <button
                    className="btn btn-success btn-lg "
                    onClick={handleOtpVerify}
                  >
                    Verify
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>

        {}
        <div className={showDetailsContainer ? "" : "d-none"}>
          <div className="details-container">
            <div className="num-box">
              <span className=" mt-5">
                <b>Enter your Details </b>
              </span>
              <form>
                <div className="form-group pt-2">
                  <span className="">Name</span>
                  <input
                    type="text"
                    className="form-control input-field"
                    id="Name"
                  />
                </div>
                <div className="form-group pt-3">
                  <span className="">Email Address</span>
                  <input
                    type="text"
                    className="form-control input-field"
                    id="Email"
                    onChange={handleEmailChange}
                  />
                  {emailError && (
                    <div className="error-message pt-2">{emailError}</div>
                  )}
                </div>
              </form>
              <div className="pt-3">
                <button
                  className="btn btn-success btn-lg"
                  onClick={handleLoginDetails}
                >
                  Sign In
                </button>
              </div>
            </div>
          </div>
        </div>
        <div className="termstextcontainer mt-3">
          <p>
            By continuing, I accept the <a className="link">Terms of Service</a>
            <a className="link">
              <br />
              Privacy Policy
            </a>
            | <a className="link">Content Policy</a>
          </p>
        </div>
      </ModalBody>
      <ErrorModal
        isOpenModal={showErrorModal}
        onCloseModal={() => setShowErrorModal(false)}
        toggleModal={() => setShowErrorModal(!showErrorModal)}
        message="some issue's!! Please Try again sometime"
      ></ErrorModal>
    </Modal>
  );
};

export default SignInModal;
